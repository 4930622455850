// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const caretRight = require("../assets/caret_right.png");
export const scooter = require("../assets/scooter.svg");
export const orderPlaced = require("../assets/order_placed.svg");
export const home_icon = require("../assets/home_icon.svg");
export const navigation_icon = require("../assets/navigation_icon.svg");
export const avatar = require("../assets/avatar.svg");
export const call_icon = require("../assets/call_icon.svg");
export const collectionHead = require('../assets/collection_head.svg');
export const deliveryHead = require('../assets/delivery_head.svg');
export const accepted = require('./../assets/accepted.svg')
export const pickedUp = require('./../assets/pickedUp.svg');
// Customizable Area End