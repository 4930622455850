import { Snackbar } from '@material-ui/core';
import { button_ig } from '../../../packages/blocks/share/src/assets';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function CustomInstagramShareButton ({ url }: { url: string }){
    const [copied, setCopied] = useState(false);
  
    const handleCopy = () => {
      setCopied(true);
      setTimeout(() => 
      { setCopied(false),openInstagramDMs()}, 2000); // Reset copied state after 2 seconds
    };
  
    const openInstagramDMs = () => {
      const instagramDMUrl = `https://www.instagram.com/direct/inbox/`;
      window.open(instagramDMUrl, '_blank');
    };
  
    return (
      <div>
        <CopyToClipboard text={url} onCopy={handleCopy} >
          <div className="btn" style={{ cursor: 'pointer', background: 'none', border: 'none', marginTop: '8px' }}><img src={button_ig} className="img" alt="btn_ig" /></div>
        </CopyToClipboard>
        <Snackbar
        style={{borderRadius:"4px"}}
        open={copied}
        autoHideDuration={2000}
        message="Link copied to clipboard!"
        ContentProps={{
          style: { 
            borderRadius:"4px",fontSize:"16px",color:"#313131", backgroundColor:"white", fontFamily:"Barmeno", fontWeight:400, minWidth:"fit-content"
          }
        }}
      />
      </div>
    );
  };