import React, { ChangeEvent, useState } from "react";
// Customizable Area Start
import {
    Button,
} from "@material-ui/core";
import * as Yup from "yup";
import "./Profile.css"
import { Field, FieldProps, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import CustomCalendar from "../../../components/src/CustomCalendar.web";

export interface Details {
    firstName: string; lastName: string; email: string |null;
    phoneNumber: string |null; dob: string |null; gender: string |null;
}

interface PersonalDetailProps<S> {
    handleInputChange: (fieldName: keyof S, value: string | boolean |null) => void
    fieldDetails: Details
    disableSubmitButton: () => boolean
    cancelButton: () => void;
    submitButton: () => void;
}

export default function PersonalDetail<S>({ handleInputChange, fieldDetails, disableSubmitButton, cancelButton, submitButton }: PersonalDetailProps<S>) {
    const userSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'This field should not contain special characters'),
            lastName: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'This field should not contain special characters'),
            phoneNumber: Yup.string().matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, 'The mobile number you entered is not in a valid format. Please enter a valid mobile number.'),
            email: Yup.string().test('email', 'The email address you entered is not in a valid format. Please enter a valid email address',
                function (value) {
                    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(value)
                }),
        });
    };

const [selectedValue, setSelectedValue] = useState(fieldDetails.gender);
  const [isActive, setIsActive] = useState(false);

  const handleSelectChange = (value:string) => {
    setSelectedValue(value);
    setIsActive(false); 
    handleInputChange("editGender" as keyof S,value)
  };

  const toggleDropdown = () => {
    setIsActive(!isActive); // Toggle dropdown visibility
  };

  const closeDropdown = ()=>{
    if(isActive=== true){
    setIsActive(false); 
    }
  }
  const renderErrorMessage = (error:undefined|string, touched:undefined|boolean ) => {
    if (error && touched) {
        return (
            <div style={{ fontFamily:"Barmeno" }} className="errors-profile">
                {error}
            </div>
        );
    }
    return null;
};
console.log("dob", fieldDetails.dob)

    return (
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    dob: '',
                    gender: "",
                }}
                validationSchema={userSchema}
                onSubmit={(values) => {
                    // same shape as initial values
                }}
                enableReinitialize={true}
                data-test-id="formikform"
            >
                {({ errors, touched, handleChange }) => (
                    <Form onClick={closeDropdown} id="Form1" style={{display:"flex", flexDirection:"column", gap:"32px"}} >
                        <div className="form-profile" >
                        <div className="details_field__wrapper">
                            <label
                                style={{ fontFamily: "Barmeno", color:"#747474",fontWeight:700 }}
                                htmlFor="firstName"
                            >
                                First Name<span className="required">*</span>
                            </label>
                            <Field
                                style={{ fontFamily: "Barmeno", width:"350px" }}
                                className={
                                    errors.firstName && touched.firstName
                                        ? "error-input"
                                        : "contact_form-field"
                                }
                                id="firstName"
                                name="firstName"
                                placeholder="Enter your first name"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(
                                        "editFirstName" as keyof S,
                                        event.target.value
                                    );
                                    handleChange(event);
                                }}
                                value={fieldDetails.firstName}
                            />
                            {renderErrorMessage(errors.firstName,touched.firstName)}
                        </div>
                        <div
                            style={{ fontFamily: "Barmeno" }}
                            className="details_field__wrapper"
                        >
                            <label
                                style={{ fontFamily: "Barmeno", color:"#747474",fontWeight:700 }}
                                htmlFor="lastName"
                            >
                                Last Name<span className="required">*</span>
                            </label>
                            <Field
                                style={{ fontFamily: "Barmeno",width:"350px" }}
                                className={
                                    errors.lastName && touched.lastName
                                        ? "error-input"
                                        : "contact_form-field"
                                }
                                name="lastName"
                                placeholder="Enter your last name"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(
                                        "editLastName" as keyof S,
                                        event.target.value
                                    );
                                    handleChange(event);
                                }}
                                value={fieldDetails.lastName}
                            />
                            {renderErrorMessage(errors.lastName,touched.lastName)}
                        </div>
                        <div
                            style={{ fontFamily: "Barmeno" }}
                            className="details_field__wrapper"
                        >
                            <label
                                style={{fontFamily: "Barmeno", color:"#747474",fontWeight:400 }}
                                htmlFor="email"
                            >
                                E-mail Address<span className="required">*</span>
                            </label>
                            <Field
                                disabled
                                style={{ color: "#313131", fontFamily: "Barmeno",width:"350px",backgroundColor: "#F1F1F1", border: "1px solid #BFBFBF "}}
                                className={
                                    errors.email && touched.email
                                        ? "error-input"
                                        : "contact_form-field"
                                }
                                name="email"
                                type="email"
                                placeholder="Enter your e-mail address"
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange("editEmail" as keyof S, event.target.value);
                                    handleChange(event);
                                }}
                                value={fieldDetails.email}
                            />
                            {renderErrorMessage(errors.email,touched.email)}
                        </div>
                        <div className="details_field__wrapper">
                            <label
                                style={{ fontFamily: "Barmeno", color:"#747474",fontWeight:400 }}
                                htmlFor="phoneNumber"
                            >
                                Mobile Number<span className="required">*</span>
                            </label>
                            <Field
                                disabled
                                style={{ fontFamily: "Barmeno", width:"350px" }}
                                className={
                                    errors.phoneNumber && touched.phoneNumber
                                        ? "error-input"
                                        : "contact_form-field"
                                }
                                name="phoneNumber"
                                type="number"
                                placeholder="00000000"
                            >
                                {({ field, form }: FieldProps<string>) => (
                                    <PhoneInput
                                    disabled
                                    inputStyle={{fontWeight:700,color: "#313131", fontFamily: "Barmeno",width:"350px",backgroundColor: "#F1F1F1", border: "1px solid #BFBFBF "}}
                                        inputProps={{
                                            name: "phoneNumber",
                                            required: true,
                                            autoFocus: true,
                                        }}
                                        country={"gb"}
                                        value={fieldDetails.phoneNumber}
                                        onChange={(value) => {
                                            const formattedValue = "+" + value;
                                            handleChange(formattedValue);
                                            form.setFieldValue(
                                                "phoneNumber",
                                                formattedValue
                                            );
                                            form.setFieldTouched("phoneNumber", true);
                                            handleInputChange(
                                                "editPhone" as keyof S,
                                                formattedValue
                                            );
                                        }}
                                    />
                                )}
                            </Field>
                            {renderErrorMessage(errors.phoneNumber,touched.phoneNumber)}
                        </div>
                        <div
                            style={{ fontFamily: "Barmeno",gap:"10px" }}
                            className="details_field__wrapper"
                        >
                            <label
                                style={{ fontFamily: "Barmeno", color:"#747474",fontWeight:700 }}
                                htmlFor="dob"
                            >
                                DOB (optional)
                            </label>
                            <CustomCalendar dob name={"dob".toUpperCase()} onDateChange={handleInputChange} dateFromParent={fieldDetails.dob}/>
                        </div>
                        <div className="details_field__wrapper">
                            <label style={{ fontFamily: "Barmeno", color:"#747474",fontWeight:700 }} htmlFor="gender">
                                Gender (optional)
                            </label>
                            <div className="custom-select" style={{width:"350px", marginTop:"10px"}}>
                                <div className={`${isActive ? 'select-active' : ''}`}>
      <div id="selection-drop" className={`${isActive ? 'select-selected-disabled' : 'select-selected'}`} onClick={toggleDropdown}>
        {selectedValue ? selectedValue : 'Select Gender'}
      </div>
      <div className={`select-items ${isActive ? '' : 'select-hide'}`} style={{borderRadius:"4px 4px 0px 0px"}}>
        <div className="optionSelected" onClick={(event) => handleSelectChange('Male')}>Male</div>
        <div className="optionSelected" onClick={(event) => handleSelectChange("Female")}>Female</div>
        <div className="optionSelected" onClick={(event) => handleSelectChange("I prefer not to say")}>I prefer not to say</div>
      </div>
    </div>
                            {/* </Field> */}
                            </div>
                        </div>
                        </div>

                        <div
                            style={{
                                padding: "24px 0px",
                                display: "flex",
                                flexDirection: "row",
                                gap: "30px",
                                justifyContent:"center"
                            }}
                        >
                            <Button
                                className="cancelButton"
                                onClick={() => cancelButton()}
                                id="submit"
                                style={{ fontFamily: "Barmeno" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={
                                    disableSubmitButton() ||
                                        [
                                            "firstName",
                                            "lastName"
                                        ].some(
                                            (field) => errors[field as keyof typeof errors]
                                        )
                                        ? "submitButtonDisabledDet"
                                        : "submitButtonDet"
                                }
                                disabled={
                                    disableSubmitButton() ||
                                    [
                                        "firstName",
                                        "lastName"
                                    ].some(
                                        (field) => errors[field as keyof typeof errors]
                                    )
                                }
                                onClick={() => {submitButton()}
                                }
                                id="submit"
                                style={{ fontFamily: "Barmeno" }}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
    );
}