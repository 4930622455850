import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  checkedActionBar: boolean;
  checkedActionBarText: string;
  notificationData: any;
  token: string;
  notificationSetting: {
    in_app_notification: boolean;
    email_notification: boolean;
    push_notification: boolean;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  notificationAPICallId: any;
  setNotificationAPICall: any;
  setNotificationGroupAPICall: any;
  setNotificationSubGroupAPICall: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      checkedActionBar: false,
      checkedActionBarText: configJSON.notificationGroup1,
      notificationData: [],
      token: "",
      notificationSetting: {
        in_app_notification: true,
        email_notification: true,
        push_notification: true
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const token = localStorage.getItem("authToken") || "";

    this.setState({ token });
    this.handleAPIResponseMessage(message);
    // Customizable Area End
  }

  getNotificationDataRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area Start
  handleAPIResponseMessage(message: Message): void {
    const apiRequestCallId: string = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
  
    if (responseJson) {
      this.handleNotificationSettings(apiRequestCallId, responseJson);
      runEngine.debugLog("API Recived", responseJson.data);
    }
    
    if (responseJson?.errors) {
      this.handleAPIErrors(responseJson, errorReponse);
    }
  }
  
  handleNotificationSettings(apiRequestCallId: string, responseJson: any): void {
    if (
      apiRequestCallId === this.notificationAPICallId ||
      apiRequestCallId === this.setNotificationAPICall &&
      responseJson && responseJson.notification_settings) {
      const notificationSetting = Object.keys(responseJson.notification_settings).reduce((acc: any, key: string) => {
        acc[key] = responseJson.notification_settings[key] === null ? false : responseJson.notification_settings[key];
        return acc;
      }, {} as any);
      this.setState({ notificationSetting });
    }
  }
  
  handleAPIErrors(responseJson: any, errorReponse: any): void {
    this.parseApiErrorResponse(responseJson);
    this.setState({ notificationData: this.state.notificationData });
    this.parseApiCatchErrorResponse(errorReponse);
  }

  updateNotificationSettings = () => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: this.state.token
    };

    const data = { ...this.state.notificationSetting };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setNotificationAPICall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateNotificationAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  setMainCheckBox(item: any) {
    var data = {
      title: item.attributes.title,
      description: item.attributes.description,
      state: item.attributes.state === "active" ? "inactive" : "active"
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationAPIEndPoint + `${item.id}`,
      data,
      "setNotification"
    );
  }

  setGroupCheckBox(index: number, groupIndex: number, groupitem: any) {
    var data = {
      group_type: groupitem.attributes.group_type,
      group_name: groupitem.attributes.group_name,
      state: groupitem.attributes.state === "active" ? "inactive" : "active",
      notification_setting_id: groupitem.attributes.notification_setting_id
    };
    this.postNotificationDataRequest(
      configJSON.updateNotificationGroupAPIEndPoint + `${groupitem.id}`,
      data,
      "setGroupNotification"
    );
  }

  setSubGroupCheckBox = (subGroupItem: any) => {
    let data = {
      subgroup_type: subGroupItem.attributes.subgroup_type,
      subgroup_name: subGroupItem.attributes.subgroup_name,
      state: subGroupItem.attributes.state === "active" ? "inactive" : "active",
      notification_group_id: subGroupItem.attributes.notification_group_id
    };

    this.postNotificationDataRequest(
      configJSON.updateNotificationSubGroupAPIEndPoint + `${subGroupItem.id}`,
      data,
      "setSubGroupNotification"
    );
  };

  postNotificationDataRequest = (
    endPointURL: string,
    data: any,
    type: string
  ): boolean => {
    const header = {
      "Content-Type": configJSON.notificationApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (type === "setNotification") {
      this.setNotificationAPICall = requestMessage.messageId;
    } else if (type === "setGroupNotification") {
      this.setNotificationGroupAPICall = requestMessage.messageId;
    } else if (type === "setSubGroupNotification") {
      this.setNotificationSubGroupAPICall = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  // Customizable Area End
}
