// Customizable Area Start
import React from "react";

import {
  Button,
  CircularProgress,
  Dialog
} from "@material-ui/core";
import './Gift.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  americanExpress,
  birthdayGiftCard,
  congratulationGiftCard,
  headerImage,
  masterCard,
  masterCard1,
  thankyouGiftCard,
  unionPay,
  visa,
} from "../src/assets";

import "@material-ui/styles";

import InappgiftingController, {
  Props,
  configJSON
} from "./InappgiftingController";
import ImageComponent from "../../../components/src/ImageComponent";
import PaymentPopup from "../../../components/src/PaymentPopup.web";

export default class Inappgifting extends InappgiftingController {
  constructor(props: Props) {
    super(props);
  }

  renderGiftAmount(){
    return(
      <div className="gift_amount_form">
        <div className="choose_giftcard_head">Choose Gift Amount</div>
        <div className="gift_amount_options">
        <label className="gift_amount_options_label"  >
          <input onChange={(event)=>this.giftCardPrice(event.target.value)} 
          className="gift_amount_radio"
          checked={this.state.giftCardPrice==='10'} 
               type='radio' name='giftAmount' value={'10'} />
            £10
        </label>
        <label className="gift_amount_options_label"  >
          <input onChange={(event)=>this.giftCardPrice(event.target.value)} 
          className="gift_amount_radio"
          checked={this.state.giftCardPrice==='20'} 
               type='radio' name='giftAmount' value={'20'} />
            £20
        </label>
        <label className="gift_amount_options_label"  >
          <input onChange={(event)=>this.giftCardPrice(event.target.value)} 
          className="gift_amount_radio"
          checked={this.state.giftCardPrice==='100'} 
               type='radio' name='giftAmount' value={'100'} />
            £100
        </label>
        <label className="gift_amount_options_label"  >
          <input onChange={(event)=>this.giftCardPrice(event.target.value)} 
          className="gift_amount_radio"
          checked={(!['10', '20', '100'].includes(this.state.giftCardPrice))} 
               type='radio' name='giftAmount' value={''} />
            Custom
        </label>
        </div>
        {(!['10', '20', '100'].includes(this.state.giftCardPrice)) && 
         <div className="custom_amount_container">
         <label htmlFor='giftAmount' className="custom_giftAmount_label">Enter Gift Card Amount</label>
         <input onBlur={this.onBlurOfGiftCardAmount} onChange={(event)=>this.giftCardPrice(event.target.value)} style={{fontFamily:'Barmeno'}} className="gift-amount-form-field" name="giftAmount" placeholder='Enter an amount between £10 and £100' value={this.state.giftCardPrice?`£${this.state.giftCardPrice}`:''}
          />
          </div>}
       
         
      </div>
    )
  }

  renderRecipientDetails(){
    return(
      <div className="giftCard_recipient_main_container">
        <div className="choose_giftcard_head">
        Choose Gift Card Recipient
        </div >
        <div className="gift_amount_options"><label className="gift_amount_options_label" >
          <input onChange={(event)=>this.giftCardRecipient(event.target.value)} 
          className="gift_amount_radio"
          checked={this.state.giftCardRecipient==='Myself'} 
               type='radio' name='recipient' value='Myself' />
            Myself
        </label>
        <label className="gift_amount_options_label"  >
          <input onChange={(event)=>this.giftCardRecipient(event.target.value)} 
          className="gift_amount_radio"
          checked={this.state.giftCardRecipient==='Someone else'} 
               type='radio' name='recipient' value='Someone else'/>
            Someone else
        </label></div>

        <div className="senders-details">
        <div className="custom_amount_container">
        <label htmlFor='sender_name' className="custom_giftAmount_label">Your Name<span className="required">*</span></label>
        <input onBlur={(event)=>{this.handleNameChangeError('senderNameError',event.target.value)}} onChange={(event)=>this.handleRecipientChange('senderName',event.target.value,'senderNameError')} style={{fontFamily:'Barmeno'}} 
        className={this.state.senderNameError?"gift-amount-form-field-error":"gift-amount-form-field"} name="sender_name" placeholder='Enter your full name' value={this.state.senderName}
         /> <span className="error-gift">{this.state.senderNameError}</span>
         {this.state.senderName && this.state.senderName.match(configJSON.specialChar) &&<div className="error-gift" >Name field cannot contain special character or numbers.</div> }
         </div>
         <div className="custom_amount_container">
        <label htmlFor='sender_email' className="custom_giftAmount_label">E-mail Address<span className="required">*</span></label>
        <input onBlur={(event)=>{this.validateEmailField('senderEmailError',event.target.value)}} type="email" onChange={(event)=>this.handleRecipientChange('senderEmail',event.target.value,'senderEmailError')} style={{fontFamily:'Barmeno'}} 
         className={this.state.senderEmailError?"gift-amount-form-field-error":"gift-amount-form-field"} name="sender_email" placeholder='Enter your e-mail address' value={this.state.senderEmail}
         /> <span className="error-gift">{this.state.senderEmailError}</span>
         </div>
         </div>
         {this.state.giftCardRecipient === 'Someone else' &&
         <div className="senders-details">
         <div className="custom_amount_container">
         <label htmlFor='reciever_name' className="custom_giftAmount_label">Recipient’s Name<span className="required">*</span></label>
         <input onBlur={(event)=>{this.handleNameChangeError('recipientNameError',event.target.value)}} onChange={(event)=>this.handleRecipientChange('recipientName',event.target.value,'recipientNameError')} style={{fontFamily:'Barmeno'}} 
         className={this.state.recipientNameError?"gift-amount-form-field-error":"gift-amount-form-field"} name="reciever_name" placeholder='Enter the recipient’s name' value={this.state.recipientName}
          />
           {this.state.recipientName && this.state.recipientName.match(configJSON.specialChar) &&<div className="error-gift" >Name field cannot contain special character or numbers.</div> }
          <span className="error-gift">{this.state.recipientNameError}</span>
          </div>
          <div className="custom_amount_container">
         <label htmlFor='reciever_email' className="custom_giftAmount_label">Recipient’s E-mail Address<span className="required">*</span></label>
         <input onBlur={(event)=>{this.validateEmailField('recipientEmailError',event.target.value)}}  type="email" onChange={(event)=>this.handleRecipientChange('recipientEmail',event.target.value,'recipientEmailError')} style={{fontFamily:'Barmeno'}} 
         className={this.state.recipientEmailError?"gift-amount-form-field-error":"gift-amount-form-field"} name="reciever_email" placeholder='Enter recipient’s e-mail address' value={this.state.recipientEmail}
          /> <span className="error-gift">{this.state.recipientEmailError}</span>
          </div>
          </div>
         }
         
      </div>
    )
  }

  renderGiftFormContainer(){
    return(
      <div className="gift_form_container">
      <div style={{fontFamily:'Barmeno'}} className="choose_giftcard">
      <div className="choose_giftcard_head">Choose Your Design</div>
      <div className="choose_giftcard_options">
      <label className="giftcard_options_label" >
        <input onChange={(event)=>this.giftCardDesign(thankyouGiftCard)} checked={this.state.giftCardDesign===thankyouGiftCard} 
             type='radio' name='giftCard' value={thankyouGiftCard} style={{display: 'none'}} />
             <ImageComponent  className="giftcard_options_image" imageUrl={thankyouGiftCard} alt="thank-you"/>
      </label>

      <label className="giftcard_options_label" >
        <input onChange={(event)=>this.giftCardDesign(birthdayGiftCard)} checked={this.state.giftCardDesign===birthdayGiftCard} 
             type='radio' name='giftCard' value={'a'} style={{display: 'none'}} />
             <ImageComponent  className="giftcard_options_image" imageUrl={birthdayGiftCard} alt="birthday"/>
      </label>

      <label className="giftcard_options_label">
        <input onChange={(event)=>this.giftCardDesign(congratulationGiftCard)} checked={this.state.giftCardDesign===congratulationGiftCard}
             type='radio' name='giftCard' value={'a'} style={{display: 'none'}} />
          <ImageComponent  className="giftcard_options_image" imageUrl={congratulationGiftCard} alt="congratulation"/>
     </label>
      </div>
        </div>
        {this.renderGiftAmount()}
        {this.renderRecipientDetails()}
        <div className="custom_amount_container">
      <label htmlFor='message' className="custom_giftAmount_label">Add your message</label>
      <textarea onChange={(event)=>{this.handleWordCountChange(event.target.value)}} style={{fontFamily:'Barmeno', height:"118px", fontWeight:500, resize:"none", padding:"10px"}} className="gift-amount-form-field" name="message" placeholder='Enter your message here....' value={this.state.giftCardMessage}
       />
       <div style={{ fontFamily: "Barmeno" }} className="word-count">
     {this.state.count}/100 </div>
       </div>
       <div className="continue_container">
        <div>By clicking on “Continue”, you agree to <span onClick={this.navigateToTnC} className="wingos_gift_tnc"> Wingo’s Gift Card Terms and Conditions</span> </div>
        <Button name="continue" onClick={()=>{this.submitGiftDetails()}} disabled={this.disableSubmit()} className={this.disableSubmit()?"giftcard_continue_disabled_button":"giftcard_continue_button"}>
          Continue
        </Button>
        </div>
      </div>
    )
  }

  renderPaymentFormContainer(){
    return(
      <div className="gift_form_container" >
        <div className="order_summary">
          <div className="order_summary_heading">Order Summary</div>
          <div className="summary_report">
            <div className="summary_detail"><div style={{fontSize:'18px'}}>Quantity</div>
                  <div  style={{fontSize:'20px'}}>1</div>
            </div>
            <div className="summary_detail"><div  style={{fontSize:'18px'}}>Sub Total</div>
                  <div  style={{fontSize:'20px'}}>£{Number(this.state.giftCardPrice)}</div>
            </div>
            <div className="summary_detail" style={{borderTop:"1px solid #BFBFBF", paddingTop:"12px"}}><div  style={{fontSize:'18px'}}>Total Amount</div>
                  <div  style={{fontSize:'20px'}}>£{Number(this.state.giftCardPrice)*1}</div>
            </div>
          </div>
        </div>
        <div className="order_summary_heading">Payment Details</div>
        <div className="card_details">
          <div className="custom_amount_container">
        <label htmlFor='cardHolderName' className="custom_giftAmount_label_payment">Cardholder Name</label>
        <input onChange={(event)=>this.handleInputChangeCard(event.target)} style={{fontFamily:'Barmeno'}} className="gift-amount-form-field" name="cardHolderName" placeholder='Enter cardholder name' value={this.state.cardHolderName}
         />
          </div>
          <div style={{display:"flex",flexDirection:"column",gap:"8px"}}>
              <div className="custom_amount_container">
        <label htmlFor='number' className="custom_giftAmount_label_payment">Card Number</label>
        <input onChange={(event)=>this.handleInputChangeCard(event.target)} style={{fontFamily:'Barmeno'}} type="tel" className="gift-amount-form-field" name="number" placeholder='1234 1234 1234 1234' value={this.state.cardNumber} maxLength={this.state.cardLength}
         />
         <div id="expiryDateerror" style={{fontSize:"12px", fontWeight:700, fontFamily:"Barmeno", color:"#E11B22"}}>{this.state.cardNumberError}</div>
         </div>
            <div style={{display:"flex", gap:"12px", alignItems:"center"}}> <img style={{width:"24px",height:"24px"}} src={visa}/> <img style={{width:"24px",height:"24px"}} src={masterCard1}/> <img style={{width:"24px",height:"16.57px"}} src={americanExpress}/> <img style={{width:"24px",height:"16.94px"}} src={masterCard}/> <img style={{width:"24px",height:"16.57px"}} src={unionPay}/></div>
          </div>
          <div className="senders-details">
        <div className="custom_amount_container">
        <label htmlFor='expiry' className="custom_giftAmount_label_payment">Expiry Date</label>
        <input onChange={(event)=>this.handleInputChangeCard(event.target)} style={{fontFamily:'Barmeno'}} className="gift-amount-form-field" name="expiry" placeholder='MM / YY' value={this.state.cardExpiry}
         />
         <div id="expiryDateerror" style={{fontSize:"12px", fontWeight:700, fontFamily:"Barmeno", color:"#E11B22"}}>{this.state.expiryError}</div>
         </div>
         <div className="custom_amount_container">
        <label htmlFor='cvc' className="custom_giftAmount_label_payment">CVC</label>
        <input onChange={(event)=>this.handleInputChangeCard(event.target)} style={{fontFamily:'Barmeno'}} className="gift-amount-form-field" name="cvc" placeholder='123' value={this.state.cardCvc}
         />
         <div id="expiryDateerror" style={{fontSize:"12px", fontWeight:700, fontFamily:"Barmeno", color:"#E11B22"}}>{this.state.cvcError}</div>
         </div>
         </div>
        </div>
        <div className="payment_button_container">
        <Button onClick={()=>{this.goBack()}} className="giftcard_back_button">
        BACK
        </Button>
        <Button name="doPayment" onClick={()=>this.doPayment()} className={this.paymentButtonDisabled()?"giftcard_continue_disabled_button":"giftcard_continue_button"} disabled={this.paymentButtonDisabled()}>
          PAY NOW
        </Button>
        </div>
      </div>
    )
  }

  renderMainComponent(){
    return(
     < div className="gift_container">
        <div className="gift_detail" >
       <div className="gift_heading">Gift Card Details</div>
       <div className='gifting_info'>
       <ImageComponent imageUrl={this.state.giftCardDesign} alt="giftCard" style={{boxShadow:"4px 4px 5px 4px #13121214", borderRadius: "8px"}}/>
       <div className="sender_detail__gift">
        <div className="gift_info_detail"><div className="gift_info_detail_key">From:</div> <div className="gift_info_detail_value">{this.state.giftCardFrom}</div></div>
        <div className="gift_info_detail"><div className="gift_info_detail_key">To:</div> <div className="gift_info_detail_value">{this.state.giftCardTo}</div></div>
        <div className="gift_info_detail"><div className="gift_info_detail_key">Message:</div> <div className="gift_info_detail_value">{this.state.messageTo}</div></div>
       </div>
       </div>
       </div>
       
        { this.state.giftCardDetailFilled?
         this.renderPaymentFormContainer()
         :this.renderGiftFormContainer()}
         {this.state.giftCard_amounts ?
        <div className="gift_balance" >
       <div className="gift_heading">Your Gift Card Balance</div>
       <div className="gift_heading" style={{color:"#2C6F37", fontSize:"30px"}}>£{this.state.giftCard_amounts}</div>
       </div>:<div></div>}
       </div>
    )
  }

  render() {
    return (
      <div className="main-component">
        <ImageComponent imageUrl={headerImage} alt='header-giftcard' style={{width:'100%'}} />
          {this.renderMainComponent()}
          <PaymentPopup open={this.state.paymentPopup} tryAgain={this.tryAgainClick} onClose={this.onclose} img={this.state.img} heading={this.state.heading} content={this.state.content} button={this.state.button}/>
          <Dialog className="loader" open={this.state.processingPayment}>
            <CircularProgress/>
          </Dialog>
      </div>
    );
  }
}

// Customizable Area End
// Customizable Area End
