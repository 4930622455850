import { BlockComponent } from "../../framework/src/BlockComponent";
import React, { Suspense, lazy } from "react";
import CustomLoader from "./CustomLoader.web";

const LazyLoadRestaurantPage = lazy(()=>import('../../blocks/storelocator/src/StoreLocator.web'))

interface Props {
    id: any;
    navigation:any;
    location?:any;
  }

interface S {
  }
interface SS {
  }

export default class RestaurantPageLazyLoad extends BlockComponent<
Props,
S,
SS
>{
    render(){
    return(
        <Suspense fallback={<CustomLoader loading={true}/>}>
            <LazyLoadRestaurantPage navigation={this.props.navigation} id="LazyLoadId" location={this.props.location} />
        </Suspense>
    )
}
}