import React from "react";
import { Button, Dialog } from "@material-ui/core";
import {noItemsInCartImage} from "../SocialLogin/assets"
import ImageComponent from "../ImageComponent";
import CloseIcon from '@material-ui/icons/Close'
import { resetTo } from "../../../framework/src/Utilities";
import "./NoItemsInCartPopup.css";


export const noItemsInCartPopup = (open: boolean, navgateToMenu:()=>void, cancelPopup:()=>void) => {
    return (
        <Dialog data-testid='no-items-popup' PaperProps={{ style: { borderRadius: '16px' } }}
            open={open} style={{ borderRadius: "16px" }} maxWidth="md" onClose={cancelPopup}>
            <div className="no_items_popup__close_button" style={{ display: 'flex', padding: '16px', justifyContent: 'flex-end', position: 'absolute', top: 0, right: 0, boxSizing: 'border-box' }} onClick={cancelPopup}><CloseIcon style={{ height: '24px', width: '24px', color: '#525252' }} /></div>
            <div className="feedBackPopupBox">
                <ImageComponent imageUrl={noItemsInCartImage} alt="no-item" style={{ width: "250px" }} />
                <div>
                    <div className="feedBackHeading">
                        Oops! No Items in Your Cart Yet
                    </div>
                    <div className="feedback_form_container" >
                        Add items to your cart to redeem the available reward points!
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "40px", width: "100%", justifyContent: "center" }}>
                    <Button onClick={cancelPopup} style={{ width: "100%", maxWidth: '200px', height: '56px', color: '#E11B22', backgroundColor: '#fff', borderRadius: '8px', border: "1px solid #E11B22" }}>Cancel</Button>
                    <Button onClick={navgateToMenu} style={{ width: "100%", maxWidth: '200px', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>Menu</Button>
                </div>
            </div>
        </Dialog>
    );
}