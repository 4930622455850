import React from 'react';
import { Button, Typography } from '@mui/material';
import {junk} from "../SocialLogin/assets";
import "./EmptyCartItemAlert.web.css";
interface Props {
    onClose: Function;
    deleteCartItem: Function;
    dataTestId?:string;
}

const EmptyCartItemAlert: React.FC<Props> = ({onClose, deleteCartItem}) => {
    return (
        <div className='deleteCartItem_Container'>
            <img src={junk} alt='junk' />
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <Typography className='title' variant='h5' style={{width:"fit-content"}}>{'Delete Cart Item(s)'}</Typography>
                <Typography className='description' variant='h6'>{'Are you sure you want to delete an item from your cart?'}</Typography>
            </div>
            <div className='btn_group'>
            <Button className='cancel' onClick={() => {onClose()}}>CANCEL</Button>
            <Button className='signin' onClick={() => {deleteCartItem()}}>DELETE</Button>
            </div>
        </div>
    )
}

export default EmptyCartItemAlert;