import React, { Component } from 'react';
import { FormLabel} from '@material-ui/core';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import "./AppFooter.css"

interface MobileNumberFieldProps {
  onCountryCodeChange: (countryCode: string) => void;
  onMobileNumberChange: (mobileNumber: string) => void;
  numberExists?: boolean;
  borderColorText?: string;
  value?:string;
}

interface MobileNumberFieldState {
  countryCode: string;
  mobileNumber: string;
  isMenuOpen: boolean;
  isInputFocused: boolean;
  phoneLength: Number;
  countryCallingCodes: Array<any>
}


const theme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        '&:hover': {
          borderColor: 'rgba(0, 0, 0, 0.87) !important',
        }
      },
    },
    MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 'none !important',
          },
          '&:after': {
            borderBottom: 'none',
          },
        },
    },
    MuiSelect: {
        select: {
          padding: '4px 0 0px 12px',
          textAlign:' center',
          '&:focus': {
            backgroundColor: 'none'
          }
        },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Barmeno, sans-serif',
        fontWeight: '500',
        lineHeight: '16px',
      },
        input: {
          padding: '3px 0 0 0',
        },
    },
    MuiTextField: {
      root: {
        border: 'none !important',
        '&:hover': {
          border: 'none',
          outline: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiIconButton: {
        root: {
          width: '24px',
          height: '24px',
          margin: '0 16px 0 4px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiSvgIcon-root': {
            width: '24px',
            height: '24px',
          },
        },
    },
  }}as any);

class CustomMobileBox extends Component<MobileNumberFieldProps, MobileNumberFieldState> {
  constructor(props: MobileNumberFieldProps) {
    super(props);
    this.state = {
      countryCode: '44',
      mobileNumber: this.props.value || "",
      isMenuOpen: false,
      isInputFocused: false,
      phoneLength: 10,
      countryCallingCodes: require('./countryCallingCodes.json')
    };
  }

  handleClearMobileNumber = () => {
    this.setState({ mobileNumber: '' });
    this.props.onMobileNumberChange('');
  };

  handleChangeCountryCode = (value: string) => {
    const countryCode = value;
    this.props.onCountryCodeChange(countryCode);
  };

  handleChangeMobileNumber = (value:string, data: any, e: any, formattedValue: string) => {
    const mobileNumber = value;
    if(data.dialCode === "44") {
      e.target.maxLength = 16;
    }
    this.setState({ mobileNumber, countryCode: data.dialCode });
    this.props.onMobileNumberChange(mobileNumber);
    this.handleChangeCountryCode(data.dialCode)
  };

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  handleArrowClick = () => {
    // Toggle the menu open/close state
    this.setState(prevState => ({
      isMenuOpen: !prevState.isMenuOpen,
    }));
  };

  handleInputFocus = () => {
    this.setState({ isInputFocused: true });
  };

  handleInputBlur = () => {
    this.setState({ isInputFocused: false });
  };

  render() {
    const { mobileNumber } = this.state;
    
    return (
      <ThemeProvider theme={theme}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'start', flexDirection: 'column', width: '100%' }}>
        <FormLabel style={{ width: "240px", fontFamily: "Barmeno, sans-Serif", color: "rgb(144, 144, 144)",fontSize: "16px",fontWeight: 500, lineHeight: "16px" }}>Mobile Number</FormLabel>
        <PhoneInput
              containerClass={this.props.numberExists ? 'error' : ''}
              inputStyle={{borderRadius: "8px", fontFamily: "Barmeno", width: "100%", height: "56px", border:this.props.borderColorText || "1px solid rgb(144, 144, 144)" }}
              dropdownStyle={{textAlign:"start", maxHeight:'100px'}}
                 inputProps={{
                     name: "phoneNumber",
                     required: true,
                 }}
                 enableLongNumbers={this.state.countryCode === '44' ? true : false}
                 country={"gb"}
                 value={mobileNumber}
                 onChange={this.handleChangeMobileNumber}
                    />
      </div>
      </ThemeProvider>
    );
  }
}

export default CustomMobileBox;
