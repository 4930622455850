import React, { Component } from 'react';
import { Box, Button, Dialog, DialogContent, IconButton, Typography} from '@material-ui/core';
import { success, failure } from "./../src/SocialLogin/assets";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface PaymentPopupProps {
    open: boolean;
    onClose: ()=>void;
    img: string;
    heading: string;
    content: string;
    button: string;
    tryAgain:()=>void;
  }

  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paper: {
          width: '600px !important',
          padding: "40px !important",
          borderRadius: "16px"
        },
      },
    }
});

export default class PaymentPopup extends Component<PaymentPopupProps>{

    render() {
        const { open, onClose,img, heading,content,button,tryAgain } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={open} maxWidth="md" >
                <DialogContent >
                    </DialogContent>
                    <Box textAlign="center" style={{display:'flex',flexDirection:"column", alignItems:'center', gap:"40px"}}>
                        <img src={img==='success' ? success:failure} alt="success" style={{width:"250px", height: "250px"}} />
                        <div style={{display:'flex',flexDirection:"column", gap:'24px'}}><Typography style={{fontFamily: 'Barmeno',  fontSize: '24px', fontWeight: 700, textAlign: "center" }}>
                        {heading}
                        </Typography>
                        <Typography style={{fontFamily: 'Barmeno',   fontSize: '18px', fontWeight: 500, textAlign: "center" }}>
                        {content}
                        </Typography></div>
                        
                        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", gap: "32px" }}>
                          <Button data-testId="onclose" onClick={button === "TRY AGAIN"?tryAgain:onClose} style={{flex: 1, width: '100%', height: '56px', color: '#fff',backgroundColor: '#E11B22',borderRadius: '8px'}}>{button}</Button>
                          {content === "Payment unsuccessful. Please check your details and try again or consider changing your payment method." && <Button data-testId="changePayment" onClick={onClose} style={{flex: 1, width: '100%', height: '56px',color: '#fff',borderRadius: '8px',backgroundColor: '#E11B22'}}>CHANGE PAYMENT METHOD</Button>}
                        </div>
                </Box>
                </Dialog>
                </ThemeProvider>
        );
    }
}