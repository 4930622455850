import React, { useEffect, useState } from 'react';
import {image_cross} from "./SocialLogin/assets";
import { Typography } from '@material-ui/core';
import "./SnackBar.css";

interface Props {
    isVisible: boolean;
    handleClose: Function
}

export default function SimpleSnackbar({isVisible, handleClose}: Props) {

  return (
    <>
    {isVisible && (
       <div style={{display: "flex", gap: "8px", alignItems: "center", padding: "16px 16px 16px 8px", background: "rgba(255, 255, 255, 1)", boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06)", borderRadius: "8px", transition: "right 0.5s ease-in-out"}}>
          <img width="20px" height="20px" src={image_cross} onClick={() => handleClose()} />
          <Typography variant="h5" component="div" className="alert_msg" >Item removed from Favourites</Typography>
        </div>
    )}
  </>
  );
}
