import React, { Component } from 'react';
import {Dialog,IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import "../../blocks/inappgifting/src/Gift.css"
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import CurrencyPoundRoundedIcon from '@mui/icons-material/CurrencyPoundRounded';
import { GiftCardDetail } from '../../blocks/inappgifting/src/InappgiftingController';

interface Props {
  open: boolean;
  onClose: ()=>void;
  giftCardDetails:GiftCardDetail|null
}

export default class GiftCardDetailsPopup extends Component<Props> {
    constructor(props: Props){
        super(props);
    }

    formatDate=(dateString:string|undefined)=>{
      const date = new Date(dateString||"");
    
      const options = {
        weekday: 'short' as 'short',
        year: 'numeric' as 'numeric',
        month: 'short' as 'short',
        day: 'numeric' as 'numeric',
        hour: '2-digit' as '2-digit',
        minute: '2-digit' as '2-digit',
        hour12: true as true
      };
    
      return date.toLocaleString('en-GB', options);
    }

  render() {
    const {open,onClose,giftCardDetails} = this.props;
    return (
      <Dialog open={open} onClose={onClose}
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
          width:'100%',
        } 
      }} >
        <div>
            <div className='close_icon_giftcard_popup' >
            <IconButton onClick={onClose} >
              <CloseIcon />
            </IconButton>
            </div>
            <div className='heading_giftcard_popup'>Gift Card Activity Details</div>
            <div style={{padding:"0px 24px"}}>
            <div className='activity_detail_giftcard'>
            <div className='activity_detail_label' ><AccessTimeRoundedIcon style={{height:'20px', width:'20px'}}/> Date & Time</div>
            <div className='activity_detail_data'>{this.formatDate(giftCardDetails?.attributes.updated_at)}</div>
            </div>
            <div className='activity_detail_giftcard'>
            <div className='activity_detail_label' ><CardGiftcardRoundedIcon style={{height:'19px', width:'20px'}}/> Gift Card Number</div>
            <div className='activity_detail_data'>{giftCardDetails?.attributes.refrence_number}</div>
            </div>
            <div className='activity_detail_giftcard'>
            <div className='activity_detail_label' ><TimelineRoundedIcon style={{height:'24px', width:'24px'}}/> Status</div>
            <div className='activity_detail_data'>{giftCardDetails?.attributes.status}</div>
            </div>
            <div className='activity_detail_giftcard' style={{borderBottom:"1px solid #D4D4D4"}}>
            <div className='activity_detail_label' ><div style={{border:"2px solid #1D1D1D", borderRadius:"50%"}}><CurrencyPoundRoundedIcon style={{height:'16px', width:'16px'}}/></div> Amount</div>
            <div className='activity_detail_data'>£{giftCardDetails?.attributes.balance_amount}</div>
            </div>
            </div>
        
        <div className='transaction_detail_header'>Transaction Details</div>
        <div style={{padding:"0px 24px 8px 24px", backgroundColor:"#FCE8E9"}}>
          <div className='activity_detail_giftcard_trans'>
            <div className='activity_detail_label' > Order Number</div>
            <div className='activity_detail_data'>{giftCardDetails?.attributes.payment_transaction.transaction_number}</div>
          </div>
          <div className='activity_detail_giftcard_trans'>
            <div className='activity_detail_label' > Order Total</div>
            <div className='activity_detail_data'>{giftCardDetails?.attributes.payment_transaction.amount}</div>
          </div>
          <div className='activity_detail_giftcard_trans'>
            <div className='activity_detail_label' > Payment method</div>
            <div className='activity_detail_data'>{giftCardDetails?.attributes.payment_transaction.payment_method_type?giftCardDetails?.attributes.payment_transaction.payment_method_type:"Card"}</div>
          </div>
          <div className='activity_detail_giftcard_trans'>
            <div className='activity_detail_label' > Payment Applied</div>
            <div className='activity_detail_data'>£{giftCardDetails?.attributes.redeemed_amount}</div>
          </div>
        </div>
        </div>
      </Dialog>
    );
  }
}
