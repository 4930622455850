import { createTheme } from "@material-ui/core/styles";

const globalTheme = createTheme({
    overrides: {
      MuiInputBase: {
        input: {
          fontFamily: 'Barmeno', 
          fontWeight: 500, 
          color: 'rgba(49, 49, 49, 1)', 
          fontSize: '16px', 
          lineHeight: '16px',
        },
        root: {
          fontFamily: 'Barmeno', 
          fontWeight: 500, 
          color: 'rgba(49, 49, 49, 1)', 
          fontSize: '16px', 
          lineHeight: '16px',
          margin: "4px 0 0 0",
        }
      },
      MuiSelect: {
        select: {
          '&:focus': {
            borderRadius: 0,
            backgroundColor: 'rgba(0, 0, 0, 0) !important',
          }
        }
      },
      MuiButton: {
        disableElevation: {
          '&.Mui-disabled': {
            backgroundColor: '#BFBFBF !important',
            color: '#909090'
          }
        }
      },
      MuiTextField: {
        root: {
          '&.Mui-error': {
            borderColor: '#F87171',
          }
        }
    }
    }
  });

export default globalTheme;
