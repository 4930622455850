import React, { Component } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography} from '@material-ui/core';
import { success, failure } from "./../src/SocialLogin/assets";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface RestPasswordPopupProps {
    open: boolean;
    onClose: ()=>void;
    button: string;
  }

  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paper: {
          width: '600px !important',
          padding: "40px !important",
          borderRadius: "16px"
        },
      },
    }
});

export default class ResetPasswordPopup extends Component<RestPasswordPopupProps>{

    render() {
        const { open, onClose,button } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={open} maxWidth="md" >
          <Box display="flex" alignItems="center" justifyContent="end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
                <DialogContent >
                    </DialogContent>
                    <Box textAlign="center" style={{display:'flex',flexDirection:"column", alignItems:'center', gap:"40px"}}>
                        <img src={success} alt="success" style={{width:"250px", height: "250px"}} />
                        <div style={{display:'flex',flexDirection:"column", gap:'24px'}}><Typography style={{fontFamily: 'Barmeno',  fontSize: '24px', fontWeight: 700, textAlign: "center" }}>
                        Reset Password Succesfull!
                        </Typography>
                        <Typography style={{fontFamily: 'Barmeno',   fontSize: '18px', fontWeight: 500, textAlign: "center" }}>
                        Your password has been changed successfully.
                        </Typography></div>
                        
                        <Button data-testId="onclose" onClick={onClose} style={{width: '472px', height: '56px', color: '#fff',backgroundColor: '#E11B22',borderRadius: '8px'}}>{button}</Button>
                </Box>
                </Dialog>
                </ThemeProvider>
        );
    }
}