import React from 'react';
import { emptyNotifications } from './assets';
import "./EmptyNotifications.web.css";

const EmptyNotifications = () => {
    return (
        <div className="emptyNotificationWrapper">
            <img src={emptyNotifications} alt="No notifications Found" className="emptyNotificationWrapper__image" />
            <h2 className="emptyNotificationWrapper__title">No Notifications Yet!</h2>
            <p className="emptyNotificationWrapper__description">We&apos;ll notify you when there is something new</p>
        </div>
    )
}

export default EmptyNotifications;