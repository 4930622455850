export const SignInToContinue = require("./assets/SignInToContinue.png")
export const dealFoundImg = require("./assets/deal_found.png")
export const textbutton_edit = require("./assets/textbutton_edit.svg")
export const image_location = require("./assets/image_location.svg")
export const image_phone_in_talk = require("./assets/image_phone_in_talk.svg")
export const image_schedule = require("./assets/image_schedule.svg")
export const image_add = require("./assets/image_add.svg")
export const delete_icon = require("./assets/delete_icon.svg")
export const orderPlacedImage = require("./assets/orderPlacedImage.png")
export const storeFront = require("./assets/store_front.svg")
export const customLoaderGif = require("./wingo_custom_loader.gif")
export const appStoreIcon = require("./app_store.svg")
export const playStoreIcon = require("./play_store.svg")