import React, { Component } from 'react';
import { Box, Button, Dialog, DialogContent, IconButton, Typography} from '@material-ui/core';
import { success } from "./../src/SocialLogin/assets";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

interface OtpSuccessfulProps {
    open: boolean;
    onClose: any;
  }

  const theme = createTheme({
    overrides: {
      MuiDialog: {
        paper: {
          maxWidth: '720px !important',
          padding: "16px !important",
          borderRadius: "16px"
        },
      },
    }
});

export default class OtpSuccessfulPopup extends Component<OtpSuccessfulProps>{

    render() {
        const { open, onClose } = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Dialog open={open} maxWidth="md" >
                <IconButton onClick={onClose} style={{alignSelf: "flex-end", width: "32px", height: "32px", color: "rgba(49, 49, 49, 1)"}}>
                  <CloseIcon />
                </IconButton>
                <DialogContent style={{padding: "0px"}}>
                    </DialogContent>
                    <Box textAlign="center" style={{padding: "0px", margin: "90px auto"}}>
                        <img src={success} alt="success" style={{width:"250px", height: "250px"}} />
                        <Typography style={{fontFamily: 'Montserrat', margin: "52px 100px 0",   fontSize: '24px', fontWeight: '700', lineHeight: '32px', letterSpacing: "-0.005em", textAlign: "center" }}>
                            Congratulations, your account has been successfully created.
                        </Typography>
                </Box>
                </Dialog>
                </ThemeProvider>
        );
    }
}