export const noOrderHistory = require('../assets/no_order_history.png')
export const deliveryHead = require('../assets/delivery_head.svg')
export const collectionHead = require('../assets/collection_head.svg')
export const imageNotes = require('../assets/notes_image.svg')
export const imageEuroSign = require('../assets/image_euro_sign.svg')
export const imageRestaurantSign = require('../assets/image_restaurant.svg')
export const collectionImage = require('../assets/collection.svg')
export const cookingImage = require('../assets/cooking.svg')
export const deliveredImage = require('../assets/delivered.svg')
export const orderAcceptedImage = require('../assets/order_accepted.svg')
export const orderPickedImage = require('../assets/order_picked.svg')
export const orderPlacedImage = require('../assets/order_placed.svg')
export const orderReadyImage = require('../assets/order_ready.svg')

export const collectionImageDone = require('../assets/collection_done.svg')
export const cookingImageDone = require('../assets/cooking_done.svg')
export const orderAcceptedImageDone = require('../assets/order_accepted_done.svg')
export const orderPickedImageDone = require('../assets/order_picked_done.svg')
export const orderPlacedImageDone = require('../assets/order_placed_done.svg')
export const orderReadyImageDone = require('../assets/order_ready_done.svg')
export const noOrderHistoryResultFound = require('../assets/no_order_history_result.png')