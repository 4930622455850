import React, { Component } from 'react';
import { Box, Dialog, DialogContent, Button, Link} from '@material-ui/core';
import { logoutAccount } from './assets';

interface LogoutProps {
    open:boolean
}
interface S {
    enablePasswordField: boolean;
    currentPassword: string;
}

export class LogoutPopup extends Component< LogoutProps, S> {
    constructor(props:  LogoutProps){
        super(props);
        this.state = {
            enablePasswordField:true,
            currentPassword: '',
        }
    }
    
  render() {
    const {open} = this.props;
    return (
      <Dialog open={open} style={{marginTop:"100px"}}
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
          paddingBottom: '20px' 
        } 
      }} >
        <DialogContent style={{padding: "0px"}}>
          <Box test-id ="logout-box" textAlign="center" style={{margin: "0px 40px", display:"flex", flexDirection:"column", gap:"40px", alignItems:"center"}}>
            <img test-id ="logout-image" style={{height: '327px'}} src={logoutAccount} alt="Logout account" />
            <div test-id ="logout-content" style={{display: 'flex',flexDirection: 'column',gap: '16px',alignItems: 'center',textAlign: 'center'}}>
                <div test-id ="logout-title" style={{fontFamily:"Barmeno", fontWeight:700, fontSize:"24px"}}>LOG OUT</div>
                <div test-id ="logout-typography" style={{fontFamily:"Barmeno", fontWeight:500, fontSize:"18px",  color: "#747474"}}>Are you sure you want to log out from your account?</div>
            </div>
            <div style={{ padding: "0px 0px", display: "flex", flexDirection: "row", gap: "30px", justifyContent:"center" }}  >
                <Link href='/Profile'> <Button test-id="cancel-logout" className="cancelButton" onClick={() => {}} id="submit" style={{fontFamily:"Barmeno"}} >
                    Cancel
                </Button></Link>
                <Link href='/Profile'> <Button test-id="submit-logout" className={"submitButtonDet"} disabled={false} onClick={() => {localStorage.removeItem('authToken');localStorage.removeItem('restaurant_details')} } id="submit" style={{ fontFamily: "Barmeno" }} > 
                Log out
                </Button></Link>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default LogoutPopup;
