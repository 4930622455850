Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile_bio/user_deatils";
exports.updateCustomizableProfileEndPoint = "bx_block_profile_bio/profile_bio/update_personal_details";
exports.updateEmailAddressEndpoint = "bx_block_profile_bio/profile_bio/email_change"
exports.UpdateMobileEndPoint = "bx_block_profile_bio/change_phone_number"
exports.confirmOtpEndPoint = "bx_block_profile_bio/otp_validation"
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.phoneRegex = /^\+(?:\d(?:[-()]?) ?){6,15}\d$/
// Customizable Area End
