import React, { ChangeEvent, Component } from 'react';
import { Box, Dialog, DialogContent, Button} from '@material-ui/core';
import { updateDetail } from './assets';
import "./Profile.css"

interface UpdateDetailsProps {
    emailError: string|null;
    updateEmailAddress:(value:string)=> void;
    removeError:()=>void;
    updatePopup:boolean;
    navigateToPages:()=>void;
}
interface S {
  enablePasswordField: boolean;
  email: string;
  invalidEmail: boolean;
  apiEmailError: string|null
}

export class UpdateDetailsPopup extends Component<UpdateDetailsProps, S> {
  constructor(props: UpdateDetailsProps) {
    super(props);
    this.state = {
      enablePasswordField: true,
      email: '',
      invalidEmail: false,
      apiEmailError: ""
    }
  }
  async componentDidUpdate(prevProps : UpdateDetailsProps, prevState:S) {
    // Check if any of the props have changed
    if (prevProps.emailError !== this.props.emailError) {
      // Trigger an action here
      this.setState({apiEmailError: this.props.emailError})
    }
  }

  checkEmailValidation=(email:string)=>{
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    this.setState({invalidEmail: !emailRegex.test(email)})
  }

  render() {
    return (
      <Dialog id="emailUpdate" open={this.props.updatePopup} style={{ marginTop: "100px", boxSizing:"border-box" }}
        PaperProps={{
          style: {
            borderRadius: '16px'
          }
        }} >
        <DialogContent style={{ padding: "0px" }}>
          <Box textAlign="center" style={{ display: "flex", flexDirection: "column", gap: "3vh", alignItems: "center" }}>
            <img style={{ height: '250px' }} src={updateDetail} alt="Delete account" />
            <div style={{display:"flex", gap:"2vh", flexDirection:"column", width:"100%"}}>
             <div style={{ fontFamily: "Barmeno", fontWeight: 700, fontSize: "24px", color:"#231F20"}}>Update e-mail address</div>
              <div style={{ fontFamily: "Barmeno", fontWeight: 500, fontSize: "18px", color:"#747474" }}>Enter your new e-mail address to update</div>
            <div className="password_field__wrapper" style={{ width: '100%', alignItems: "start" }}>
              <label style={{ fontFamily: "Barmeno", marginBottom:"8px", color:"#747474", fontSize:"16px", fontWeight:500 }} htmlFor="email" > E-mail Address </label>
              
                <input
                className='email-input-update'
                onBlur={(event)=>{this.checkEmailValidation(event.target.value)}}
                  style={{fontSize:"16px", borderRadius: "8px", fontFamily: "Barmeno", width: "100%", height: "56px", fontWeight:600,  border:"1px solid #313131", padding:"0px 10px" }}
                  type="text"
                  id="outlined-basic" name="email" placeholder="Enter email address"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => { this.setState({ email: event.target.value, invalidEmail:false, apiEmailError:"" }); this.props.removeError()}}
                  value={this.state.email}
                />
              { this.state.apiEmailError && <div className='errors' style={{fontFamily:"Barmeno" }}>{this.state.apiEmailError}</div> }
              {this.state.invalidEmail && <div className='errors' style={{fontFamily:"Barmeno" }}>The email address you entered is not in a valid format</div>}
            </div>
            </div>
            <div style={{ padding: "0px 0px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}  >
              <Button className="cancelButton" 
              onClick={() => {this.props.navigateToPages(); this.setState({invalidEmail:false, apiEmailError:""}) }} 
              id="submit" style={{ fontFamily: "Barmeno" }} >
                Cancel
              </Button>
              <Button className={this.state.invalidEmail || !(!this.state.apiEmailError) || (this.state.email==="")?"submitButtonDisabledDet":"submitButtonDet"} disabled={this.state.invalidEmail || !(!this.state.apiEmailError) || (this.state.email==="")} 
              onClick={() => {this.props.updateEmailAddress(this.state.email);
                    if (!this.state.apiEmailError) {
                      this.setState({ email: "" }); 
                    }
                  }}
              id="submit" style={{ fontFamily: "Barmeno" }}  >
                Update
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default UpdateDetailsPopup;