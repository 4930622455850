import React, { ChangeEvent, Component } from 'react';
import { Box, Dialog, DialogContent, Button, IconButton} from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface DeleteAccountProps {
  deletePassword: () => void
  activeOrderOpen:boolean
  navigateToPages:(page:string)=>void
}

export class ActiveOrdersPresentPopup extends Component<DeleteAccountProps> {
  constructor(props: DeleteAccountProps) {
    super(props);
  }

  render() {
    return (
      <Dialog open={this.props.activeOrderOpen} style={{ marginTop: "100px", boxSizing:"border-box" }}
        PaperProps={{
          style: {
            borderRadius: '16px',
            paddingBottom: '20px'
          }
        }} >
        <DialogContent style={{ padding: "0px" }}>
          <Box textAlign="center" style={{ display: "flex", flexDirection: "column", gap: "40px", alignItems: "center" }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'start', textAlign: 'start' }}>
              <div style={{ fontFamily: "Barmeno", fontWeight: 700, fontSize: "24px" }}>Are you sure you want to delete your account?</div>
              <div style={{ fontFamily: "Barmeno", fontWeight: 400, fontSize: "18px", color:"#747474" }}>You have Active Orders or Gift Card has some balance. Deleting account will remove all your data.</div>
            </div>
            <div style={{ padding: "0px 0px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}  >
              <Button className="cancelButton" onClick={() => { this.props.navigateToPages("CustomisableUserProfiles") }} id="submit" style={{ fontFamily: "Barmeno" }} >
                Cancel
              </Button>
              <Button className={"submitButtonDet"} disabled={false} onClick={this.props.deletePassword} id="submit" style={{ fontFamily: "Barmeno" }}  >
                Confirm
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default ActiveOrdersPresentPopup;
