import React from 'react'
import ManagePaymentController,{Props} from './ManagePaymentController'
import Profile from '../../../components/src/Profile'
import { Button, IconButton } from '@material-ui/core'
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { CardData } from '../../../components/src/ConfirmOrder/ConfirmOrder.web';
import "./ManageCardPayment.css"
import GetCardBrand from '../../../components/src/ConfirmOrder/GetCardBrand.web';
import DialogPopupWeb from '../../../components/src/DialogPopup.web';
import RemoveCodeAlert from '../../../components/src/EmptyCartItemAlert/RemoveCodeAlert.web';
import { Alert } from '@material-ui/lab';
import Paymentadmin2 from './Paymentadmin2.web';
import { noCardDetailsFound } from './assets';

export default class ManagePayment extends ManagePaymentController {
    constructor(props:Props){
        super(props)
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    CardDetailComponent=(data:{id:string,name:string|null,exp_month:number, exp_year:number, brand:string, last_4_digit:string}) => {
        return(
        <div className='card_detail_component'>
            <div className='card_detail_container'>
                <div className='card_brand_number_container'> <GetCardBrand brand={data.brand} /> Card ending in <span style={{fontWeight:700,color:"#1D1D1D"}}>{data.last_4_digit}</span></div>
                <div className='card_name_expiry'>
                    <div className='card_name_container'>
                        <div>Name</div>
                        <div style={{fontWeight:500,fontSize:"16px"}}>{data.name}</div>
                    </div>
                    <div className='card_name_container'>
                        <div>Expiry</div>
                        <div style={{fontWeight:500,fontSize:"16px"}}> {this.getMonthYear(data.exp_month,data.exp_year)}</div>
                    </div>
                </div>
            </div>
            <div style={{display: "flex",flexDirection: "row",gap: "16px"}}>
            <IconButton style={{backgroundColor:"#FAF4F4", borderRadius:"8px", width:"32px", height:"32px"}}
            onClick={()=>{this.editCardDetails({id:data.id,cardHolder:data.name,last4:data.last_4_digit,exp_month:data.exp_month,exp_year:data.exp_year})}}><EditRoundedIcon style={{color:"#2C6F37", fontSize:"18px"}}/></IconButton>
            <IconButton style={{backgroundColor:"#FAF4F4", borderRadius:"8px", width:"32px", height:"32px"}} onClick={()=>{this.openRemoveCardPopup(data.id)}}><DeleteOutlineRoundedIcon style={{color:"#E11B22", fontSize:"18px"}} /></IconButton>
            </div>
        </div>
        )
      }
    CardsList = (card_data_list:CardData[]) => {
        return(
        <div style={{display: "flex",flexDirection: "column",gap: "16px", fontFamily: "Barmeno"}}>
          {card_data_list.map((card_data,index) => (
            this.CardDetailComponent({id : card_data.id, name:card_data.attributes.billing_details.name , exp_month: card_data.attributes.card.exp_month,
                exp_year: card_data.attributes.card.exp_year, brand: card_data.attributes.card.brand, last_4_digit : card_data.attributes.card.last4})
          ))}
        </div>
      )}

    AddCardComponent = () => {
        return (
            <div className="main-panel" style={{ minHeight: "530px", display: "flex", flexDirection: "column", gap: "32px" }}>
                <div className='add_new_card_arrow' ><IconButton onClick={this.closeAddCard}><ArrowBackRoundedIcon style={{ color: "#1d1d1d", width: "34px", height: "34px" }} /></IconButton> {this.state.card_details_edit?"Update Card" :"Add New Card"}</div>
                <Paymentadmin2 id="AddCard" navigation={this.props.navigation} changeOpenAddPaymentPopup={this.closeAddCard} token={this.state.token} getCardList={this.getNewlyAddedCardDetails} card_details_edit={this.state.card_details_edit} />
            </div>
        )
    }

    CardDetailsFoundComponent =()=>{
        const {card_data_list} = this.state
        return(
            <div className="main-panel" style={{ minHeight: "530px", display: "flex", flexDirection: "column", gap: "32px" }}>
                {card_data_list.length>0?
                <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                    <div id="manage_payment_heading" style={{ fontFamily: 'Barmeno', fontSize: '20px', fontWeight: 700, lineHeight: '24px', textAlign: 'left' }}>Manage Payments</div>
                    {this.CardsList(card_data_list)}
                </div>:
                this.NoCardDetailsFound()
                    }
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", fontFamily: "Barmeno", }}>
                    <Button
                        style={{ color: "white", backgroundColor: "#E11B22", width: '250px', height: '56px', padding: '16px 0px 0px 0px', borderRadius: '8px', fontWeight: 500, fontSize: "16px" }} onClick={this.openAddCard} >
                        Add new card
                    </Button>
                </div>
            </div>
        )
    }

    NoCardDetailsFound=()=>{
        return(
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <div style={{display:"flex", flexDirection:"column", gap:"8px"}}>
             <div style={{ fontFamily: 'Barmeno', fontSize: '20px', fontWeight: 700, lineHeight: '24px', textAlign: 'left' }}>Manage Payments</div>
             <div style={{fontFamily: 'Barmeno', fontSize: '16px', fontWeight: 500, lineHeight: '14px', textAlign: 'left'}}>You have no payment cards added. Please add one to proceed.</div>
             </div>
             <img style={{width:"327px",height:"327px", alignSelf:"center"}} src={noCardDetailsFound} alt='nocarddetails'/>
        </div>
        )
    }
    // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <Profile navigateToPages={this.navigateToPage}>
            {this.state.deleteSuccess && <Alert iconMapping={{
          success: <CheckCircleRoundedIcon fontSize="inherit" />,
        }} style={{top:"200px",color: "#313131",backgroundColor:"white", width:"fit-content", boxShadow:"4px 4px 12px #bbb", position:"absolute", right:"56px", zIndex:99 }} >
          Card Details has been deleted successfully.
        </Alert>}
        {this.state.addedSuccess && <Alert iconMapping={{
          success: <CheckCircleRoundedIcon fontSize="inherit" />,
        }} style={{top:"200px",color: "#313131",backgroundColor:"white", width:"fit-content", boxShadow:"4px 4px 12px #bbb", position:"absolute", right:"56px", zIndex:99 }} >
          Card Details has been added successfully.
        </Alert>}
        {this.state.addCardOpen?
                    this.AddCardComponent():
            this.CardDetailsFoundComponent()
  }
            <DialogPopupWeb open={this.state.removeCardPopup} onClose={this.closeRemoveCardPopup}><RemoveCodeAlert removePoints={()=>{}} removeGiftCard={()=>{}} removeCouponCode={this.removeCardFromList} onClose={this.closeRemoveCardPopup} heading_remove={"Delete Card"} content_remove={"Are you sure you want to delete the card details from your profile?"} value1={false} value2={this.state.card_id_to_delete}/></DialogPopupWeb>
        </Profile>
    )
    // Customizable Area End
  }
}
