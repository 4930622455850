Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDelete = "DELETE"
exports.productAPiEndPoint = "catalogue/catalogues";
exports.dealsEndPoint="bx_block_catalogue/deals_list";
exports.menuDataEndPoint="bx_block_catalogue/catalogues"
exports.filterDataEndPoint="bx_block_catalogue/show_all_allergens"
exports.favouriteEndPoint = "bx_block_favourites/favourites"
exports.deleteFavouriteEndPoint = "bx_block_favourites/favourites"
exports.allergensList = "bx_block_catalogue/show_all_allergens"
exports.cartsList = "bx_block_shopping_cart/carts"
exports.catalogueIdReg = /[?&]catalogue_id=([^&]*)/
exports.storeInformationApiEndPoint = "bx_block_storelocator/store_locators/"
exports.apiMethodTypePost = "POST";
exports.sendCartItemsApiEndPoint = "bx_block_shopping_cart/carts"
exports.setRestaurantApiEndPoint = "bx_block_order_management/restaurant_selections"
exports.removeAllItemApiEndPoint = 'bx_block_shopping_cart/remove_cart_items_all'
exports.getRestaurantDetailAndCartApiEndPoint = 'bx_block_order_management/restaurant_selections/change_restaurant'
exports.defaultCartItem = {
  id: "",
  type: "string",
  attributes: {
      id: 0,
      cart_id: 0,
      catalogue_id: 0,
      spice_level: "",
      choose_your_type: "",
      quantity: 0,
      price: 0,
      product_name: "",
      images: {
          url: "",
          content_type: "",
          file_name: ""
      },
      cart_item_sides_attributes: [],
      drinks: [],
      nibbles: []
  }
};
// Customizable Area End