import React, {Component } from 'react';
import { Box, Dialog, DialogContent, Button} from '@material-ui/core';
import { success } from '../../settings2/src/assets';

interface SuccessProps {
  successOpen : boolean;
  valueUpdated: string;
  navigateToPages:(value: string)=>void
  onClose:()=>void
}

interface S{

}

export class SuccessPopup extends Component<SuccessProps,S> {
  constructor(props: SuccessProps) {
    super(props);
    this.state={

    }
  }

  render() {
    return (
      <Dialog open={this.props.successOpen} onClose={this.props.onClose} style={{ boxSizing:"border-box" }}
        PaperProps={{
          style: {
            borderRadius: '16px'
          }
        }} >
        <DialogContent style={{ padding: "0px" }}>
          <Box textAlign="center" style={{ display: "flex", flexDirection: "column", gap: "70px", alignItems: "center" }}>
            <img style={{ height: '250px' }} src={success} alt="Delete account" />
            <div style={{fontFamily:"barmeno", fontSize:"24px", fontWeight:700}}>
            {this.props.valueUpdated} has been verified successfully!</div>
              <Button style={{fontFamily: "Barmeno" ,borderRadius: "8px ",padding: "16px 0px",fontSize: "16px",fontWeight: 700,width: "100%",height:" 54px",color: "#FFFFFF",backgroundColor: "#E11B23"}} id="submit" 
              onClick={()=>{this.props.navigateToPages('CustomisableUserProfiles'); this.props.onClose()}}>
              CLOSE
              </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default SuccessPopup;
