import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  googlePayButtonRef:any
  token:string|null
  order_id:string|undefined
  openPaymentPopup:(img:string,button:string,heading:string,content:string)=>void
  isPaymentPopupOpen?:boolean;
  amount:string;
  cardPayButtonRef:any
  payment_method_id?:string;
  cvv?:string;
  cashPayButtonRef:any;
  orderConfirmPopup:()=>void;
  setPaymentStatus: (status: boolean) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  payment_id:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GooglePayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  gPayPaymentCallId: string = ""
  doPaymentViaCardApiCallId:string=""
  doPaymentViaCashApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      payment_id:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if(prevState.payment_id !== this.state.payment_id){
        this.doGpayPayment()
    }
      
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId===this.gPayPaymentCallId){
        if (responseJson && responseJson.message) {
          if (responseJson.message === "Transaction successful") {
            this.props.openPaymentPopup("success", "CLOSE", 'Payment Successful!', "Your payment for the order has been made successfully!")
          }
        } else if (responseJson && responseJson.error) {
          this.props.openPaymentPopup("failure", "TRY AGAIN", 'Payment Failed!', "Unfortunately, your payment has failed. Please try again.")
        }
    }
    if(apiRequestCallId === this.doPaymentViaCardApiCallId){
      this.setCardPaymentResponse(responseJson)
      }
    if(this.doPaymentViaCashApiCallId === apiRequestCallId){
      this.setCashPaymentResponse(responseJson)
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setPaymentId=(tokenString:string)=>{
    const tokenObject = JSON.parse(tokenString)
    const id:string = tokenObject.id
    this.setState({payment_id:id})
  }

  setCardPaymentResponse=(responseJson:{meta?:{payment:{status:string}},errors?:{stripe:string}[], error?: string})=>{
    if(responseJson){
      if(responseJson.meta && responseJson.meta.payment.status === "succeeded"){
        this.props.setPaymentStatus(true)
          this.props.openPaymentPopup("success","CLOSE",'Payment Successful!',"Your payment for the order has been made successfully!")
      }
      else if(responseJson.meta && responseJson.meta.payment.status === "requires_action"){
        this.props.setPaymentStatus(false)
          this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',"Unfortunately, your payment has failed. Please try again.")
      }else if(responseJson.errors){
        this.props.setPaymentStatus(false)
          this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',responseJson.errors[0].stripe)
      } else if(responseJson.error) {
        this.props.setPaymentStatus(false)
        this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',"Payment unsuccessful. Please check your details and try again or consider changing your payment method.")
      }
  } 
  }

  setCashPaymentResponse=(responseJson:{message?:string,error?:string})=>{
      if(responseJson.message ){
          this.props.setPaymentStatus(true)
          this.props.orderConfirmPopup()
      }else if(responseJson.error){
          this.props.openPaymentPopup("failure","TRY AGAIN",'Payment Failed!',"Unfortunately, your payment has failed. Please try again.")
      }
  }

  doGpayPayment=()=>{
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token:this.props.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.gPayPaymentCallId = requestMessage.messageId
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.gpayEndPoint
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      const httpBody = {
          order_id: this.props.order_id,
          payment: {
              id: this.state.payment_id,
              currency: configJSON.currencyCode,
              status: configJSON.paymentStatus,
              payment_method_type: configJSON.paymentMethodType
          }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  doPaymentViaCard=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    };
    
    const httpBody = {
      payment: {
      order_id:Number(this.props.order_id) ,
      payment_method_id: this.props.payment_method_id,
      cvv: Number(this.props.cvv)
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.doPaymentViaCardApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.doPaymentApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  doPaymentViaCash=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.doPaymentViaCashApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCODApiEndPoint}${this.props.order_id}/pay_on_cash`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
