// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const giftbox = require("../assets/giftbox.png");
export const more = require("../assets/more.png");
export const sale = require("../assets/sale.jpg");
export const share = require("../assets/share.png");
export const user = require("../assets/user.png");
export const medal = require("../assets/medal.png");
export const rewardIcon = require("../assets/reward.png");
export const closeIcon = require("../assets/back.png")
export const BTMcoupons = require("../assets/BTMcoupons.png")
export const BTMgifiting = require("../assets/BTMgifiting.png")
export const BTMhome = require("../assets/BTMhome.png")
export const BTMreward = require("../assets/BTMreward.png")
export const BTMservices = require("../assets/BTMservices.png")
export const saleImage1 = require("../assets/sale1.jpeg");
export const saleImage2 = require("../assets/sale2.jpeg");
export const saleImage3 = require("../assets/sale3.jpeg");
export const userImage = require("../assets/user.png");
export const giftImage = require("../assets/gift.jpeg");
export const backIcon = require("../assets/back.png");
export const headerImage = require("../assets/header_image.png");
export const congratulationGiftCard = require("../assets/congratulation_gift_card.png");
export const thankyouGiftCard = require("../assets/thankyou_gift_card.png");
export const birthdayGiftCard = require("../assets/birthday_gift_card.png");
export const americanExpress = require("../assets/american_express.svg");
export const visa = require("../assets/visa.svg");
export const masterCard = require("../assets/mastercard.svg");
export const masterCard1 = require("../assets/mastercard1.svg");
export const unionPay = require("../assets/union_pay.svg");
export const jcbImage = require("../assets/jcb-card.svg")
export const discover = require("../assets/discover.png")
export const diners_club = require("../assets/club_diners.png")
export const noGiftCardHistory = require("../assets/no_gift_history.png");
// Customizable Area End
