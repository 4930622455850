export const banner = require("../assets/banner.png");
export const image_burger = require("../assets/image_burger.png");
export const image_chickenwings = require("../assets/image_chickenwings.png");
export const image_collection = require("../assets/image_collection.png");
export const image_delivery = require("../assets/image_delivery.png");
export const image_doodle2 = require("../assets/image_doodle2.svg");
export const Feedback = require("../assets/Feedback.png")
export const image_doodle3 = require("../assets/image_doodle3.png");
export const image_pot = require("../assets/image_pot.png");
export const image_chilli = require("../assets/image_ chilli.png");
export const group = require("../assets/group.png");
export const image_giftcard = require("../assets/image_giftcard.png");
export const social_login = require("../assets/group_banner.png");
export const hotchilli = require("../assets/hot_chilli.png");
export const image_logo = require("../assets/image_logo.png");
export const wingobg = require("../assets/wingo_bg_comp.webp")
export const aboutUs = require("../assets/About_Us_banner.svg")
export const wingoChicken = require("../assets/wingoChicken.svg")
export const veggies = require("../assets/veggies.png")
export const spicyLevel = require("../assets/Spicy.svg")
export const arrow = require("../assets/arrow.svg")
export const spicy1 = require("../assets/spicy_1.svg")
export const spicy2 = require("../assets/spicy_2.svg")
export const spicy3 = require("../assets/spicy_3.svg")
export const recipes = require("../assets/recipes.svg")
export const wingoRewards = require("../assets/Rewards_header.svg")
export const reward1 = require("../assets/reward1.svg")
export const reward2 = require("../assets/reward2.svg")
export const reward3 = require("../assets/reward3.svg")
export const round1 = require("../assets/round1.svg")
export const round2 = require("../assets/round2.svg")
export const chicken = require("../assets/chicken.png")
export const score1 = require("../assets/reward_point.png")
export const score2 = require("../assets/reward_worth.png")
export const chilly = require("../assets/chilly.svg")
export const systemMessage = require("../assets/systemsg.svg")

export const specialDiscounts = require("../assets/special_discount.svg");
export const wingo_reward = require("../assets/wingo_reward.svg");
export const order_steps = require("../assets/order_steps.webp");
export const image = require("../assets/order_steps.svg");
export const gift_card = require("../assets/gift_card.svg");

export const chickenWhole = require("../assets/chicken_whole.png")
export const apple_mockup = require("../assets/apple_mockup.svg")
export const platter_Chicken = require("../assets/platter_Chicken.svg")
export const image_google_play_store = require("../assets/image_google_play_store.png");
export const image_appleicon = require("../assets/image_appleicon.png");
