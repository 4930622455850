import axios from "axios";
import { createNewArray } from "./utils";
export const configJSON1 = require("./../../framework/src/config.js");
export const configJSON = require("./../../blocks/catalogue/src/config");

export const addToFavourite= async(id:number) =>{
  const headers={
    headers: {
      'token': localStorage.getItem('authToken')
    }
  }
  try {
    const response = await axios.post(
      `${configJSON1.baseURL}/${configJSON.favouriteEndPoint}`,{
        data:{"favouriteable_id": id}
      }, headers
    );
    return null
}
catch (error) {
  const typedError = error as any;
  return typedError.response.status
}
}

export const filterListMenu = async (signal: AbortSignal,allergen:string[],spiceLevel:string|null,diet:string|null, search:string|null,restaurant_id:string|null) => {
  const params= {
    product_type: diet || undefined,
    spice_level: spiceLevel || undefined,
    allergens: allergen.length > 0 ? allergen : undefined,
    query: search || undefined,
    restaurant_id: restaurant_id || undefined
  }
  const headers={
      'token': localStorage.getItem('authToken'),
      'Content-Type': 'application/json'
  }
    try {
      const response = await axios.get(
        `${configJSON1.baseURL}/${configJSON.menuDataEndPoint}`,{params:params,headers:headers,signal}
      );
          const updatedArr = createNewArray(response.data.data);
          return updatedArr;
    } catch (error: any) {
      if(error.response){
        return error.response.status;
      } else if(error.code) {
        return []
      }
        
    }
  }

export const allergens= async() =>{
  try {
    const response = await axios.get(
      `${configJSON1.baseURL}/${configJSON.allergensList}`,
    );
    const allergens = response.data.data.map((allergenObject:{id: number; name: string; created_at: Date; updated_at:Date})=>
      allergenObject.name
    )
    return allergens
}
catch (error) {
  const typedError = error as any;
  return typedError.response.status
}
}

export const removeFromFavourite =  async (id: number)=>{
  const headers={
    headers: {
      'token': localStorage.getItem('authToken')
    }
  }
  try {
    const response = await axios.delete(
      `${configJSON1.baseURL}/${configJSON.deleteFavouriteEndPoint}/${id}`, headers
    );
    return null
    
}
catch (error) {
  const typedError = error as any;
  return typedError.response.status
}
}

export const unsubscribeNewsLetterReasons = async () => {
  try {
    const response = await axios.get(
      `${configJSON1.baseURL}/bx_block_custom_forms/unsubscription_reasons`
    );
    return response;
}
catch (error) {}
}

export const unsubscribeNewsLetter = async ({email, note, unsubscription_reason_id}: {email: string, note: string, unsubscription_reason_id: number}) => {
  try {
    const response = await axios.put(
      `${configJSON1.baseURL}/bx_block_custom_forms/newsletter_subscriptions/unsubscribe`, {
        form: {
          "email": email,
          "note": note,
          "unsubscription_reason_id": unsubscription_reason_id
        }
      }
    );
    return response;
}
catch (error) {}
}

export const subscribeNewsLetter = async (email: string) => {
  try {
    const response = await axios.post(
      `${configJSON1.baseURL}/bx_block_custom_forms/newsletter_subscriptions`, {
        form: {
          "email": email
        }
      }
    );
    return response;
}
catch (error) {}
}

export const resendEmail = async (email: string) => {
  try {
    const response = await axios.post(
      `${configJSON1.baseURL}/account_block/accounts/resend_link`, {
        "data": {
          "attributes": {
              "email": email
          }
      }
      }
    );
    return response;
}
catch (error) {}
}

export const resendOtp = async (phone: string) => {
  try {
    const response = await axios.post(
      `${configJSON1.baseURL}/account_block/accounts/send_otps`, {
        "data": {
          "attributes": {
              "full_phone_number": phone
          }
      }
      }
    );
    return response;
}
catch (error) {}
}

export const socialMediaLinks = async () => {
  try {
    const response = await axios.get(
      `${configJSON1.baseURL}/bx_block_content_management/social_media_links`
    );
    return response;
}
catch (error) {}
}