import React from "react";
import { customLoaderGif } from "./assets";
import { Dialog, styled } from "@material-ui/core";

interface myProps {
  loading: boolean;
}
const TransparentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: 'transparent',
    boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px',
  },
}));

const CustomLoader=(props: myProps)=> {
  return (
    <TransparentDialog open={props.loading} >
    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
        <img style={{width:"200px",height:"200px"}} src={customLoaderGif} alt="loading"/>
      </div>
      </TransparentDialog>
  );
}

export default CustomLoader;
