import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {copyContent} from "./../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  endPoint: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  text: string;
  copied?: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      text: `${location.origin}${this.props.endPoint}`,
      copied: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  changeInput = (val: string) => {
    this.setState({text: val});
  }

    handleClick = () => {
      // click functionality goes here. this click will get called along with the facebook share popup open operation
    }

    copyOnClick =() => {
      copyContent(this.state.text);
      this.setState({copied: true});
      setTimeout(() => { 
        this.setState({copied: false});
      }, 2000);
    }
  // Customizable Area End
}
