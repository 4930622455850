// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert } from "react-native";
export const configJSON = require("./config");
import { IReward, IService, IDiscountCoupon, IGiftCard, ISendGift, SnackbarColor } from "./InappgiftingModels";
import { formatCVC, formatCreditCardNumber, formatDate, formatExpirationDate } from "../../../components/src/utils";
import { congratulationGiftCard } from "./assets";

const initialGiftObj: ISendGift = {
  recipientName: "",
  recipientEmail: "",
  recipientMobileNumber: "",
  coupon: "",
  discounts: ""
}

export interface GiftCardDetail {
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title":string,
      "refrence_number": string,
      "gift_card_recipient": string,
      "gift_card_value": 30.0,
      "redeemed_amount": 0.0,
      "balance_amount": 30.0,
      "your_name": string,
      "email_address": string,
      "recipients_name": string,
      "recipients_email_address": string,
      "message": string,
      "status": string,
      "created_at":string,
      "updated_at": string,
      "sender": {
          "data": {
              "id": string,
              "type": string,
              "attributes": {
                  "activated": boolean,
                  "country_code": null|string,
                  "email": string,
                  "first_name": string,
                  "full_phone_number": string,
                  "last_name": string,
                  "phone_number": null|string,
                  "type": string,
                  "created_at": string,
                  "updated_at": string,
                  "device_id": null,
                  "unique_auth_id": null
              }
          }
      },
      "payment_transaction": {
          "id": number,
          "account_id": number,
          "charge_id": string,
          "amount": number,
          "currency": string,
          "charge_status": string,
          "created_at": string,
          "updated_at": string,
          "transactionable_type": string,
          "transactionable_id": number,
          "payment_method_type": null|string,
          "transaction_number":string
      }
  }
}

export interface RewardReceivedDataInterface {
  error: "",
  errors: '',
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string,
    gift_card_value: number,
    created_at: string,
    updated_at: string,
    sender: {
      id: number,
      first_name: string,
      last_name: string,
      full_phone_number: string,
      country_code: null,
      phone_number: number,
      email: string,
      activated: true,
      device_id: string,
      unique_auth_id: string,
      password_digest: string,
      created_at: string,
      updated_at: string,
      user_name: null,
      platform: null,
      user_type: null,
      app_language_id: null,
      last_visit_at: null,
      is_blacklisted: boolean,
      suspend_until: null,
      status: string,
      role_id: null,
      stripe_id: null,
      stripe_subscription_id: null,
      stripe_subscription_date: null
    },
    receiver: {
      id: number,
      first_name: string,
      last_name: string,
      full_phone_number: string,
      country_code: null,
      phone_number: null,
      email: string,
      activated: false,
      device_id: string,
      unique_auth_id: string,
      password_digest: string,
      created_at: string,
      updated_at: string,
      user_name: null,
      platform: null,
      user_type: null,
      app_language_id: null,
      last_visit_at: null,
      is_blacklisted: boolean,
      suspend_until: null,
      status: string,
      role_id: null,
      stripe_id: null,
      stripe_subscription_id: null,
      stripe_subscription_date: null
    }
  }
}

export interface TargetType {
  name: string;
  value: string;
}

export interface ItemDataInter {
  attributes:
  {
    code: string,
    created_at: string,
    detail: string,
    discount_value: number,
    id: number,
    min_purchase_value: null,
    title: string,
    updated_at: string,
    valid_from: string,
    valid_to: string
  },
  id: string,
  type: string
}

export interface RewardReceivedDataArray {
  errors: null,
  data: Array<RewardReceivedDataInterface>
}

export interface ServiceDataInterface {
  attributes: {
    created_at: string,
    discount: number,
    id: number,
    mrp: number,
    name: string,
    selling_price: number,
    updated_at: string
  },
  id: number,
  type: string

}

export interface ServiceDataInterfaceArray {
  errors: null,
  data: Array<ServiceDataInterface>
}


export interface ItemList {

  id: number,
  name: string,
  url: string

}

export interface SubIetemData {
  id: number,
  name: string,
  url: string
}

export interface ItemImage {
  index: number,
  item: SubIetemData
}


interface GetRewardData {
  attributes:
  {
    code: string,
    created_at: string,
    description: string,
    expiry_date: string,
    id: number
    , reward_type: string,
    reward_value: string,
    title: string,
    updated_at: string
  }
  , id: string,
  type: string

}

interface GetRewardDataArray {
  errors: null,
  data: Array<GetRewardData>
}



export interface GetDiscountData {
  attributes:
  {
    code: string,
    created_at: string,
    detail: string,
    discount_value: number,
    id: number,
    min_purchase_value: null,
    title: string,
    updated_at: string,
    valid_from: string,
    valid_to: string
  },
  id: string,
  type: string
}

export interface GiftCardTableAttributes {
  id: number;
  refrence_number: string;
  recipients_name: string;
  gift_card_value: number;
  redeemed_amount: null | number;
  balance_amount: null | number;
  updated_at: string;
  status: string;
}

export interface GiftCardTableData {
  id: string;
  type: string;
  attributes: GiftCardTableAttributes

}

interface GetDiscountDataArray {
  errors: null,
  data: Array<GetDiscountData>
}


export interface DataInterface {
  id: number,
  name: string,
  url: string
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  colorId: string;
  activeIndex: number;
  entries: number;
  activeSlide: number;
  index: number;
  data: Array<DataInterface>;
  rewardData: Array<GetRewardData>;
  discountData: Array<GetDiscountData>;
  isRewardModal: boolean;
  selectedItem: number;
  serviceData: Array<ServiceDataInterface>;
  token: string;
  shareModalSelected: boolean,
  receivedGifts: Array<RewardReceivedDataInterface>,
  tabNumber: number;
  giftObj: ISendGift;
  services: IService[];
  coupons: IDiscountCoupon[];
  rewards: IReward[];
  isRewardsOpen: boolean;
  giftObjErrors: ISendGift;
  giftCards: IGiftCard[];
  isOpenSnackbar: boolean;
  snackbarMessage: string;
  snackbarType: SnackbarColor;
  cardNumber: string;
  cardExpiry: string;
  cardCvc: string;
  cardHolderName: string;
  giftCardPrice : string;
  giftCardDetailFilled: boolean;
  giftCardDesign: string;
  giftCardRecipient:string;
  senderName:string;
  senderEmail:string;
  recipientName: string;
  recipientEmail:string;
  giftCardMessage:string;
  count:string;
  senderNameError:string;
  recipientNameError:string;
  senderEmailError:string;
  recipientEmailError: string;
  giftCardTo :string;
  giftCardFrom: string;
  messageTo : string;
  paymentPopup:boolean;
  img: string;
  heading: string;
  content: string ;
  button: string;
  giftCardList: GiftCardTableData[];
  headCells : string[];
  page: number;
  rowsPerPage: number;
  totalPage: number;
  popup:boolean;
  date: string;
  giftCardNumber: string;
  status: string;
  amount: number|null;
  giftCard_amounts: number|null;
  expiryError: string;
  cardNumberError: string;
  cvcError: string;
  processingPayment: boolean;
  giftCardDetails: GiftCardDetail |null,
  cardLength:number;
  cvcLength:number;
  cardBrand:string | undefined;
}

interface SS {
  navigation: any;
  id: any;
}

export default class InappgiftingController extends BlockComponent<
  Props,
  S,
  SS
> {
  token:null| string = "";
  getRewardsApiCallId: string = "";
  getServicesApiCallId: string = "";
  getDiscountApiCallId: string = "";
  getReceivedGiftCallId: string = "";
  getServicesAPIId: string = "";
  getRewardsAPIId: string = "";
  getDiscountCouponsAPIId: string = "";
  sendGiftCardAPIId: string = "";
  getGiftCardsAPIId: string = "";
  getGiftCardDetailsApiCallId: string="";
  tabList: string[] = ["ALL", "REWARDS", "GIFTING", "SERVICES", "COUPONS"];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      colorId: "cupons",
      activeIndex: 0,
      entries: 0,
      activeSlide: 0,
      index: 0,
      isRewardModal: false,
      token: "",
      selectedItem: 0,
      data: [
        {
          id: 1,
          name: "There are many variations of passages of Lorem Ipsum available, but the",
          url: "https://img.freepik.com/free-vector/gradient-colorful-sale-wallpaper_52683-55788.jpg?w=1060&t=st=1680161421~exp=1680162021~hmac=35ce90a7527752837bfe904236e8fb8d74223872c260f3d864177c3245860353",
        },
        {
          id: 2,
          name: "There are many variations of passages of Lorem Ipsum available, but the",
          url: "https://img.freepik.com/free-vector/gradient-colorful-sale-wallpaper_52683-55788.jpg?w=1060&t=st=1680161421~exp=1680162021~hmac=35ce90a7527752837bfe904236e8fb8d74223872c260f3d864177c3245860353",
        },
        {
          id: 3,
          name: "There are many variations of passages of Lorem Ipsum available, but the",
          url: "https://img.freepik.com/free-vector/gradient-colorful-sale-wallpaper_52683-55788.jpg?w=1060&t=st=1680161421~exp=1680162021~hmac=35ce90a7527752837bfe904236e8fb8d74223872c260f3d864177c3245860353",
        },
      ],
      rewardData: [],
      discountData: [],
      serviceData: [],
      receivedGifts: [],
      shareModalSelected: false,
      tabNumber: 0,
      giftObj: initialGiftObj,
      services: [],
      coupons: [],
      rewards: [],
      isRewardsOpen: false,
      giftObjErrors: initialGiftObj,
      giftCards: [],
      isOpenSnackbar: false,
      snackbarMessage: "",
      snackbarType: "success",
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      cardHolderName:'',
      giftCardPrice:'10',
      giftCardDetailFilled: false,
      giftCardDesign:congratulationGiftCard,
      giftCardRecipient:'Myself',
      senderName:'',
      senderEmail:'',
      recipientName: '',
      recipientEmail:'',
      giftCardMessage:'',
      count:'0',
      senderNameError:'',
      recipientNameError:'',
      senderEmailError:'',
      recipientEmailError:'',
      giftCardTo :'',
      giftCardFrom: '',
      messageTo : '',
      paymentPopup: false,
      img: '',
      heading: '',
      content: '',
      button:'',
      giftCardList: [],
      headCells: ['Gift Card Number','Recipient Name', 'Total amount on Gift Card', 'Redeemed', 'Balance',' '],
      page: 1,
      rowsPerPage: 10,
      totalPage:1,
      popup:false,
      date: '',
      giftCardNumber: '',
      status: '',
      amount: 0,
      giftCard_amounts:null,
      expiryError:"",
      cardNumberError:"",
      cvcError: "",
      processingPayment:false,
      giftCardDetails: null,
      cardLength:19,
      cvcLength:3,
      cardBrand:"",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }
  async componentDidMount() {
    const { navigation } = this.props;
    this.setToken()
    // navigation.addListener ('willFocus', () =>{ this.getDiscountData();})
    // this.getRewardData();
    // this.getServicesData();
    // this.receivedGifts();
    // this.getAllServices();
    // this.getUserRewards();
    // this.getDiscountCoupons();
    // this.getGiftCards();

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getRewardsApiCallId) {
        this.rewardeResponse(responseJson, errorReponse)
      }

      if (apiRequestCallId === this.getServicesApiCallId) {
        this.serviceResponse(responseJson, errorReponse)
      }

      if (apiRequestCallId === this.getDiscountApiCallId) {
        this.discountResponse(responseJson, errorReponse)
      }
      if (apiRequestCallId === this.getReceivedGiftCallId) {
        this.receivedGiftsResponse(responseJson, errorReponse)
      }
      if (apiRequestCallId === this.getServicesAPIId) {
        this.receiveAllServicesAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.getRewardsAPIId) {
        this.receiveRewardsAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.getDiscountCouponsAPIId) {
        this.receiveDiscountCouponsAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.getGiftCardsAPIId) {
        this.receiveGiftCardsAPIResponse(responseJson);
      }

      if (apiRequestCallId === this.sendGiftCardAPIId) {
        this.receiveSendGiftCardAPIResponse(responseJson);
      }
      if(apiRequestCallId === this.getGiftCardDetailsApiCallId){
        this.receiveGiftCardDetails(responseJson)
      }
    }
  }

  receivedGiftsResponse = async (responseJson: RewardReceivedDataArray, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successReceivedGiftsApi(responseJson)
      }
      else {
        this.failureReceivedGiftApiErrorResponse(errorReponse);
      }
    }
  }

  receiveGiftCardDetails=(responseJson:{card:{data:GiftCardDetail}})=>{
    this.setState({giftCardDetails:responseJson.card.data})
  }

  successReceivedGiftsApi = async (responseJson: RewardReceivedDataArray) => {
    this.setState({ receivedGifts: responseJson.data });

  }
  failureReceivedGiftApiErrorResponse(errorReponse: string) {
    Alert.alert('Please try again')
  }

  serviceResponse = async (responseJson: ServiceDataInterfaceArray, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successServiceApi(responseJson)
      }
      else {
        this.failureServiceApiErrorResponse(errorReponse);
      }
    }
  }

  successServiceApi = async (responseJson: ServiceDataInterfaceArray) => {
    responseJson.data.sort((first,second)=>{
      return first.id - second.id;
    })
    this.setState({ serviceData: responseJson.data });

  }
  failureServiceApiErrorResponse(errorReponse: string) {
    Alert.alert('Please try again')
  }

  rewardeResponse = async (responseJson: GetRewardDataArray, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successRewardApi(responseJson)
      }
      else {
        this.failureRewardApiErrorResponse(errorReponse);
      }
    }
  }

  successRewardApi = async (responseJson: GetRewardDataArray) => {
    this.setState({ rewardData: responseJson.data })

  }
  failureRewardApiErrorResponse(errorReponse: string) {
    Alert.alert('Please try again.')
  }


  discountResponse = async (responseJson: GetDiscountDataArray, errorReponse: string) => {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.successDiscountApi(responseJson)
      }
      else {
        this.failureDiscountApiErrorResponse(errorReponse);
      }
    }
  }

  async componentDidUpdate(prevProps:Props, prevState:S) {
    if(this.state.page !== prevState.page){
      this.getGiftCards()
    } if(this.state.giftCardDetails !== prevState.giftCardDetails){
      this.setState({popup: !this.state.popup})
    }
  }

  successDiscountApi = async (responseJson: GetDiscountDataArray) => {
    // responseJson.data.sort((first1,second2)=>{
    //   return first1.id - second2.id;
    // })
    this.setState({ discountData: responseJson.data });
  }
  failureDiscountApiErrorResponse(errorReponse: string) {
    Alert.alert('Please try again')
  }




  getDiscountData = () => {
    const header = {
      "Content-Type": "application/json",
      "token": "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzYsImV4cCI6MTY4OTQwMjYxMiwidG9rZW5fdHlwZSI6ImxvZ2luIn0.oVXJzlwION0Wr-ZM3_Ewd8TaCvCXgVr9_jr9fTlptGrKH-OzvK7XmJaxsg_jgGmWh-zoRvltq0_qvL8EOyt7Nw"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiscountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiscountEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getRewardData = () => {
    const header = {
      "Content-Type": "application/json",
      "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzYsImV4cCI6MTY4OTQwMjYxMiwidG9rZW5fdHlwZSI6ImxvZ2luIn0.oVXJzlwION0Wr-ZM3_Ewd8TaCvCXgVr9_jr9fTlptGrKH-OzvK7XmJaxsg_jgGmWh-zoRvltq0_qvL8EOyt7Nw"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRewardsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRewardEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServicesData = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServicesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServicesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  receivedGifts = () => {
    const header = {
      "Content-Type": "application/json",
      "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NzYsImV4cCI6MTY4OTQwMjYxMiwidG9rZW5fdHlwZSI6ImxvZ2luIn0.oVXJzlwION0Wr-ZM3_Ewd8TaCvCXgVr9_jr9fTlptGrKH-OzvK7XmJaxsg_jgGmWh-zoRvltq0_qvL8EOyt7Nw"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReceivedGiftCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.receivedGifts
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  rewardModalTouch = () => this.setState({ isRewardModal: !this.state.isRewardModal })

  rewardCloseModal = () => this.setState({ isRewardModal: false });

  discountShareNavigate = (item: GetDiscountData) => {
    this.props.navigation.navigate('Gifting', item)
  }




  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const recipientInputString = event.target.value;
    const giftObject = { ...this.state.giftObj };
    this.setState({
      ...this.state,
      giftObj: { ...giftObject, [name]: recipientInputString }
    });
  }

  handleTabChange = (newValue: number) => {
    this.setState({ ...this.state, tabNumber: newValue, isRewardsOpen: false, giftObj: initialGiftObj, giftObjErrors: initialGiftObj });
  }

  handleSelectCoupon = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ ...this.state, giftObj: { ...this.state.giftObj, coupon: event.target.value as string } })
  }

  onSelectCoupon = (couponId: string) => {
    this.setState({ ...this.state, tabNumber: 2, giftObj: { ...this.state.giftObj, coupon: couponId } });
  }

  setTabActiveColor = (tabe: number) => {
    return tabe === this.state.tabNumber ? "secondary" : "default";
  }

  validateGiftObject = () => {
    const giftData = { ...this.state.giftObj };
    const giftErrors = { ...initialGiftObj };
    const regexForEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/);
    const regexForPhoneNumber = new RegExp(/^\d{10}$/);

    if (!giftData.recipientName.trim()) {
      giftErrors.recipientName = "Recipient Name is required";
    } else if (!giftData.recipientEmail.trim()) {
      giftErrors.recipientEmail = "Recipient Email is required";
    } else if (giftData.recipientEmail && !regexForEmail.test(giftData.recipientEmail)) {
      giftErrors.recipientEmail = "Please Enter Valid Email";
    } else if (!giftData.recipientMobileNumber.trim()) {
      giftErrors.recipientMobileNumber = "Recipient Mobile Number is required";
    } else if (giftData.recipientMobileNumber && !regexForPhoneNumber.test(giftData.recipientMobileNumber)) {
      giftErrors.recipientMobileNumber = "Please Enter Valid Mobile Number";
    } else if (!giftData.coupon.trim()) {
      giftErrors.coupon = "Discount Coupon is required";
    }
    if (JSON.stringify(giftErrors) !== JSON.stringify(initialGiftObj)) {
      this.setState({ ...this.state, giftObjErrors: giftErrors });
    } else {
      this.setState({ ...this.state, giftObjErrors: initialGiftObj });
      this.setGiftCardData();
    }
  }

  setDiscountCouponValue = () => {
    const filledGiftCardObj = { ...this.state.giftObj };
    const couponOb = this.state.coupons.find((coupon: IDiscountCoupon) => coupon.id === filledGiftCardObj.coupon);
    if (couponOb) {
      filledGiftCardObj.discounts = couponOb.attributes.discount_value;
    }
    return filledGiftCardObj;
  }

  setGiftCardData = () => {
    const gftCardData = this.setDiscountCouponValue();
    const formData = new FormData();
    formData.append("gift_card[discount_coupon_id]", gftCardData.coupon);
    formData.append("gift_card[receiver_name]", gftCardData.recipientName);
    formData.append("gift_card[receiver_email]", gftCardData.recipientEmail);
    formData.append("gift_card[receiver_phone]", gftCardData.recipientMobileNumber);
    formData.append("gift_card[gift_card_value]", gftCardData.discounts);
  }

  getAllServices = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getServicesAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServicesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserRewards = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRewardsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRewardsAPIEndPoint + "?id=24"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDiscountCoupons = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiscountCouponsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiscountCouponsAPIEndPoint + "?id=24"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  receiveAllServicesAPIResponse = (responseJson: { data: IService[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, services: responseJson.data });
    }
  }

  receiveRewardsAPIResponse = (responseJson: { data: IReward[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, rewards: responseJson.data });
    }
  }

  receiveDiscountCouponsAPIResponse = (responseJson: { data: IDiscountCoupon[] }) => {
    if (responseJson && responseJson.data) {
      this.setState({ ...this.state, coupons: responseJson.data });
    }
  }

  receiveGiftCardsAPIResponse = (responseJson: { data?: GiftCardTableData[], errors?:[{token:string}], total_amount:number|null, total_pages:number|null }) => {
    if (responseJson && responseJson.data && responseJson.total_pages) {
      this.setState({ giftCardList:responseJson.data, giftCard_amounts: responseJson.total_amount, totalPage: responseJson.total_pages });
    }else if(responseJson && responseJson.errors){
      this.showAlert('token', 'expired')
    }
  }

  receiveSendGiftCardAPIResponse = (responseJson: { data?: IGiftCard, errors?:[{token:string}], message?:string }) => {
    let snackbarMsg = "";
    let snackbarType: SnackbarColor = "success";
    this.setState({processingPayment: false})
    if (responseJson && responseJson.data) {
      this.setState({ paymentPopup: true, img: 'success', heading: 'Payment Successful!', content: 'Check your e-mail for the Wingo’s Gift Card code.', button: 'CLOSE' })
    } else if (responseJson && responseJson.errors) {
      if(responseJson.errors[0].token){
        this.showAlert('token', 'expired')
      }else{
      this.setState({ paymentPopup: true, img: 'failure', heading: 'Payment Failed!', content: 'Unfortunately, your payment has failed. Please try again.', button: 'TRY AGAIN' })
      }
    } else if(responseJson && responseJson.message){
      this.checkMessageForPayment(responseJson.message)
    }
    this.setState({ ...this.state, giftObj: initialGiftObj, giftObjErrors: initialGiftObj, tabNumber: 0, isOpenSnackbar: true, snackbarMessage: snackbarMsg, snackbarType: snackbarType });
    setTimeout(() => {
      this.setState({ ...this.state, isOpenSnackbar: false, snackbarMessage: "", snackbarType: "success" })
    }, 3000);
  }

  checkMessageForPayment =(message:string)=>{
      if(message.includes("Your card number is incorrect.")){
        this.setState({cardNumberError:"Your card number is incorrect."})
      } else  if(message.includes("Your card has expired.")){
        this.setState({cardNumberError:"Your card has expired."})
      } else if (message.includes("Your card's security code is incorrect.")){
          this.setState({cvcError:"Your card's security code is incorrect."})
      } else if(message.includes("Your card was declined.") || message.includes("Your card has insufficient funds.") ||  message.includes("exceeding its amount limit.") || message.includes("An error occurred while processing your card. Try again in a little bit.") ){
        this.setState({ paymentPopup: true, img: 'failure', heading: 'Payment Failed!', content: 'Unfortunately, your payment has failed. Please try again.', button: 'TRY AGAIN' })
      }
  }

  rewardOpenCheck = () => {
    this.setState({ ...this.state, isRewardsOpen: true })
  }

  redeemButtom = () => { this.setState({ ...this.state, isRewardsOpen: false }) }

  handleInputChangeCard = (target: TargetType) => {
    if (target.name === 'number') {
      const {cardNumber,length, cardBrand} = formatCreditCardNumber(target.value)
      this.setState({ cardNumber: cardNumber, cardNumberError:"", cardLength:length, cardBrand:cardBrand })
      if(cardBrand!=='american-express'){
        this.setState({cvcLength:3})
      }else{
        this.setState({cvcLength:4})
      }
    } else if (target.name === 'expiry') {
      const {expiry, error}= formatExpirationDate(target.value)
      this.setState({ cardExpiry: expiry, expiryError: error })
    } else if (target.name === 'cvc') {
      this.setState({ cardCvc: formatCVC(target.value,this.state.cvcLength), cvcError:"" })
    } else {
      this.setState({ cardHolderName: target.value })
    }
  }

  cardLengthValidation =()=> {
    return (this.state.cardBrand === 'diners-club'?(this.state.cardNumber.length !==17 && this.state.cardNumber.length !==19 )
    :this.state.cardBrand ==='unionpay'? (this.state.cardNumber.length !==19 && this.state.cardNumber.length !==23 )
    :this.state.cardNumber.length < this.state.cardLength)}

  paymentButtonDisabled() {
    return !!this.state.expiryError || 
     (this.state.cardCvc.length !== this.state.cvcLength) || 
     this.cardLengthValidation() || 
      (this.state.cardExpiry.length<5) || 
      !this.state.cardHolderName || !!this.state.expiryError || !!this.state.cvcError || !!this.state.cardNumberError || !!this.state.processingPayment
  }

  gifCardToggle() {
    this.setState({ giftCardDetailFilled: !this.state.giftCardDetailFilled })
  }

  giftCardDesign(value: string) {
    this.setState({ giftCardDesign: value })
  }
  giftCardPrice(value: string) {
    if (value === '' || value === '£' || !/\d/.test(value)) {
      this.setState({ giftCardPrice: '' })
    } else if(!isNaN(Number( value.replace(/\D/g, '')))) {
      const numericValue = value.replace(/\D/g, '');
      const number = parseInt(numericValue, 10);
      const validNumber = Math.min(Math.max(number, 1), 100);
      const stringValue = validNumber.toString();
      this.setState({ giftCardPrice: stringValue })
    }
  }
  giftCardRecipient(value: string) {
    this.setState({ giftCardRecipient: value })
  }
  handleRecipientChange = (fieldName: keyof S, value: string | boolean, errorName?: keyof S): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    if (errorName !== undefined) {
      const errorState: Partial<S> = {
        [errorName]: '',
      };
      this.setState(errorState as S)
    }
    this.setState(updatedState as S)
  }
  handleWordCountChange = (value: string) => {
    const words = value.trim().split(/\s+/);
    let count
    if(words.length>100){
      count = 100
    }else{
      count = words.length
      this.handleRecipientChange('giftCardMessage',value)
    }
    const stringCount = count.toString();
    this.setState({ count: stringCount })
  }

  handleNameChangeError(fieldName: keyof S, value: string) {
    if (!value.trim()) {
      const updatedState: Partial<S> = {
        [fieldName]: 'This field should not be left empty',
      };
      this.setState(updatedState as S)
    }
  }
  validateEmailField(fieldName: keyof S, value: string) {
    if (!value.trim()) {
      const updatedState: Partial<S> = {
        [fieldName]: 'This field should not be left empty',
      };
      this.setState(updatedState as S)
    }
    else if (!configJSON.validateEmail.test(value)) {
      const updatedState: Partial<S> = {
        [fieldName]: 'The email address you entered is not in a valid format. Please enter a valid email address',
      };
      this.setState(updatedState as S)
    }
  }
  disableSubmit = () => {
    return !(this.state.senderEmail && this.state.senderName && this.state.giftCardMessage) ||
      (this.state.giftCardRecipient === 'Someone else' && !(this.state.recipientEmail && this.state.recipientName)) || this.checkErrors()
  }
  checkErrors = () => {
    return !!(this.state.senderEmailError || this.state.senderNameError || this.state.recipientEmailError || this.state.recipientNameError )
  }
  submitGiftDetails = () => {
    if (this.state.giftCardRecipient === 'Someone else') {
      this.setState({ giftCardTo: this.state.recipientEmail, giftCardFrom: this.state.senderEmail, giftCardDetailFilled: true, messageTo: this.state.giftCardMessage })
    } else {
      this.setState({ giftCardTo: this.state.senderEmail, giftCardFrom: this.state.senderEmail, giftCardDetailFilled: true, messageTo: this.state.giftCardMessage })
    }
  }
  goBack = () => {
    this.setState({ giftCardTo: '', giftCardFrom: '', giftCardDetailFilled: false, messageTo: '' })
  }
  doPayment = () => {
    this.sendGiftCard()
  }
  onclose = () => {
    this.setState({ paymentPopup: false })
    window.location.reload()
  }
  tryAgainClick =()=>{
    this.setState({paymentPopup:false, cardHolderName:"", cardNumber:"",cardCvc:"", cardExpiry:""})
  }
  setToken = () => {
    this.token = localStorage.getItem('authToken')
      this.getGiftCards()
  }
  setSendGiftCardData = () => {
    let data
    if (this.state.giftCardRecipient === 'Someone else') {
      data = {
          "gift_card_value": this.state.giftCardPrice,
          "gift_card_recipient": this.state.giftCardRecipient,
          "your_name": this.state.senderName,
          "email_address": this.state.senderEmail,
          "recipients_name": this.state.recipientName,
          "recipients_email_address": this.state.recipientEmail,
          "message": this.state.giftCardMessage
      }
      this.setState({ giftCardTo: this.state.recipientEmail, giftCardFrom: this.state.senderEmail, giftCardDetailFilled: true, messageTo: this.state.giftCardMessage })
    } else {
      data = {
          "gift_card_value": this.state.giftCardPrice,
          "gift_card_recipient": this.state.giftCardRecipient,
          "your_name": this.state.senderName,
          "email_address": this.state.senderEmail,
          "recipients_email_address": this.state.senderEmail,
          "recipients_name": this.state.senderName,
          "message": this.state.giftCardMessage
        }
    }
    return data
  }

  getGiftCards = () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGiftCardsAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.sendGiftCardAPIEndPoint}?page=${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  properCardNumber = (cardNumber:string)=>{
    return cardNumber.replace(/\s/g, '');
    }

  conversionToMontAndYear=(dateString:string)=>{
      const [month, year] = dateString.split('/').map(Number);
      const fullYear = 2000 + year;
  
    return {
      exp_month: `${month}`,
      exp_year: `${fullYear}`
    };
    }

  sendGiftCard = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendGiftCardAPIId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendGiftCardAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    const {exp_month,exp_year} = this.conversionToMontAndYear(this.state.cardExpiry)

    const httpBody = {
      gift_card: this.setSendGiftCardData(),
      card_number:this.properCardNumber(this.state.cardNumber),
      exp_month: exp_month,
      exp_year:exp_year,
      cvc: this.state.cardCvc
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    this.setState({processingPayment:true})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showGiftCardInformation=(id:string)=>{
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGiftCardDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showGiftCardDetailApiEndPoint}${id}/show_gift_card`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };
  handleBackButtonClick = (event: unknown) => {
    this.handleChangePage(event, this.state.page - 1);
  };
  handleNextButtonClick = (event: unknown) => {
    this.handleChangePage(event, this.state.page + 1);
  };
  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  navigateToGiftCard=()=>{
    this.setState({page:this.state.page +1})
  }
  navigateToFinalGiftCard=()=>{
    this.setState({page:this.state.totalPage})
  }
  onBlurOfGiftCardAmount = ()=>{
    if(10>Number(this.state.giftCardPrice)){
      this.setState({giftCardPrice:"10"})
    }
  }
  navigateToTnC = () => {
    this.props.navigation.navigate("TermsConditions")
  }
  closePopup = () => {
    this.setState({ popup: false })
  }
  openPopup = (id:string) => {
    this.showGiftCardInformation(id)
  }
}





// Customizable Area End
