import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DownwardArrowIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M7 10l5 5 5-5H7z" />
  </SvgIcon>
);

export default DownwardArrowIcon;
