import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import { CardData } from "../../../components/src/ConfirmOrder/ConfirmOrder.web";

// Customizable Area Start
export interface CardDetailsEdit{
  id:string;
  cardHolder:string|null;
  last4:string;
  exp_month:number;
  exp_year:number
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string;
  card_data_list:CardData[];
  removeCardPopup:boolean;
  card_id_to_delete:string;
  deleteSuccess: boolean;
  addCardOpen: boolean
  addedSuccess: boolean;
  card_details_edit:CardDetailsEdit|undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManagePaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCardListApiCall:string=""
  deleteCardDetailApiCall:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token:"",
      card_data_list:[],
      removeCardPopup:false,
      card_id_to_delete:"",
      deleteSuccess:false,
      addCardOpen: false,
      addedSuccess:false,
      card_details_edit:undefined,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
     if(apiRequestCallId===this.getCardListApiCall){
      this.setAllCardDetails(responseJson)
     } else if(apiRequestCallId === this.deleteCardDetailApiCall){
      this.updateCardDataList(responseJson)
     }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getToken =() => {
    getStorageData('authToken',false).then(data => {
       this.setState({token: data,})
        this.getAllCardDetails(data)
       return data
     })
};
  getAllCardDetails=(token:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCardListApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListOfCardApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setAllCardDetails=(responseJson:{data?:CardData[],errors?:[{stripe:string}]})=>{
      if(responseJson.data){
        this.setState({card_data_list:responseJson.data})
      }
  }

  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  getMonthYear=(monthNumber:number, year:number)=> {
    const monthNames = configJSON.monthNames
     const monthName = monthNames[monthNumber - 1];
     return `${monthName},${year}`
  }

  openRemoveCardPopup=(id:string)=>{
    this.setState({removeCardPopup:true,card_id_to_delete:id})
  }
  editCardDetails=(data:CardDetailsEdit)=>{
    this.setState({addCardOpen:true, card_details_edit:data})
  }
  closeRemoveCardPopup=()=>{
    this.setState({removeCardPopup:false})
  }

  removeCardFromList = (id: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCardDetailApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCardApiEndpoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  updateCardDataList = (responseJson: { message?: string }) => {
    if (responseJson.message) {
      this.setState(prevState => ({
        card_data_list: prevState.card_data_list.filter(card => card.id !== this.state.card_id_to_delete)
      }));
      this.setState({ deleteSuccess: true })
      {
        const timer = setTimeout(() => {
          this.setState({ deleteSuccess: false })
        }, 3000);

        return () => clearTimeout(timer);
      }
    }
  }


  getNewlyAddedCardDetails = () => {
    this.setState({ addedSuccess: true })
    this.getAllCardDetails(this.state.token)
    {
      const timer = setTimeout(() => {
        this.setState({ addedSuccess: false })
      }, 3000);

      return () => clearTimeout(timer);
    }
  }

  openAddCard = () => {
    this.setState({ addCardOpen: true })
  }
  closeAddCard = () => {
    this.setState({ addCardOpen: false, card_details_edit: undefined })
  }

  // Customizable Area End
}
