import React from "react";

// Customizable Area Start
import { Container, Box, Typography, InputAdornment, FormLabel, Button, Snackbar } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ImageIcon from '@material-ui/icons/Image';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { FacebookShareButton, WhatsappShareButton, InstapaperShareButton } from "react-share";
import {button_fb, button_wa, button_ig} from "./assets"
import CustomInstagramShareButton from "../../../../packages/components/src/CustomInstagramShareButton.web";
const configurl= require("./../../../framework/src/config");

import "./Share.web.css";
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={shareTheme}>
        <Container maxWidth={"sm"}>
          <Box className="container_share" sx={shareWebStyle.shareMainWrapper}>
            <Typography className="title" variant="h6">{configJSON.labelBodyText}</Typography>
            <div style={{display: "flex", gap: "12px", width: "100%"}}>
            <FormControl fullWidth>
              <FormLabel className="label" htmlFor="copy-link">Copy Link</FormLabel>
              <TextField
                id="copy-link"
                variant="outlined"
                className="textInput"
                InputProps={{
                  className: "textField",
                  startAdornment: (
                    <InputAdornment position="start">
                      <ImageIcon />
                    </InputAdornment>
                  ),
                }}
                value={this.state.text}
                onChange={(e) => {this.changeInput(e.target.value)}}
              />
            </FormControl>
            <Button data-testid="copy-button" onClick={() => this.copyOnClick()} className="btn_copy">Copy</Button>
            </div>
            <Typography className="share_content" variant="h6">{configJSON.labelTitleText}</Typography>
            <div className="social_icons">
            <FacebookShareButton
              url={this.state.text}
              quote={configJSON.quote}
              hashtag={configJSON.hashtag}
              className={undefined}
              data-testid="facebook-share-button"
              onClick={() => this.handleClick()}
            >
              <div className="btn"><img src={button_fb} className="img" alt="btn_fb" /></div>
            </FacebookShareButton>
            <WhatsappShareButton
              url={this.state.text}
              className={undefined}
              data-testid="whatsapp-share-button"
              onClick={() => this.handleClick()}
            >
              <div className="btn"><img src={button_wa} className="img" alt="btn_fb" /></div>
            </WhatsappShareButton>
            <CustomInstagramShareButton url={this.state.text} />
            {/* <InstapaperShareButton
              url={configurl.baseURL}
              className={undefined}
              data-testid="instagram-share-button"
              onClick={() => this.handleClick()}
            >
              <div className="btn"><img src={button_ig} className="img" alt="btn_fb" /></div>
            </InstapaperShareButton> */}
            <Snackbar
              style={{borderRadius:"4px"}}
              open={this.state.copied}
              autoHideDuration={2000}
              message="Link copied to clipboard!"
              ContentProps={{
                style: { 
                  borderRadius:"4px",fontSize:"16px",color:"#313131", backgroundColor:"white", fontFamily:"Barmeno", fontWeight:400, minWidth:"fit-content"
                }
              }}
            />
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const shareTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const shareWebStyle = {
  shareMainWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "24px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
