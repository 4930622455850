import React, { Component } from 'react';
import './UnsubscribeNewsLetter.css';
import { Button, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { unsubscribeNewsLetter, unsubscribeNewsLetterReasons } from '../../../components/src/apiCall';
import { unsubscribeNewsLetterImage } from './assets';

export interface Props {
  close: (open: boolean) => void;
  openSuccessPopup: (open: boolean) => void
}

export interface S {
  reason: string;
  reasonsList: Array<any>;
  note: string;
  open: boolean
}

export default class UnsubscribeNewsLetter extends Component<Props, S> {

  constructor(props: Props) {
    super(props);
    this.state = {
      reason: '',
      reasonsList: [],
      note: '',
      open: false
    }
    this.unsubscriptionReasons = this.unsubscriptionReasons.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  async componentDidMount() {
    this.unsubscriptionReasons();
  }

  unsubscriptionReasons() {
    unsubscribeNewsLetterReasons().then((response: any) => {
      if(response?.status === 200) {
        this.setState({reasonsList: response.data.data, reason: response.data.data[0].id})
      }
    })
  }

  handleChange(event: any) {
    this.setState({reason: event.target.value})
  };

  handleClose() {
    this.setState({open: false});
  };

  handleOpen() {
    this.setState({open: true});
  };

  unsubscribe() {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email") || "";
    unsubscribeNewsLetter({email, note: this.state.note, unsubscription_reason_id: Number(this.state.reason)}).then((response: any) => {
      if(response?.status === 201) {
        this.props.close(false);
        this.props.openSuccessPopup(true);
      }
    })
  }

  render() {
    return (
      <div className='unsubscribe_reason'>
        <img src={unsubscribeNewsLetterImage} style={{width:"250px", height:"250px", alignSelf:"center"}}/>
         <Typography className='question' variant='h5'>{'Are you sure you want to unsubscribe from our newsletter?'}</Typography>
         <div id="reason_drop">
         <InputLabel className='label' shrink htmlFor="reason">
             Select Reason for Change
         </InputLabel>
           <Select
             id="reason"
             open={this.state.open}
             onClose={this.handleClose}
             onOpen={this.handleOpen}
             value={this.state.reason}
             fullWidth
             onChange={this.handleChange}
             className='value_text'
           >
             {this.state.reasonsList.map((reason) => <MenuItem data-value={reason.id} key={reason.id} value={reason.id}>{reason.attributes.reason}</MenuItem>)}
           </Select>
           </div>
         <div>
         <InputLabel className='label' shrink htmlFor="note">
           Note
         </InputLabel>
         <TextField 
           id="note" 
           variant="outlined" 
           onChange={(e) => this.setState({note: e.target.value})}
           value={this.state.note}
           fullWidth
           placeholder={'Write Suggestions here'}></TextField>
         </div>
 
         <div className='btns'>
           <Button className='cancel' onClick={() => this.props.close(false)}>CANCEL</Button>
           <Button className='unsubscribe' onClick={() => {this.unsubscribe()}}>Unsubscribe</Button>
         </div>
      </div>
   )
  }
}
