import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, Typography, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './DialogPopup.css';

interface Props {
    open: boolean;
    onClose: any;
    closeIcon?: boolean
    classes: any;
    dataTestId?:string;
    className?:string;
}

const styles = (theme: any) => ({
  dialogPaper: {
    maxHeight: '800px !important',
    width:"100%"
  },
});

class DialogPopup extends Component<Props> {
    constructor(props: Props){
        super(props);
    }

  render() {
    const {open, onClose, classes, closeIcon = true} = this.props;
    return (
      <Dialog className={this.props.className} open={open} onClose={onClose}
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{ 
        style: { 
          borderRadius: '16px'
        } 
      }} >
        {closeIcon && <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>}
        <DialogContent style={{padding: "0px"}}>
          {this.props.children}
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogPopup);
