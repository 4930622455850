import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Link,
  RadioGroup,
  FormControlLabel,
  TextField,
  FormLabel,
  Typography,
  IconButton,
  OutlinedInput,
  Checkbox,
  Radio
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CustomRadioBox from "../../../components/src/CustomRadioBox.web";
import CustomMobileBox from "../../../components/src/CustomMobileBox.web";

//@ts-ignore
import {banner, mobile_banner} from './assets';
import SocialLogin from "./../../../components/src/SocialLogin/SocialLogin";
// @ts-ignore
import globalTheme from "../../../components/src/Theme/Theme.js";
import './EmailAccountLoginBlock.web.css';
import OtpSentPopup from "./../../forgot-password/src/OtpSentPopup.web";
import EmailSentPopup from "../../forgot-password/src/EmailSentPopup.web";
import PaymentPopup from "../../../components/src/PaymentPopup.web";
import OtpSuccessfulPopup from "../../../components/src/OtpSuccessfulPopup.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start
 // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <ThemeProvider theme={globalTheme}>
      <div className="main-content email-account-login" style={{backgroundColor: 'rgb(223, 223, 223)'}}>
      <Box
        sx={webStyle.mainWrapper}
        className="wrapper"
      >
        <Box sx={webStyle.header}className="sub_wrapper">
          <Typography style={webStyle.headerTextT} variant="h4" component="h2">
            Sign in
          </Typography>
          <Typography style={{...webStyle.headerInfoT, position: "relative"}} variant="subtitle1" component="div">
            Enter your e-mail address or mobile number and password to sign in!
            {(this.state.emailUnregistered) && (<div style={{position: "absolute", fontFamily: "Barmeno", bottom: "-20px", left: 0, color: "#E11B22", fontWeight: "700", fontSize: "14px", lineHeight: "16.8px"  }}>No account registered with these details</div>)}
          </Typography>
          <RadioGroup
            row
            style={webStyle.choiceT}
            defaultValue="email_account"
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e) => {this.onAccountTypeChange(e.target.value)}}
          >
            <FormControlLabel value="email_account" control={<CustomRadioBox />} style={webStyle.emailT} label={<Typography variant="body1" style={webStyle.radioLabel}>Email Address</Typography>} />
            <FormControlLabel value="sms_account" control={<CustomRadioBox />} style={webStyle.mobileT} label={<Typography variant="body1" style={webStyle.radioLabel}>Mobile Number</Typography>} />
          </RadioGroup>

          <div style={{margin: '0 0 24px 0'}}>{this.state.loginSignUpType === "email_account" ?
                        (<><FormLabel style={webStyle.emailInputTextT}  >Email Address</FormLabel>
                        <div style={{position: "relative"}}>
                          <TextField className="inputField" itemID="txtInputEmail" error={this.state.invalidEmail} value={this.state.email} placeholder="Enter email address" onChange={(e) => this.handleInputChange('email', e.target.value)} size="small" style={webStyle.emailInputT} variant="outlined" />
                          {(this.state.invalidEmail) && (<span style={{position: "absolute", fontFamily: "Barmeno", bottom: "-4px", left: 0, color: "#E42328", fontWeight: "500", fontSize: "12px", lineHeight: "12px"  }}>The email address you entered is not in a valid format. Please enter a valid email address</span>)}
                        </div></>) : (<CustomMobileBox onCountryCodeChange={(e) => this.handleInputChange('countryCodeSelected', e)} onMobileNumberChange={(e) => this.handleInputChange('phone', e)}    />)
          }
        </div>

        <div style={{margin: '0 0 36px 0'}}>
          <FormLabel style={webStyle.passwordTextT}  >Password</FormLabel>
          <div style={{position: "relative"}}>
            <OutlinedInput
              className="inputField"
              id="signIn_password_fieild"
              itemID="txtInputPassword"
              value={this.state.password}
              placeholder="Enter password"
              error={this.state.invalidPassword}
              onChange={e => this.handleInputChange('password', e.target.value)}
              style={webStyle.passwordInputT}
              type={!this.state.enablePasswordField ? "text" : "password"}

            />
            <IconButton
              itemID="clkShowPassword"
              className="eyeIcon"
              style={{ margin: "0px 0px 0px -50px" }}
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => this.handleClickShowPassword()}
            >
              {!this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            {(this.state.invalidPassword) && (<span style={{position: "absolute", fontFamily: "Barmeno", bottom: "-12px", left: 0, color: "#E42328", fontWeight: "500", fontSize: "12px", lineHeight: "12px"  }}>Incorrect password. Please enter the correct password!</span>)}
          </div>
        </div>
          <div style={webStyle.subHeader} >
            <Checkbox itemID="checkBox" style={webStyle.checkbox} checked={this.state.checkedRememberMe} onChange={() => this.setState({ checkedRememberMe: !this.state.checkedRememberMe })} name="gilad" />
            <Typography style={webStyle.keepLoginText} >Keep me logged in</Typography>
            <Link id="btnForgotPassword" style={webStyle.forgotText} href="/ForgotPassword"  color="inherit">
              Forgot Password?
            </Link>
          </div>
          <Button className="singInButtonLogin" id="btnEmailLogIn" variant="contained" color="primary" size="small" onClick={() => this.checkAndLogin()} style={{...webStyle.signIn, ...(this.state.isFormValid ? {} : webStyle.disabledLink)}} disabled={!this.state.isFormValid} disableElevation>
            SIGN IN
          </Button>

          <Typography style={webStyle.existAccountText as any}  >
            Don't have an account?
            <Link itemID="goToSignUp" style={{ marginLeft: '0px', fontFamily: "Barmeno",  fontSize: "16px", fontWeight: "700", lineHeight: "19.2px", textTransform: "none", color: "rgba(225, 27, 34, 1)" }} color="error" onClick={() => { }} href="/accountregistration" >
              {" Sign Up"}
            </Link>
          </Typography>

          <Box display="flex" alignItems="center" marginTop={"30px"}>
              <Box borderTop={1} flexGrow={1} borderColor="#909090" />
              <Typography style={{fontFamily: "Barmeno", fontWeight: 700, fontSize: 20, lineHeight: "24px", margin: "0 12px" }} variant="body1">Sign In Using</Typography>
              <Box borderTop={1} flexGrow={1} borderColor="#909090" />
          </Box>

          <SocialLogin loggedIn={this.props.loggedInUser} navigation={this.props.navigation} />

        </Box>
        <Box className="banner_box">
          <img src={banner} className="desktopBanner" style={webStyle.bgImage} alt="banner" />
          <img src={mobile_banner} className="mobileBanner" style={webStyle.bgImage} alt="banner" />
        </Box>
      </Box>
      </div>
      <OtpSentPopup resendClick={this.TwoFAuth} length={4} onChange={() => {}} inValidOtp={this.state.inValidOtp} open={this.state.openOtpPopup} onClose={this.closeOtpPopup} phone={this.state.phone || this.state.email} confirmOtp={this.otpAuthentication} changeInValidOtp={this.changeInValidOtp} />
      <OtpSentPopup resendClick={this.sendActivationLink} length={4} onChange={() => {}} inValidOtp={this.state.inValidOtp} open={this.state.openOtpVerificationPopup} onClose={this.closeOtpVerificationPopup} phone={this.state.phone} confirmOtp={this.otpAuthenticationForVerification} changeInValidOtp={this.changeInValidOtp} />
      <EmailSentPopup resendClick={this.sendActivationLink} open={this.state.openPopup} onClose={this.closeEmailPopup} email={this.state.email} />
      <PaymentPopup open={this.state.accountNotActivatedPopup} onClose={this.sendActivationLink} img={'fail'} heading={'OOPS! Your Account Is Not Activated'} content={this.state.content} button={this.state.button} tryAgain={this.sendActivationLink}/>
      <OtpSuccessfulPopup open={this.state.openSuccessPopup} onClose={this.closeConfirmationPopup} />
    </ThemeProvider>
    </ThemeProvider>
       // Customizable Area End
    );
  }
}
// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: 'Barmeno;',
    margin: '40px 40px 36px 40px',
    backgroundColor: '#FFFFFF',
    borderRadius: '32px',
  },
  header: {
    padding: "50px 45px 100px 45px",
    margin: '0 auto',
  },
  keepLoginText: { fontSize: "16px", fontWeight: "500", lineHeight: "16px", color: "#313131", fontFamily: 'Barmeno',margin:"0 auto 0 8px", alignItems: "end"},
  headerInfoT: { color: "#909090", lineHeight: '21px', fontFamily: "Barmeno", fontWeight: "500", margin: "46px 0px 10px 0", fontSize: '18px', },
  headerTextT: { fontSize: "30px", fontWeight: "700", lineHeight: "30px", color: "#313131", fontFamily: 'Barmeno', marginTop: "2px" },
  choiceT: { margin: '20px 0 20px 0' },
  emailT: { fontFamily: "Barmeno !important", color: "#231f20", fontSize: "16px", fontWeight: "500", lineHeight: "26px" },
  mobileT: { fontFamily: "Barmeno !important", color: "#231f20", fontSize: "16px", fontWeight: "500", lineHeight: "26px" },
  radioLabel: {fontFamily: "Barmeno", fontWeight: "500", fontSize: "16px", lineHeight: "16px"},
  emailInputTextT: { fontFamily: "Barmeno", color: "#909090", fontSize: "16px", fontWeight: "500", lineHeight: "16px" },
  emailInputT: { width: '100%', height: '56px' },
  passwordTextT: { fontFamily: "Barmeno", color: "#909090", fontSize: "16px", fontWeight: "500", lineHeight: "16px" },
  passwordInputT: { width: "100%", height: '45px',fontSize:"14px" },
  subHeader: { width: "100%", alignItems: "center", justifyContent: "space-between", display: "flex" },
  checkbox: { fontFamily: "Barmeno !important", padding:"0px", borderRadius:"6px", color: "#909090", fontSize: "16px", fontWeight: "500", lineHeight: "24px", width: "20px", height: "20px" },
  forgotText: { fontFamily: "Barmeno !important", marginLeft: 'auto', color: "#E11B22", fontWeight: "700", lineHeight: "19.2px", fontSize: '16px' },
  signIn: { backgroundColor: "#E11B23", color: "#ffffff", borderRadius:"8px", padding: "16px 0px", fontSize: "18px", fontWeight: "700", fontFamily: "Barmeno", width: "100%", height: '56px', maxHeight: "56px", marginTop: '34px' },
  disabledLink: { color: "#909090", backgroundColor: "#BFBFBF" },
  existAccountText: { fontFamily: "Barmeno", marginTop: '20px', textAlign: "center", color: "#313131", fontWeight: "400", lineHeight: "16px", fontSize: '16px' },
  bgImage: { width: '100%', height: "100%" },
  infoBox: { height: "100px", width: "300px", margin: "35px 0px" },
  infoTextPoints: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "500",
    fontFamily: "Montserrat",
    color: "#231f20",
    display: "flex"
  },
  terms: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "19px",
    letterSpacing: "0em",
  }
};
// Customizable Area End