import React, { useEffect, useState } from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { convertToDate, generateTimeSlots, londonDateTime } from '../utils';
import "./TimeDropdown.css";
import DownwardArrowIcon from './../DownwardArrowIcon';

interface TimeDropdownProps {
  value: string;
  onChange: (value: string) => void;
  dateSelected: string;
  open_time: string;
  close_time: string;
}

const TimeDropdown: React.FC<TimeDropdownProps> = ({ value, onChange, dateSelected, open_time, close_time }) => {
  const [times, setTimes] = useState(generateTimeSlots(londonDateTime(), open_time, close_time));

  useEffect(() => {
    const parsedDate = convertToDate(dateSelected);
    const slots = generateTimeSlots(parsedDate || londonDateTime(), open_time, close_time);
    setTimes(slots);
  }, [dateSelected])

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value as string);
  };

  return (
      <Select
        labelId="time-select-label"
        id="time-select"
        className='time-select'
        defaultValue={value}
        value={value}
        onChange={handleChange}
        displayEmpty
        disabled={!Boolean(dateSelected)}
        IconComponent={DownwardArrowIcon}
      >
         <MenuItem key="" value="" disabled>
          Select Slot
        </MenuItem>
        {times.map((time, index) => (
          <MenuItem key={index} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
  );
};

export default TimeDropdown;
