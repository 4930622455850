// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "inappgifting";
exports.labelBodyText = "inappgifting Body";
exports.getRewardEndPoint = `bx_block_inappgifting/rewards`;
exports.btnExampleTitle = "CLICK ME";
exports.getServicesEndPoint = "bx_block_inappgifting/services";
exports.getDiscountEndPoint = "bx_block_inappgifting/discount_coupons"
exports.sendCouponEndpoint = "bx_block_inappgifting/gift_cards"
exports.receivedGifts = `bx_block_inappgifting/gift_cards/received_gift_cards`
exports.getServicesAPIEndPoint = "bx_block_admin/services";
exports.getRewardsAPIEndPoint = "bx_block_inappgifting/rewards";
exports.getDiscountCouponsAPIEndPoint = "bx_block_admin/discount_coupons";
exports.sendGiftCardAPIEndPoint = "bx_block_inappgifting/gift_cards";
exports.getGiftCardAPIEndPoint = "bx_block_admin/gift_cards/received_gift_cards";
exports.validateEmail = /^(?:[a-zA-Z0-9_'^&amp;+-])+(?:\.[a-zA-Z0-9_'^&amp;+-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,7}$/;
exports.showGiftCardDetailApiEndPoint = "bx_block_order_management/orders/"
exports.specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]/

// Customizable Area End
