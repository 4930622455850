import React, { Component } from 'react';
import { Box, Dialog, DialogContent, Typography, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { emailVerification }from './assets';

interface EmailSentProps {
    open: boolean;
    onClose: any;
    email: string;
    resendClick: any;
}

export class EmailSentPopup extends Component<EmailSentProps> {
    constructor(props: EmailSentProps){
        super(props);
    }

  render() {
    const {open,onClose,email,resendClick} = this.props;
    return (
      <Dialog open={open} onClose={onClose}
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
          paddingBottom: '50px' 
        } 
      }} >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{padding: "0px"}}>
          <Box textAlign="center" style={{margin: "0px 128px"}}>
            <img style={{height: '250px', width: '250px'}} src={emailVerification} alt="Email Sent" />
            <Typography variant="h6" gutterBottom style={{fontWeight:700, fontFamily:'Barmeno', fontSize:"24px"}}>
              Verification Email Sent!
            </Typography>
            <Typography variant="body1" paragraph style={{fontSize:"16px",fontWeight: 600, color: "#747474", padding: "0% 2%", fontFamily:'Barmeno'}}>
              Please check the verification mail sent to {email}.
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{fontFamily:'Barmeno', fontWeight:600, fontSize:"16px", color:"#313131"}}>
              Still didn’t receive e-mail? <strong id='resend-click' onClick={resendClick} style={{ color: "#E11B22", cursor: "pointer", fontFamily:'Barmeno'}}
              onMouseDown={(e) => (e.target as HTMLElement).style.color = 'rgb(150, 51, 196)'}
              onMouseUp={(e) => (e.target as HTMLElement).style.color = '#E11B22'}>
                Resend</strong>
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default EmailSentPopup;
