import React, { Component } from 'react';
import { Box, Button, Dialog, DialogContent, Link, Typography} from '@material-ui/core';
import { faqPopupImg}from '../../../blocks/interactivefaqs/src/assets';
import '../../../blocks/interactivefaqs/src/Faq.css';

interface PopupProps {
    open: boolean;
    onClose:() => void;
    navigation:any
}

export class Popup extends Component<PopupProps> {
    constructor(props: PopupProps){
        super(props);
    }

  render() {
    const {open,onClose} = this.props;
    return (
      <Dialog open={open} onClose={onClose}  className='popup_customform_faq'
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
          marginTop: '120px',
          maxWidth: '600px',
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          paddingBottom: '10px',        
        } }}
      style={{padding:"6%"}}
      >
        <DialogContent>
          <Box className='popoup-content' textAlign="center" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '2vh', height:'100%'}}>
            <img src={faqPopupImg} style={{width:'250px'}} alt="popupSent" />
            <div>
            <Typography variant="h6" gutterBottom style={{fontFamily:'Barmeno',fontWeight:"700", fontSize:'24px'}}>
            Contact form submitted successfully!
            </Typography>
            <Typography variant="body1" paragraph style={{color: "#747474", fontFamily:'Barmeno', fontSize:'18px'}}>
            Thank you for reaching out! We'll be in touch shortly.
            </Typography>
            </div>
            <div style={{width:' 88%', padding:' 0px 24px'}}>
                <div itemID="goToFaq" onClick={()=>{onClose(),this.props.navigation.navigate('Interactivefaqs')}}  color="inherit" >
                <Button style={{width:'100%', fontFamily:'Barmeno', fontSize:"16px", fontWeight:"700",backgroundColor: '#E11B22', height: '56px',borderRadius: '8px', color: 'white'}}>CLOSE</Button>
            </div>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Popup;
