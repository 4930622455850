Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'
exports.putApiMethod = 'PUT'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.comboDealsApiEndPoint = "bx_block_order_management/orders/combo_deal?"
exports.removeAllItemApiEndPoint = 'bx_block_shopping_cart/remove_cart_items_all'
exports.cartsList = "bx_block_shopping_cart/carts"
exports.createOrderApiEndPoint = "bx_block_order_management/orders/create_order"
exports.updateProfileApiEndPoint = "bx_block_order_management/orders"
exports.restaurantListApi = '/bx_block_storelocator/store_locators'
exports.setRestaurantApiEndPoint = "bx_block_order_management/restaurant_selections"
exports.getRewardPointApiEndPoint = "bx_block_shopping_cart/carts/reward_points?total="
exports.getRestaurantDetailsApiEndPoint = "bx_block_order_management/restaurant_selections"
// Customizable Area End
