import React from "react";

import {
  Button, Link,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ActiveOrdersPresentPopup from "../../../components/src/ActiveOrdersPresentPopup.web"
import "./Settings.css"


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
} from "./Settings2Controller";
import DeleteAccountPopup from "./DeleteAccountPopup.web";
import Profile from "../../../components/src/Profile";

export default class DeleteAccount extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Profile navigateToPages={this.navigateToPage}>
        <div className="main-panel" style={{display:"none"}}> 
            </div>
        </Profile>
          <DeleteAccountPopup deleteSuccess ={this.state.deleteSuccess} setError={this.setErrorFalse} pwdError={this.state.invalidPassword} setDeletePassword={this.setDeletePassword} confirmPassword={this.checkDeleteAccountPassword} navigateToChangePassword ={this.navigateToPages} deleteAccountPopup={this.state.deleteAccountPopup}/>
          <ActiveOrdersPresentPopup deletePassword={this.deleteAccount} activeOrderOpen={this.state.activeOrderPopup} navigateToPages={this.navigateToPages} />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
