import React from 'react';
import { unsubscribe } from "./../SocialLogin/assets";
import './UnSubscribeContent.css';
import { Button, Typography } from '@mui/material';

interface ModalProps {
    close: () => void;
    children?: React.ReactNode;
  }

const UnsubscribeContent: React.FC<ModalProps> = ({close}) => {

  const ok = () => {
    close();
  }

  return (
     <div className='unsubscribe_success'>
        <img src={unsubscribe} alt="unsubscribe" style={{width:"250px", height: "250px"}} />
        <Typography className='sucess_text' variant='h5'>{'You have unsubscribed from our newsletter.'}</Typography>
        <Button className='ok' onClick={() => {ok()}}>Ok</Button>
     </div>
  )
}

export default UnsubscribeContent;
