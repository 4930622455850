import React, {ReactNode } from "react";

import {
  Button,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import "./../../blocks/customisableuserprofiles/src/Profile.css";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PowerSettingsNewRoundedIcon from "@material-ui/icons/PowerSettingsNewRounded"
import LogoutPopup from "./../../blocks/settings2/src/LogoutPopup.web";
import "./../../blocks/settings2/src/Settings.css";
import MessageEnum from "../../framework/src/Messages/MessageEnum";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End
interface Props {
  navigateToPages: (page: MessageEnum) => void, 
  children: ReactNode
}

export default function Profile({navigateToPages, children}: Props) {
  const [logOut, setLogOut] = React.useState(false);
  // Customizable Area Start
  // Customizable Area End
  const path = location.pathname;
  const logOutUser =()=>{
    setLogOut(true);
  }

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <div className={path === "/CartOrderItem" ? "hide-header-sidebar" : "myProfileHeader"}>
          My Profile
          <Button test-id='Log-out' onClick={() => { logOutUser() }} style={{ height: "34px", border: "none", borderRadius: "4px", color: "#fff", padding: "0px 12px !important", textTransform: "none" }}>LOG OUT <PowerSettingsNewRoundedIcon style={{ marginLeft: "6px" }} /></Button>
        </div>
        <div className="my-profile">
          <div className={path === "/CartOrderItem" ? "hide-header-sidebar" : "side-panel"} style={{ maxWidth: "277px" }}>
            {/* Settings */}
            <Button id={path === "/AddressManagement"||path === "/AddAddress"||path === "/EditAddress" || path === "/Profile" || path==="/ChangePassword" || path === "/DeleteAccount" || path === "/PaymentManagement" || path === "/Notificationsettings" ? "settings-button" : "not-clicked"} onClick={() => { navigateToPages(MessageEnum.NavigationToProfile) }} className="settings-button">Settings</Button>
            <div className="settings-options" style={{ display: path === "/AddressManagement"||path === "/AddAddress"||path === "/EditAddress" || path === "/Profile"|| path==="/ChangePassword"|| path === "/DeleteAccount" || path === "/PaymentManagement" || path === "/Notificationsettings" ? 'initial' : 'none' }}>
              
              {/* Profile Details */}
              <div className="settings-options-list" style={{ borderLeft: path === "/Profile" ? "2px solid #E11B22" : "2px solid #BFBFBF" }}>
                <div className="side-navigation" style={{ fontWeight: path === "/Profile" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToProfile) }}>Edit Personal Details</div></div>
              
              {/* Address Management */}
              <div className="settings-options-list" style={{ borderLeft: path === "/AddressManagement" ||path === "/AddAddress"||path === "/EditAddress"  ? "2px solid #E11B22" : "2px solid #BFBFBF" }}>
                <div className="side-navigation" style={{ fontWeight: path === "/AddressManagement"||path === "/AddAddress"||path === "/EditAddress" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToAddressManagement) }}>Manage Addresses</div></div>
              
              {/* Payment Management */}
              <div className="settings-options-list" style={{ borderLeft: path === "/PaymentManagement" ? "2px solid #E11B22" : "2px solid #BFBFBF" }}>
                <div className="side-navigation" style={{ fontWeight: path === "/PaymentManagement" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToPaymentManagement) }}>Manage Payments</div></div>
              
              {/* Password Management */}
              <div className="settings-options-list" style={{ borderLeft: path === "/ChangePassword" ? "2px solid #E11B22" : "2px solid #BFBFBF" }}>
                <div className="side-navigation" style={{ fontWeight: path === "/ChangePassword" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToChangePassword) }}>Change Your Password</div></div>
              
              {/* Notification */}
              <div className="settings-options-list"  style={{ borderLeft: path === "/Notificationsettings" ? "2px solid #E11B22" : "2px solid #BFBFBF" }}>
                <div className="side-navigation" style={{ fontWeight: path === "/Notificationsettings" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToNotificationSettings) }}>Notifications</div></div>
              
              {/* Delete Account */}
              <div className="settings-options-list" style={{ borderLeft: path === "/DeleteAccount" ? "2px solid #E11B22" : "2px solid #BFBFBF" }}>
                <div className="side-navigation" style={{ fontWeight: path === "/DeleteAccount" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToDeleteAccount) }}>Delete My Account</div></div>
            </div>

            {/* All Order History */}
            <Button id={path === "/GiftCardOrderHistory" || path === "/OrderHistory" ? "settings-button" : "not-clicked"} onClick={() => { navigateToPages(MessageEnum.NavigationToOrderHistoryMessage) }} className="settings-button">Order History</Button>
            <div style={{ display: path === "/GiftCardOrderHistory" || path === "/OrderHistory" ? 'flex' : 'none', flexDirection: "column" }}>
              {/* Order History */}
              <div style={{ borderLeft: path === "/OrderHistory" ? "2px solid #E11B22" : "none", fontWeight: 500 }} className="settings-options-list">
                <div onClick={() => { navigateToPages(MessageEnum.NavigationToOrderHistoryMessage) }} style={{ fontWeight: path === "/OrderHistory" ? 500 : 700 }} className="side-navigation"  >Food Order History</div></div>
              {/* GiftCard History */}
              <div style={{ borderLeft: path === "/GiftCardOrderHistory" ? "2px solid #E11B22" : "none", fontWeight: 500 }} className="settings-options-list">
                <div className="side-navigation" style={{ fontWeight: path === "/GiftCardOrderHistory" ? 500 : 700 }} onClick={() => { navigateToPages(MessageEnum.NavigationToGiftCardHistory) }} >Gift Card History</div></div>
            </div>

            {/* Favoutrites */}
            <Button id={path === "/Favourites" ? "settings-button" : "not-clicked"} onClick={() => { navigateToPages(MessageEnum.NavigationToFavourites) }} className="settings-button">Favourites</Button>
          </div>
          {children}
        </div>
        <LogoutPopup open={logOut} />
      </ThemeProvider>
      // Customizable Area End
    );
}