import React, { Component, createRef, RefObject } from 'react';
import { Box, Button, Dialog, IconButton, TextField, Typography} from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import "./OtpSentPopup.css"
import { otpSent }from './assets';

interface OtpInputProps {
    open: boolean;
    onClose: any;
    phone: string;
    length: number;
    onChange: (otp: string) => void;
    inValidOtp: boolean;
    confirmOtp:(value:string) => void;
    resendClick:()=>void;
    changeInValidOtp?:()=>void
}

interface OtpInputState {
    otp: string[];
  }

  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        input: {
          height: '80px',
          width: '80px',
          lineHeight: '28.8px',
          fontWeight: 700,
          fontSize: '24px',
          textAlign: 'center',
          color: '#313131',
          padding: '0 !important',
        },
        root: {
          '&$focused $notchedOutline': {
            borderColor: '#9EBDA3',
          },
        }
      },
      MuiDialog: {
        paper: {
          maxWidth: '720px !important',
          width: '100%',
        },
      },
    }
});
  
export class OtpSentPopup extends Component<OtpInputProps, OtpInputState> {
    otpRefs: RefObject<HTMLInputElement>[] = [];
    constructor(props:OtpInputProps){
        super(props);
        this.state = {
          otp: Array(props.length).fill('')
        };
        this.otpRefs = Array(props.length)
          .fill(0)
          .map(() => createRef());
    }

  handleChange = (index: number, value: string) => {
    // if the value is not a number, do not update the state
    if (isNaN(Number(value))) {
      return;
    }
    
    if (this.props.changeInValidOtp) {
      this.props.changeInValidOtp()
    }
    if (value.length > 1) {
      value = value.slice(-1); // Ensure only single-digit input
    }
    const newOtp = [...this.state.otp];
    newOtp[index] = value;
    this.setState({ otp: newOtp }, () => {
      this.props.onChange(this.state.otp.join(''));
    });
    if (value && index < this.props.length - 1 && this.otpRefs[index + 1].current) {
      // Move focus to next input field
      this.otpRefs[index + 1].current!.focus();
    }
  };

      handleKeyDown = (index: number, event: any) => {
        if (event.key === 'Backspace' && !this.state.otp[index] && index > 0) {
          // Move focus to previous input field on Backspace
          this.otpRefs[index - 1].current?.focus();
        }
      };

      getOtp=()=>{
        const result = this.state.otp.join('')
        return result
      }

  render() {
    const { open,onClose} = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={open} onClose={onClose}
          PaperProps={{
            style: {
              paddingTop: "16px",
              paddingBottom: "32px",
              maxWidth: "720px",
              width: "100%",
              minHeight: "592px",
              height: "fit-content"
            }
          }}>
          <Box textAlign="center" style={{ padding: "0px", margin: "auto", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: "32px" }}>
            <div style={{ top: 16, position: "absolute", right: 16 }}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", padding: '0px 40px', maxWidth: "639", width: "100%" }}>
              <img src={otpSent} alt="otp Sent" style={{ width: "250px", height: "180px" }} />
            </div>
            <div>
              <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
                OTP Verification Code
              </Typography>
              <Typography style={{ color: "#909090", padding: "0% 2%" }}>Please enter the verification code sent to {this.props.phone}</Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
              {this.state.otp.map((digit: string, index: number) => (
                <TextField
                  name='otp-field'
                  key={index}
                  inputRef={this.otpRefs[index]}
                  value={digit}
                  variant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth
                  className={this.props.inValidOtp ? 'otperror' : ''}
                  style={{
                    width: 80,
                    textAlign: 'center'
                  }}
                  onChange={(e) => this.handleChange(index, e.target.value)}
                  onKeyDown={(e) => this.handleKeyDown(index, e)}
                  autoFocus={index === 0} // Autofocus on the first input
                />
              ))}
            </div>
            <div style={{ width: "100%" }}>
              <Button
                onClick={() => this.props.confirmOtp(this.getOtp())}
                data-test-id="confirm-otp-btn"
                id="btnVerify"
                variant="contained"
                color="primary"
                size="small"
                style={{ background: "#E11B22", borderRadius: "8px", padding: "16px 150px", fontSize: "18px", fontWeight: "700", fontFamily: "Monstserrat", height: '56px', maxHeight: "56px", maxWidth: '367px', width: '100%' }}
              >
                Submit
              </Button>
              {this.props.inValidOtp && <div className='otperror' style={{ fontFamily: 'Barmeno', fontWeight: '500', color: '#E11B23', marginTop: '8px' }}>Incorrect verification code. Please enter again</div>}
            </div>
            <Typography variant="body2" color="textSecondary">
              Still didn’t receive OTP? <strong id='resenLink' onClick={() => this.props.resendClick()} style={{ fontSize: '16px', color: "#E11B22", cursor: "pointer", fontFamily: 'Barmeno' }}
                onMouseDown={(e) => (e.target as HTMLElement).style.color = 'rgb(150, 51, 196)'}
                onMouseUp={(e) => (e.target as HTMLElement).style.color = '#E11B22'}>Resend</strong>
            </Typography>
          </Box>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default OtpSentPopup;