import React, { Component } from 'react';
import { Dialog, DialogContent, IconButton, Button} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { restaurant_popup }from './assets';
import { RestaurantList } from './StoreLocatorController';
import { Typography } from '@mui/material';

interface RestaurantProps {
    open: boolean;
    onClose: ()=>void;
    restaurantDetail: RestaurantList;
    menuNavigation:(id:string,order_type:string,title:string)=>void
}

interface PopupType{
  title: string;
      location: string;
      contact: string;
      open_time: string;
      close_time: string;
      postcode: string;
      store_order_type: string[]
}

interface State {
  sameRestaurant: boolean;
}

export default class RestaurantPopup extends Component<RestaurantProps, State> {
    constructor(props: RestaurantProps){
      super(props);
      this.state = {
        sameRestaurant: false,
      }
    }

    getCurrentStore() {
      let details;

      if (typeof window !== 'undefined' && window.localStorage) {
        details = localStorage.getItem("restaurant_details");
      }

      return details ? JSON.parse(details) : {};
    }

    handleClick(orderTypes: string) {
      const {restaurantId, orderType} = this.getCurrentStore()
      if (restaurantId == this.props.restaurantDetail.id && orderType === orderTypes) {
        this.setState({ sameRestaurant: true });
      } else {
        this.props.menuNavigation(this.props.restaurantDetail.id, orderTypes, this.props.restaurantDetail.attributes.title);
      }
    }

    handleClose = (): void => {
      this.setState({ sameRestaurant: false });
      this.props.onClose();
    }

  render() {
    const {open,onClose, restaurantDetail,menuNavigation} = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose}
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
          paddingBottom: '20px',
          marginTop: '120px',
          maxWidth: '500px',  
          height:'fit-content',
          width:"100%"        
        } ,
        className:"popup-main"
      }} >
        <DialogContent style={{paddingTop: "20px", fontFamily:"Barmeno"}}>
          <div style={{margin: "0px", display:"flex", flexDirection:"column", alignItems:"center", gap:"8px"}}>
            <div style={{display:"flex", alignItems:"flex-start", marginLeft:"44px"}}>
            <img style={{height: '200px'}} src={restaurant_popup} alt="restaurant popup" />
            <IconButton className='closeIcon' onClick={this.handleClose}>
              <CloseIcon />
            </IconButton>
            </div>
            <div style={{fontSize:"20px", fontWeight:700}}>Choose your preferred order type</div>
            <div className="restaurant-options">
                        <div className="restaurant-options">
                        <div style={{fontSize:"18px", fontWeight:700}}> {restaurantDetail.attributes.title}</div>
                        <div className="restaurant-contents">
                            <div className="restaurant-detail"> <LocationOnRoundedIcon className="location"/> {restaurantDetail.attributes.location}  </div>
                            <div className="restaurant-detail"> <PhoneInTalkIcon  className="location"/> {restaurantDetail.attributes.contact} </div>
                            <div className="restaurant-detail"> <ScheduleIcon  className="location"/> Open {restaurantDetail.attributes.open_time} to {restaurantDetail.attributes.close_time} </div>
                        </div>
                        {restaurantDetail.attributes.order_type.includes('collection') && <Button data-testid="collection-button" onClick={() => this.handleClick("collection")} style={{fontFamily:"Barmeno", fontWeight:700}} className="collection-button">COLLECTION</Button> }
                        
                        {restaurantDetail.attributes.order_type.includes('delivery') && <Button onClick={()=> this.handleClick("delivery")} style={{fontFamily:"Barmeno", fontWeight:700}} className="collection-button">DELIVERY</Button>}
                        </div>                        
                    </div>
          </div>
          {this.state.sameRestaurant && <Typography mt={2} fontFamily="Barmeno" color="red" textAlign="center" data-testid="restaurant-selection-message">You have already selected {restaurantDetail.attributes.title} for {this.getCurrentStore().orderType}</Typography>}
        </DialogContent>
      </Dialog>
    );
  }
}
