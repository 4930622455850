import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import LogoutPopup from "../../settings2/src/LogoutPopup.web";
import "./Address.css"
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Profile from "../../../components/src/Profile";
const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff"
      }
    }
  });

// Customizable Area End

import AddressManagementController, {
  Props,
  AddressDataTypeNew
} from "./AddressManagementController";
import { noAddress } from "./assets";
import Alert from "@material-ui/lab/Alert";
import DeleteAddressPopup from "./DeleteAddressPopup.web";

export default class AddressManagement extends AddressManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  AddressComponent = (data:{id:string,name:string,address:string,phoneNumber:string, address_type:string, city:string, post_code:string,default_address:boolean}) => {
    return(
    <div className="address_start">
      <div className="address_type">{data.address_type} 
        <div style={{display: "flex",flexDirection: "row",gap: "16px"}}> <IconButton onClick={()=>{this.editAddress({id:data.id,name:data.name,phoneNumber: data.phoneNumber,address: data.address,address_type: data.address_type,default_address: data.default_address, post_code: data.post_code, city: data.city})}} style={{backgroundColor:"#FAF4F4", borderRadius:"8px", width:"32px", height:"32px"}}><EditRoundedIcon style={{color:"#2C6F37", fontSize:"18px"}}/></IconButton> 
        <IconButton style={{backgroundColor:"#FAF4F4", borderRadius:"8px", width:"32px", height:"32px"}}><DeleteOutlineRoundedIcon style={{color:"#E11B22", fontSize:"18px"}} onClick={()=>this.openDeleteAddressPopup(data.id)}/></IconButton></div></div>
                    
      <div className="address_info">
        <div className="address_field">{data.name}</div>
        <div className="address_field">{data.address}</div>
        <div className="address_field">{data.city}</div>
        <div className="address_field">{data.post_code}</div>
        <div className="address_field">Phone: <span style={{fontWeight:700}}>{data.phoneNumber}</span></div>
      </div>
    </div>
    )
  }

  AddressList = (addressList:AddressDataTypeNew[]) => {
    return(
    <div style={{display: "flex",flexDirection: "column",gap: "16px", fontFamily: "Barmeno"}}>
      {addressList.map(addresses => (
        this.AddressComponent({id :addresses.id,name : addresses.attributes.name, address: addresses.attributes.address, phoneNumber: addresses.attributes.full_phone_number,
          address_type: addresses.attributes.address_type, city: addresses.attributes.city,post_code:  addresses.attributes.post_code,default_address: addresses.attributes.default_address})
      ))}
    </div>
  )}

  render() {
    // Customizable Area Start
    const { addressesNewList } = this.state;
  const defaultAddresses = addressesNewList.filter(address => address.attributes.default_address);
  const additionalAddresses = addressesNewList.filter(address => !address.attributes.default_address);

    return (
        <ThemeProvider theme={theme}>
        <Profile navigateToPages={this.navigateToPage}>
        {this.state.deletedSuccess && <Alert iconMapping={{
          success: <CheckCircleRoundedIcon fontSize="inherit" />,
        }} style={{top:"200px",color: "#313131",backgroundColor:"white", width:"fit-content", boxShadow:"4px 4px 12px #bbb", position:"absolute", right:"56px", zIndex:99 }} >
          Address deleted succesfully!
        </Alert>}
          {addressesNewList.length !==0?
          <div className="main-panel" style={{minHeight:"530px", display:"flex", flexDirection:"column", gap:"32px"}}> 
              <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
                <div style={{fontFamily: 'Barmeno',fontSize: '20px', fontWeight: 700, lineHeight: '24px', textAlign: 'left'}}>Manage Addresses</div>
                  <div className="additional_address_head">Default Address</div>
                  {this.AddressList(defaultAddresses)}
              </div>
                <div style={{display: "flex",flexDirection: "column",gap: "16px", fontFamily: "Barmeno",}}>
                  <div className="additional_address_head">Additional Address</div>
                  {this.AddressList(additionalAddresses)}
                </div>
            <div>
            <Button onClick={()=>{this.navigateToPages("AddAddress")}}
      style={{ color:"white", backgroundColor:"#E11B22", width: '250px', height: '56px', padding: '16px 0px 0px 0px', borderRadius: '8px', fontWeight:500, fontSize:"16px"}} >
      Add new address
    </Button>
            </div>
          </div>:
          <div className="main-panel" style={{minHeight:"530px"}}> 
          <div style={{display:"flex", flexDirection:"column", gap:"8px"}}>
              <div style={{fontFamily: 'Barmeno',fontSize: '20px', fontWeight: 700, lineHeight: '24px', textAlign: 'left'}}>Manage Addresses</div>
              <div style={{fontFamily: 'Barmeno',fontSize: '16px', fontWeight: 500, lineHeight: '16px', textAlign: 'left'}}>You have no address entries on Wingo’s Account.</div>
          </div>
          <div style={{display:"flex", justifyContent:"center"}}>
              <img src={noAddress} alt="no-address"/>
          </div>
          <div>
          <Button onClick={()=>{this.navigateToPages("AddAddress")}}
    style={{ color:"white", backgroundColor:"#E11B22", width: '250px', height: '56px', padding: '16px 0px 0px 0px', borderRadius: '8px', fontWeight:500, fontSize:"16px"}} >
    Add new address
  </Button>
          </div>
        </div>}
        </Profile>
          <LogoutPopup open={this.state.logoutPopup}/>
          <DeleteAddressPopup open={this.state.deleteAddressPopup} closePopup={this.closeDeleteAddressPopup} deleteAddress={this.deleteAddress} id={this.state.addressTodelete}/>
        </ThemeProvider>
      );
    // Customizable Area End
  }
}