import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { collectionImage, collectionImageDone, cookingImage, cookingImageDone, deliveredImage, orderAcceptedImage, orderAcceptedImageDone, orderPickedImage, orderPickedImageDone, orderPlacedImage, orderPlacedImageDone, orderReadyImage, orderReadyImageDone } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON1 = require("../../../framework/src/config")
import {actionCableConfig, actionCableConfigCloseConnection, webSocketFunction} from "../../../components/src/websocketConfig"
  // Customizable Area Start

export interface ImageArray{
  status: string;
  active: string;
  inactive: string;
}
export interface OrderHistoryAttributes{
  id: number;
  order_number: string;
  placed_at: string;
  order_type: string|null;
  status: string;
  items_count: number;
  restaurant: string;
  total: string;
  estimated_time: string
}
export interface OrderHistoryOrders {
  id: string;
  type: string;
  attributes: OrderHistoryAttributes
}

export interface OrderHistoryDetailRestaurant{
  id: number,
  title: string;
  location: string;
  contact: string;
  open_time: string;
  close_time: string;
  postcode: string;
  created_at: string;
  updated_at: string;
  order_type: string[];
}

export interface  OrderHistoryDetailPayment{
  id: number,
  account_id: number,
  charge_id: string;
  amount: number,
  currency: string;
  charge_status: string;
  created_at: string;
  updated_at: string;
  transactionable_type: string;
  transactionable_id: number,
  payment_method_type: string;
  transaction_number: string;
}

export interface OrderHistoryDetailItems{
  id: string;
  type: string;
  attributes: {
      id: number,
      cart_id: null,
      catalogue_id: number,
      spice_level: string;
      choose_your_type: string;
      quantity: number,
      price: number,
      product_name: string;
      images: {
          url: string;
          content_type: string;
          file_name: string;
      },
      sides: ProductSideDetais[],
      drinks: ProductSideDetais[],
      nibbles: ProductSideDetais[],
      toppings: ProductSideDetais[],
      wraps_product: ProductSideDetais[]
  }
}

export interface ProductSideDetais{
  catalogue_id: number;
  name: string;
}

export interface DeliveryAddresses{
  "id": number, 
  "account_id": number, 
  "address": string, 
  "name": string, 
  "flat_no": null|string, 
  "zip_code": string, 
  "phone_number": string, 
  "deleted_at": null|string, 
  "latitude": null|string, 
  "longitude": null|string, 
  "residential": boolean, 
  "city": string, 
  "state_code": null|string, 
  "country_code": null|string, 
  "state": null|string, 
  "country": string, 
  "address_line_2": null|string, 
  "address_type": string, 
  "address_for": string, 
  "is_default": boolean, 
  "landmark": null|string, 
  "created_at": string, 
  "updated_at": string
}

export interface OrderHistoryDetailsAttributes{
  id: number,
  order_number: string;
  placed_at: string;
  delivered_at: null,
  collected_at: null,
  order_type: null,
  estimated_time: string;
  status: string;
  items_count: number,
  redeem_point: string;
  points_worth: string;
  gift_card_amount: string;
  applied_discount: string;
  sub_total: string;
  total: string;
  restaurant: OrderHistoryDetailRestaurant;
  delivery_addresses: DeliveryAddresses[],
  payment_transaction:OrderHistoryDetailPayment | null;
  items_detail: {
      data: OrderHistoryDetailItems[]
  }
}
export interface OrderHistoryDetails{
  id: string;
            type: string;
            attributes: OrderHistoryDetailsAttributes;
}
  // Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  expandAccordian: string |boolean;
  orderHistoryDataPresent: boolean;
  orderStatus: string;
  orderType: "delivery"|"collection";
  imageGroupCollection: ImageArray[];
  imageGroupDelivery: ImageArray[];
  orderStatusesDelivery: string[];
  orderStatusesCollection:string[];
  token:string
  active_orders: OrderHistoryOrders[];
  past_orders: OrderHistoryOrders[];
  viewOrderDetailsOpen: boolean;
  OrderDetails: OrderHistoryDetails | null;
  message:string;
  trackOrder: boolean;
  id: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];
    this.state = {
      expandAccordian:"Active",
      orderHistoryDataPresent: true,
      orderStatus: "ready",
      orderType: "delivery",
      imageGroupCollection:[{status: 'placed',active:orderPlacedImageDone, inactive:orderPlacedImage},
      {status: 'confirmed',active:orderAcceptedImageDone, inactive:orderAcceptedImage},
      {status: 'cooking',active:cookingImageDone, inactive:cookingImage},
      {status: 'ready',active:orderReadyImageDone, inactive:orderReadyImage},
      {status: 'collected',active:collectionImageDone, inactive:collectionImage}],
      imageGroupDelivery:[{status: 'placed',active:orderPlacedImageDone, inactive:orderPlacedImage},
      {status: 'confirmed',active:orderAcceptedImageDone, inactive:orderAcceptedImage},
      {status: 'cooking',active:cookingImageDone, inactive:cookingImage},
      {status: 'ready',active:orderReadyImageDone, inactive:orderReadyImage},
      {status: 'in_transit',active:orderPickedImageDone, inactive:orderPickedImage},
      {status: 'delivered',active:deliveredImage, inactive:deliveredImage}],
      orderStatusesDelivery: ['placed','confirmed','cooking','ready','in_transit','delivered'],
      orderStatusesCollection: ['placed','confirmed','cooking','ready','collected'],
      token: "",
      active_orders: [],
      past_orders: [],
      viewOrderDetailsOpen: false,
      OrderDetails: null,
      message:"",
      trackOrder: false,
      id: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  setOrderHistoryApiCallId:string="";
  setOrderDetailsApiCallId:string="";
  setInvoiceDownloadCallId: string ="";
  setReorderItemsCallId: string = ""
  private cableSubscription: any | null = null;

  async componentDidMount(){
    this.getToken()
  }
  async componentWillUnmount() {
    this.unsubscribeFromStatusUpdates();
  }

  async receive(from: string, message: Message) {
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.setOrderHistoryApiCallId){
        this.setOrderHistoryData(responseJson)
      } else if(apiRequestCallId === this.setOrderDetailsApiCallId){
        this.setOrderDetails(responseJson)
      } else if(apiRequestCallId === this.setReorderItemsCallId){
        this.reorderItemsResponse(responseJson)
      }
    }
    }

  // Customizable Area Start

  setActiveOrderState = (orderHistory: OrderHistoryOrders[], oldOrderHistory:OrderHistoryOrders[]) => {
    this.setState({active_orders: orderHistory, past_orders:oldOrderHistory})
  }

  getToken = () => {
    getStorageData('authToken',false).then(data => {
       this.setState({token: data})
       this.getOrderHistoryData(data)
       this.subscribeToStatusUpdates(data)
       return data
     })
};
reorderItemsResponse=(responseJson:{meta?:{message:string}})=>{
  if(responseJson.meta && responseJson.meta.message === "Order has been reordered successfully"){
    this.props.navigation.navigate("MenuPage")
  }
  else{
    this.showAlert("token","token")
  }
}

downloadInvoice=(id:string)=> {
  const url = `${configJSON1.baseURL}/${configJSON.getOrderDetailsApiEndPoint}/${id}/download_invoice?token=${this.state.token}`
  location.href= url
}

  navigateToMenuPage = ()=>{
    this.props.navigation.navigate('MenuPage')
  }

  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  handleExpand =
    (expand: string) => (event: React.ChangeEvent<{}>, expandAccordian: boolean) => {
      this.setState({ expandAccordian: expandAccordian? expand : false });
    };

    getOrderHistoryData=(token:string)=>{
      const headers = {
        "Content-Type": configJSON.apiContentType,
        "token": token
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setOrderHistoryApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOrderHistoryApiEndPoint
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    setOrderHistoryData=(responseJson:{errors?:[{"token":string}], data?:{active_orders:OrderHistoryOrders[],past_orders:OrderHistoryOrders[]}})=>{
      if(responseJson.errors){
        this.showAlert("token","token")
      } else if(responseJson.data){
        const {active_orders, past_orders} = responseJson.data
        this.setState({active_orders,past_orders,message:""})
      } 
    }
    setSearchOrderHistoryData=(responseJson:{errors?:[{"token":string}], data?:{active_orders:OrderHistoryOrders[],past_orders:OrderHistoryOrders[]},message?:string})=>{
      if(responseJson && !responseJson.message){
        this.setOrderHistoryData(responseJson)
      } else if(responseJson.message){
        this.setState({active_orders:[],past_orders:[],message:responseJson.message})
       }
    }

    closeViewOrderDetails=()=>{
      this.setState({viewOrderDetailsOpen:false})
    }
    openViewOrderDetails=(id:string)=>{
      this.getOrderDetails(id)
    }

    getOrderDetails = (id:string)=>{
      const headers = {
        "Content-Type": configJSON.apiContentType,
        "token": this.state.token
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setOrderDetailsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetailsApiEndPoint}${id}/order_detail`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.listOfOrdersMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    setOrderDetails=(responseJson:{data?:{data:OrderHistoryDetails},errors?:[{"token":string}]})=>{
     if(responseJson.errors){
      this.showAlert("token","token")
     } else if(responseJson.data){
      this.setState({OrderDetails:responseJson.data.data,viewOrderDetailsOpen:true,orderStatus:responseJson.data.data.attributes.status})
     }
    }

    openTask = (id: string) => {
      this.setState({trackOrder: true, id});
    }

    closeTask = () => {
      this.setState({trackOrder: false});
    }
    reorderItems=(id:string)=>{
      const headers = {
        "Content-Type": configJSON.apiContentType,
        "token": this.state.token
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setReorderItemsCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetailsApiEndPoint}${id}/reorder`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.createOrderMethod
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    subscribeToStatusUpdates(token:string) {
      this.cableSubscription = webSocketFunction(token)
      actionCableConfig(this.cableSubscription, this.setActiveOrderState)
    }

    unsubscribeFromStatusUpdates() {
      if(this.cableSubscription ){
        actionCableConfigCloseConnection(this.cableSubscription)
      }
    }
// Customizable Area End
}