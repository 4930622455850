import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
import ForgotPasswordController from "./ForgotPasswordController";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  emailSchema: any;
  phoneSchema: any;
  otpSchema: any;
  passwordSchema: any;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;
  token: any;
  enablePasswordField: boolean;
  btnConfirmPasswordShowHide: boolean;
  password: any;
  reTypePassword: any;
  enableReTypePasswordField: boolean;
  selectedOption: any;
  emailValidation: string;
  passwordNotMatched: boolean;
  isMenuOpen: boolean;
  openPopup: boolean;
  otp:any;
  openOtpPopup:boolean;
  countryCode: string[];
  goToSignIn:boolean;
  inValidOtp:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class NewPasswordController extends ForgotPasswordController {
  // Customizable Area Start
  requestMobileOtpCallId:string = "";
  otpConfirmationCallId:string ="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    //phone schema
    let phoneSchema = {
      // countryCode: Yup.number()
      // .required("Country code is required"),

      phone: Yup.string()
        .matches(configJSON.phoneRegExp, configJSON.phoneNumberIsNotValid)
        .required(configJSON.phoneNumberIsRequired),
    };

    //otpSchema
    let otpSchema = {
      otpCode: Yup.number().min(4).required(configJSON.otpCodeIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .required(configJSON.pleaseEnterAPassword)
        .min(2, configJSON.passwordMustBeAtLeast2Characters),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.emailReg = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "+1",
      passwordRules: "",
      emailSchema: emailSchema,
      phoneSchema: phoneSchema,
      otpSchema: otpSchema,
      passwordSchema: passwordSchema,
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      password: "",
      reTypePassword:"",
      enableReTypePasswordField:true,
      selectedOption:'email',
      emailValidation: 'Valid',
      passwordNotMatched: false,
      isMenuOpen: false,
      openPopup: false,
      otp: ['','','',''],
      openOtpPopup: false,
      countryCode:['+1','+91'],
      goToSignIn: false,
      inValidOtp:false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

    async receive(from: string, message: Message) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const tokenDetails = message.getData(getName(MessageEnum.NavigationPayLoadMessage))
            if (tokenDetails) {
                this.setState({ token: tokenDetails.token })
            }

        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.requestMobileOtpCallId !== null &&
            this.requestMobileOtpCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            this.mobileOtpReceived(responseJson)
        }
        else if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.otpConfirmationCallId !== null &&
            this.otpConfirmationCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            this.otpIsConfirmed(responseJson)
        }
    }
    goToSignInMethod=()=>{
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    mobileOtpReceived=(responseJson:{data:{id:string,type:string,attributes:{full_phone_number: string,pin:string}},meta:{token:string;}})=>{
      if (
        responseJson !== undefined &&
        responseJson.meta &&
        responseJson.meta.token
    ) {
        this.otpToken = responseJson.meta.token;
        this.setState({ token: this.otpToken, openOtpPopup: true })
    }
    }

    otpIsConfirmed = (responseJson:{messages?:[{token:string}],errors?:[{error:string}]})=>{
      if (
        responseJson !== undefined &&
        responseJson.messages
    ) {
        this.setState({ token: responseJson.messages[0].token });
        const message: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordSetMessage)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationPayLoadMessage), { token: responseJson.messages[0].token })
        this.send(message);
    }
    //error handling
    else if (responseJson && responseJson.errors) {
      this.setState({ inValidOtp: true })
        return
    } else {
        return
    }
    }

  sendMobileOtpApi=()=> {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.requestMobileOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEmailEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const data = {
        "attributes":{
            "full_phone_number": `+${this.state.phoneValue}`
    }
    };

    const httpBody = {
      data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  confirmOtpCall = (otp:string)=>{
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.otpConfirmationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmOtpEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const data = {
      token: this.state.token,
      otp_code: Number(otp)
    };
    const httpBody = {
      data: data,
    };
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
