import React from "react";
import { Button, IconButton, Popover } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import NotificationsController, { Props } from "./NotificationsController";
import NotificationItem from "./NotificationItem.web";
import { emptyNotifications } from './assets';
import "./NotificationDialog.web.css";

export default class NotificationDialog extends NotificationsController {
    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (this.props.anchorEl && !prevProps.anchorEl) {
            this.getNotifications();
            this.setState({ latestNotifications: this.getLatestNotifications()})
        }
    }

    render() {
        const { anchorEl, handleClose } = this.props;
        const open = Boolean(anchorEl);

        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className="notificationDialog"
                classes={{ paper: "notificationDialog__paper" }}
            >
                <div className="notificationDialog__mainWrapper">
                    <div className="notificationDialog__headerWrapper">
                        <span className="notificationDialog__headerWrapper__title">
                            Latest Notifications
                        </span>

                        <IconButton onClick={handleClose} className="notificationDialog__headerWrapper__close_button">
                            <Close />
                        </IconButton>
                    </div>

                    <div className="notificationDialog__notificationWrapper">
                        {this.state.latestNotifications.map((notification) => {
                            const markAsRead = () => this.markAsRead(notification.id);
                            const deleteNotifications = () => this.deleteNotifications(notification.id);

                            return (
                                <NotificationItem 
                                    dialog
                                    key={notification.id} 
                                    id={this.props.id}
                                    navigation={this.props.navigation}
                                    notification={notification} 
                                    markAsRead={markAsRead} 
                                    deleteNotification={deleteNotifications} 
                                />
                            );
                        })}
                        {this.state.latestNotifications.length === 0 && (
                            <div data-testId="empty-notification" className="emptyNotification_wrapper">
                                <img src={emptyNotifications} alt="No unread notification" className="emptyNotification_wrapper__image" />
                                <p className="emptyNotification_wrapper__text">No unread notifications</p>
                            </div>
                        )}
                    </div>

                    <Button
                        className="notificationDialog__cta"
                        size="small"
                        onClick={() => {
                            this.props.navigate && this.props.navigate("/notifications");
                            handleClose && handleClose();
                        }}
                    >
                        View All
                    </Button>
                </div>
            </Popover>
        )
    }
}