Object.defineProperty(exports, "__esModule", {
    value: true,
  });

exports.getAddressApiContentType = "application/json";
exports.getAddressAPiEndPoint = "bx_block_address/addresses";
exports.getDeliveryAdressApiEndPoint = "bx_block_order_management/orders";
exports.postAdressApiEndPoint = "bx_block_order_management/orders";
exports.deleteAddressAPiEndPoint = "bx_block_address/addresses" 
exports.putCollectionApiEndPoint = "bx_block_order_management/orders";
exports.deleteAddressesApiMethod = "DELETE";
exports.getAddressesApiMethod = "GET";
exports.putCollectionApiMethod = "PUT";
exports.postAdressApiEndPoint = "POST";
exports.getCouponCodeApiEndpoint = 'bx_block_promo_codes/promo_codes'
exports.applyCouponCodeEndPoint = "order_management/orders"
exports.getWingoRewardsPointsEndPoint = "bx_block_loyaltysystem/rewards"
exports.getWingoRewardsPointWorthEndPoint = "bx_block_loyaltysystem/rewards/points_conversion"
exports.putWingoRewardsApiEndPoint= "bx_block_order_management/orders/redeem_reward_points"
exports.putGiftCardApiEndPoint = "bx_block_order_management/orders"
exports.specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]/
exports.getListOfCard = "bx_block_stripe_integration/payment_methods"
exports.getRewardPointApiEndPoint = "bx_block_shopping_cart/carts/reward_points?total="
exports.storeInformationApiEndPoint = "bx_block_storelocator/store_locators/"