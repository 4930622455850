import React from 'react';
import { subscribed_img } from "./../SocialLogin/assets";
import './AlreadySubscribed.css';
import { Button, Typography } from '@mui/material';

interface ModalProps {
  navigate: () => void;
  close: () => void;
    children?: React.ReactNode;
  }

const AlreadySubscribed: React.FC<ModalProps> = ({navigate, close}) => {

  return (
     <div className='already_subscribed'>
        <img src={subscribed_img} alt="subscribed_img" style={{width:"328px", height: "328px"}} />
        <div>
            <Typography className='sucess_text' variant='h5'>{"You're Already Subscribed!"}</Typography>
            <Typography className='description_text' variant='h5'>{"You're already subscribed to Wingo’s Newsletter! Thank you for being a part of our Wingo’s community."}</Typography>
        </div>
        <Button className='navigate' onClick={() => {close()}}>CLOSE</Button>
     </div>
  )
}

export default AlreadySubscribed;
