import { BlockComponent } from "../../framework/src/BlockComponent";
import React, { Suspense, lazy } from "react";
import CustomLoader from "./CustomLoader.web";

const LazyLoadFranchise = lazy(()=>import('../../blocks/customform/src/CustomForm.web'))

interface Props {
    id: any;
    navigation:any;
    location?:any;
  }

interface S {
  }
interface SS {
  }

export default class LazyLoadedFranchise extends BlockComponent<
Props,
S,
SS
>{
    render(){
    return(
        <Suspense fallback={<CustomLoader loading={true}/>}>
            <LazyLoadFranchise navigation={this.props.navigation} id="LazyLoadId"/>
        </Suspense>
    )
}
}