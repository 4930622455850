import React, { ChangeEvent, Component } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import "./Menu.css"
import {SpiceLevelType } from './MenuPageController';

interface PopupProps {
    open: boolean;
    onClose:() => void
    allergen: string[]
    spiceLevel: SpiceLevelType[]
    dietType: SpiceLevelType[]
    spiceChange:(value:ChangeEvent<HTMLInputElement>) => void
    dietChange:(value:ChangeEvent<HTMLInputElement>) => void
    allergenChange:(value:string) => void,
    selectedAllergen: string[],
    selectedSpice:string|null
    selectedDiet: string|null
    applyFilter:() => void
    clearFilter:() => void
}

export default class FilterPopup extends Component<PopupProps> {
    constructor(props: PopupProps){
        super(props);
    }

  render() {
    const {open,onClose, allergen, spiceLevel, dietType, spiceChange, dietChange, allergenChange, selectedAllergen, selectedSpice, selectedDiet, clearFilter, applyFilter} = this.props;
    return (
        <Dialog open={open} onClose={onClose}
        PaperProps={{ 
          style: { 
            borderRadius: '16px',
          } 
        }} >
          <DialogTitle style={{borderBottom:"1px solid #D4D4D4"}}>
            <Box display="flex" alignItems="center" justifyContent="space-between" padding="0px 16px 0px 32px">
                <div style={{fontFamily:"Barmeno", fontSize:"16px", fontWeight:"bolder"}}>Filters</div>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent style={{padding: "0px"}}>
            <Box display="flex" flexDirection="column" padding="24px 40px" style={{gap:"24px", fontFamily:"Barmeno",fontWeight:"bolder"}}>

                <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
                Veg / Plant-based
                  <div style={{display:"flex", flexDirection:"row", gap:"16px", flexWrap:'wrap'}}>
                      {dietType.map((item)=>(
                          <label style={{cursor: 'pointer', display: 'inline-flex', alignItems: 'center', padding:"6px 12px 6px 8px", borderRadius:"26px",
                                color: selectedDiet === item.value ? '#fff' : '#525252',backgroundColor: selectedDiet === item.value ? '#2C6F37' : '#F1F1F1'}} key={item.id}>
                              <input onChange={dietChange} checked={selectedDiet===item.value} type='radio' name={item.title} value={item.value} style={{display: 'none'}} />
                              <img style={{marginRight:'4px'}} src={item.img}/>
                              {item.title}
                          </label>
                      ))}
                  </div>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
                Allergens
                  <div className='filter'>
                      {allergen.map((item,key)=>(
                          <label key={key} style={{display:"flex", flexDirection:'row', alignItems:"center", gap:"8px", color:'#747474'}}>
                              <input onChange={(event)=>allergenChange(item)} className='allergenCheckbox' type="checkbox" name="allergen" checked={selectedAllergen.includes(item)} value={item}/>
                              {item}
                          </label>
                      ))}
                  </div>
                </div>

                <div style={{display:"flex", flexDirection:"column", gap:"16px", fontWeight:'bold'}}>
                Spice Level
                  <div style={{display:"flex", flexDirection:"row", gap:"16px", flexWrap:'wrap'}}>
                      {spiceLevel.map((item)=>(
                          <label style={{cursor: 'pointer', display: 'inline-flex', alignItems: 'center', padding:"6px 12px 6px 8px", borderRadius:"26px",
                          color: selectedSpice === item.value ? '#fff' : '#525252',backgroundColor: selectedSpice === item.value ? '#2C6F37' : '#F1F1F1'}} key={item.id} >
                              <input onChange={spiceChange} checked={selectedSpice===item.value} type='radio' name={item.title} value={item.value} style={{display: 'none'}} />
                              <img style={{marginRight:'4px'}} src={item.img}/>
                              {item.title}
                          </label>
                      ))}
                  </div>
                </div>

            </Box>
          </DialogContent>
          <DialogActions style={{boxShadow: '0px -4px 15px 0px #4848481F', justifyContent:"space-evenly", padding:'16px'}}>
          <Button id='clear' onClick={clearFilter} style={{width: '220px',height: '56px',border: '1px solid #E11B22',color: '#E11B22'}} >
          CLEAR FILTERS
          </Button>
          <Button id='apply' onClick={applyFilter}  style={{width: '220px',height: '56px',backgroundColor:'#E11B22',color: '#fff'}}>APPLY FILTERS</Button>
        </DialogActions>
        </Dialog>
      );
  }
}
