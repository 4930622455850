import React from "react";
// Customizable Area Start
import './ShoppingCartOrders.web.css'
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Button } from '@material-ui/core';
import { IconButton, Typography } from "@mui/material";
import DialogPopupWeb from "../../../components/src/DialogPopup.web";
import DealFoundPopup from "../../../components/src/DealFoundPopup.web";
import { SignInToContinuePopup } from "../../../components/src/SignInToContinuePopup.web";
// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  totalPrice = () => {
   const total_price = this.props.cartdetails.data.reduce((acc, item) => {
    return acc + item.attributes.quantity * item.attributes.price 
    }, 0)
    return total_price.toFixed(2);
  }

  getOrderType = () => {
    const restaurant_details = localStorage.getItem('restaurant_details');
    const { orderType } = JSON.parse(restaurant_details || '{}');
    
    if (orderType) {
      return orderType === 'delivery' ? 'Delivery' : 'Collection';
    };

    return 'Delivery';
  }

  showSpiceLevel(item: any): boolean {
    if (item.attributes.spice_level && item.attributes.spice_level !== 'none') return true;
    return false;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <div className="shopping-cart-order-list" style={{position:"sticky", top:"260px"}}>
          <div className="container_to_item_summary">
          <Typography className="title">
            Your Order
          </Typography>
          <div className="order_from">
            <span className="order_from__title">{this.getOrderType()} from</span>
            <span className="order_from__restaurant_name">{this.props.restaurant_name}</span> 
          </div>
          {this.props.cartdetails.data?.map((item: any, index: number) => (
            <div key={item.id} className="item_details">
              <div key={index}>
                  <div style={{fontFamily:"Barmeno",fontWeight:700,fontSize:"18px"}} className="item_name">{item.attributes.product_name}</div>
                  {this.showSpiceLevel(item) && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Typography className="spice">Spice Level:{' '}
                        <Typography component="span" className="capitalize-text" >
                          {`${item.attributes.spice_level.replace('_', ' ')} |`}
                        </Typography>{' '}
                        <Typography component="span" className="capitalize-text">
                          {item.attributes.choose_your_type}
                        </Typography>{' '}
                      </Typography>
                      <IconButton className='edit_button' onClick={() => this.props.editCartItem(item)}><EditRoundedIcon style={{color:"#2C6F37"}}/></IconButton>
                    </div>
                  )}
              </div>
            <div style={{display:"flex",gap:"12px",alignItems:"center"}}>
              <IconButton data-test-id={`deleteOrderItem_${index}`} disabled={item.attributes.quantity<1} onClick={() => this.props.addRemoveItems(item, 'removeItem')} className='product_minus_add_button'><RemoveRoundedIcon style={{color:"#286532"}}/></IconButton>
              <div style={{fontWeight:700}}>{String(item.attributes.quantity).padStart(2, '0')}</div>
              <IconButton data-test-id={`addOrderItem_${index}`} onClick={() => this.props.addRemoveItems(item, 'addItem')} className='product_minus_add_button'><AddRoundedIcon style={{color:"#286532"}}/></IconButton>
              <Typography className="amount mr-lf-auto">{`£${(item.attributes.quantity * item.attributes.price).toFixed(2)}`}</Typography>
            </div>
            </div>
          ))}
          <div style={{display: 'flex', padding: '16px 0'}}>
          <Typography className="amount">Sub Total</Typography>
          <Typography className="amount mr-lf-auto">{`£${this.totalPrice()}`}</Typography>
          </div>
          </div>
          <div className="container_to_item_number_price" style={{fontFamily:"Barmeno", fontWeight:700, display:"flex" ,alignItems:"center"}}>
          {this.props.cartdetails.data.length} {this.props.cartdetails.data.length<=1? "item":"items"} added | {`£${this.totalPrice()}`}
          </div>
          <Button style={{ height: '56px', borderRadius: '8px', fontWeight: 'bold', fontSize: '16px', lineHeight: '16px', textTransform: 'uppercase', color: "#fff", backgroundColor: "#E11B22", width:"100%", padding:"16px", boxSizing:"border-box"}} onClick={this.checkout}>Go to Checkout</Button>
          <DialogPopupWeb open={this.state.deal_conversion_popup_open} onClose={this.navigateToAddShoppingCartOrderItem} > <DealFoundPopup dealData={this.state.deal_found_data} onClose={this.navigateToAddShoppingCartOrderItem} onDeal={this.props.navigateToComboDeal} closePopup={this.closeDealConversionPopup} deleteDeal={this.removeAllItemsFromCart} cartsList={this.props.cartsList} token={this.state.token} /> </DialogPopupWeb>
          <SignInToContinuePopup open={this.state.signInToContinue} onClose={this.closeSignInToContinue} heading={'Sign In to Continue'} content={'Almost there! To complete your order, please sign in or create an account.'} goToSignIn={this.navigationToSignIn}/>
        </div>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
