import React from "react";

// Customizable Area Start
import {
  Container,
  Button,
} from "@material-ui/core";
import { CheckRounded } from "@material-ui/icons";
import { headerBackground, headerView } from "./assets";
import NotificationItem from "./NotificationItem.web";
import EmptyNotifications from "./EmptyNotifications.web";
import "./Notifications.web.css";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <div data-testId="notifications" className="mainWrapper">
        <section className="mainWrapper__headerWrapper" style={{ backgroundImage: `url(${headerBackground})` }}>
          <Container maxWidth="md" className="mainWrapper__headerWrapper__header" style={{ backgroundImage: `url(${headerView})` }}>
            <h1 className="mainWrapper__headerWrapper__header__title">
              {configJSON.notifications}
            </h1>
          </Container>
        </section>

        {this.state.data ? (
          <>
            <section className="mainWrapper__actionsWrapper">
              <Container maxWidth="md" className="mainWrapper__actionsWrapper__group">
                <Button startIcon={<CheckRounded />} onClick={this.markAllAsRead}>{configJSON.markAllAsReadText}</Button>
              </Container>
            </section>

            <section className="mainWrapper__notificationWrapper">
              <Container maxWidth="md" className="mainWrapper__notificationWrapper__itemWrapper">
                {Object.keys(this.state.notifications).map((key, idx) => (
                  <div key={idx} className="mainWrapper__notificationWrapper__notificationContainer">
                    <div className="mainWrapper__notificationWrapper__notificationContainer__titleWrapper">
                      <h2 className="mainWrapper__notificationWrapper__notificationContainer__titleWrapper__notificationContainer__title">{key}</h2>
                      <hr className="mainWrapper__notificationWrapper__notificationContainer__titleWrapper__notificationContainer__separator" />
                    </div>

                    <div className="mainWrapper__notificationWrapper__notificationsGroup">
                      {this.state.notifications[key]?.map((notification) => {
                        const markAsRead = () => this.markAsRead(notification.id);
                        const deleteNotifications = () => this.deleteNotifications(notification.id);

                        return (
                          <NotificationItem data-testId='notification-item' key={notification.id} id={this.props.id} navigation={this.props.navigation} notification={notification} markAsRead={markAsRead} deleteNotification={deleteNotifications} />
                        );
                      })}
                    </div>
                  </div>
                ))}

              </Container>
            </section>
          </>
        ) : (
          <Container data-testId='notifications-empty' maxWidth="md">
            <EmptyNotifications />
          </Container>
        )}
      </div>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
