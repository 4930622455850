import React, { Component } from 'react'
import { orderPlacedImage } from '../assets'
import { IconButton } from '@material-ui/core'

interface Props{
  close:()=>void
}

export default class OrderConfirmedPopup extends Component<Props> {
  render() {
    return (
      <div style={{display:"flex", flexDirection:"column", gap:"40px",padding:"0px 40px 40px 40px", alignItems:"center"}}>
        <img src={orderPlacedImage} alt="orderPlacedImage" style={{width:"250px",height:"250px"}} />
        <div style={{display:"flex", flexDirection:"column", gap:"16px"}}>
            <div style={{fontFamily:"Barmeno",fontSize:"24px",fontWeight:700,display:"flex",justifyContent:"center"}}>Order Placed!</div>
            <div style={{fontFamily:"Barmeno",fontSize:"18px",fontWeight:500,display:"flex",justifyContent:"center",color:"#747474",textAlign:"center"}}>Thank you for placing your order! Please wait until your order gets accepted by our restaurant.</div>
            <div style={{display:"flex", flexDirection:"column", gap:"4px"}}>

            </div>
            <div onClick={this.props.close} style={{width:"fit-content",height:"fit-content",alignSelf:"center"}}><IconButton style={{color:"#E11B22",textTransform:"none"}} > Track Order </IconButton> </div>
        </div>
      </div>
    )
  }
}
