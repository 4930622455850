import { OrderHistoryOrders } from "../../blocks/ordermanagement/src/OrderHistoryController";

export const configJSON = require("../../framework/src/config")

export const webSocketFunction = (token:string) =>{
    const baseURL =  configJSON.baseURL.replace('https://', '');
    const socket = new WebSocket(`wss://${baseURL}/cable?token=${token}`);
    return socket
}


export const actionCableConfig = (cableSubscription:any | null, setActiveOrderState: (orderHistory: OrderHistoryOrders[],oldOrderHistory: OrderHistoryOrders[]) => void)=>{
    if(cableSubscription){
    const socket = cableSubscription
    socket.onopen = () => {
        console.log('WebSocket connection established');
        const message = {
          command: "subscribe",
          identifier: JSON.stringify({
            channel: "BxBlockOrderManagement::OrderTrackingChannel",
          })
        };
      
        socket.send(JSON.stringify(message));
      };
      socket.onmessage = (event:any) => {
        const response = event.data;
        const message = JSON.parse(response);
      
        if (message.type === 'ping') {
          return;
        }
        
        console.log('WebSocket message received:', message);
        // Check if the message contains order status updates
        if (message.message && message.message.data && message.message.data.active_orders) {
          setActiveOrderState(message.message.data.active_orders, message.message.data.past_orders)
        }
      };
      
      socket.onerror = (error:any) => {
        console.error('WebSocket error:', error);
        setActiveOrderState([],[])
      };
    }
}

interface HeaderCountConfigProps {
  cableSubscription: any | null;
  setCartCount: (cartCount: number) => void;
  setNotificationCount: (notificationCount: number) => void;
}

export const headerCountConfig = ({ cableSubscription, setCartCount, setNotificationCount }: HeaderCountConfigProps) => {
  if (cableSubscription) {
    const socket = cableSubscription

    socket.onopen = () => {
      console.log('WebSocket connection established');
      const cartCountMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "BxBlockShoppingCart::CartItemCountChannel",
        })
      };

      const notificationCountMessage = {
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "BxBlockNotifications::NotificationCountChannel",
        })
      };

      socket.send(JSON.stringify(cartCountMessage));
      socket.send(JSON.stringify(notificationCountMessage));
    };

    socket.onmessage = (event: any) => {
      const response = event.data;
      const message = JSON.parse(response);

      if (message.type === 'ping') {
        return;
      }

      console.log('WebSocket message received:', message);
      // Check if the message contains order status updates
      if (message.message && message.message.data && message.message.data.cart_item_count) {
        setCartCount(message.message.data.cart_item_count)
      }

      if (message.message && message.message.data && message.message.data.notification_count) {
        setNotificationCount(message.message.data.notification_count)
      }
    };

    socket.onerror = (error: any) => {
      console.error('WebSocket error:', error);
      setCartCount(0)
      setNotificationCount(0)
    };
  }
}

export const actionCableConfigCloseConnection = (cableSubscription:any | null)=>{
    cableSubscription.onclose = () => {
        console.log('WebSocket connection closed');
      };
}