import { Button, Dialog } from "@material-ui/core";
import React, { Component } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { SignInToContinue } from "./assets";
import "./SnackBar.css"

interface SigninProps {
    open: boolean;
    onClose: ()=>void;
    heading: string;
    content:string;
    goToSignIn?: ()=>void
}

export class SignInToContinuePopup extends Component<SigninProps>{

    render(){
        return(
            <Dialog PaperProps={{ style: {borderRadius: '16px'}}}  
        open={this.props.open} onClose={this.props.onClose} >
            <div className="closeIconSubmittedFeedback"><CloseIcon onClick={this.props.onClose} style={{height: '24px', width: '24px', color: '#525252', cursor:"pointer"}}/></div>
            <div className="feedBackPopupBox" style={{padding:"0px 40px 40px 40px"}}>
              <img style={{height:"200px" , width:"200px"}} src={SignInToContinue}></img>  
              <div style={{ display: 'flex', flexDirection: "column", gap: '16px' }}>
                <div className='feedBackHeading'>
               {this.props.heading}
              </div>
                <div style={{ fontFamily: 'Barmeno', fontSize: '18px', fontWeight: 500, textAlign: "center", color:"#747474" }}>
                {this.props.content}
                </div></div>
                <div style={{display:"flex", flexDirection:"row", gap:"40px", width:"100%", justifyContent:"center"}}>
                <Button onClick={this.props.onClose} style={{ width:"100%", maxWidth: '200px', height: '56px', color: '#E11B22', backgroundColor: '#fff', borderRadius: '8px', border:"1px solid #E11B22" }}>Cancel</Button>
                {this.props.goToSignIn ?
                <Button onClick={this.props.goToSignIn}  style={{ width:"100%", maxWidth: '200px', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>Sign In</Button> :
                <Button href="/accountlogin"  style={{ width:"100%", maxWidth: '200px', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>Sign In</Button>}
                </div>
              
            </div>
          </Dialog>
        )
    }
}