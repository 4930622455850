import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  orderId?: string;
  onClose?: () => void;
  addressToedit?: null | EditAddress
  // Customizable Area End
}

// Customizable Area Start
export interface AddressDataType {
  latitude: number;
  longitude: number;
  address: string;
  address_type: string;
}
export interface EditAddress{
  id: string;
  name: string,
  full_phone_number: string,
  post_code: string,
  address: string,
  address_type: string, 
  default_address: boolean,
  city:string,
}
export interface AddressType {
  id: string;
  type: string;
  attributes: AddressDataType;
}

export interface AddressValueType {
  value: string;
}

export const AdressTypeData = [
  {
    value: "Home", label: 'Home'
  },
  {
    value: "Work", label: 'Work'
  },
  {
    value: "Other", label: 'Other'
  },
];
// Customizable Area End

interface S {
  // Customizable Area Start
  txtInputAddressValue: string;
  txtInputLatValue: string;
  txtInputLngValue: string;
  token: string;
  addressTypeValue: string;
  isOpen: boolean;
  logoutPopup: boolean;
  name: string;
  full_phone_number: string;
  post_code: string;
  country: string;
  address: string;
   address_type: string; 
   default_address: boolean;
   city:string;
   editAddress:EditAddress | null;
   editAddressMode: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiAddAddressCallId: string = "";
  token:null| string = ""
  editAddress: null|EditAddress = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
       // Customizable Area Start
      txtInputAddressValue: "",
      txtInputLatValue: "",
      txtInputLngValue: "",
      addressTypeValue: AdressTypeData[0].value,
      token: "",
      isOpen: false,
      logoutPopup: false,
      name: '',
      full_phone_number: '',
      post_code: "",
      country: 'UK',
      address: '',
      address_type: 'Home', 
      default_address: false,
      city:'',
      editAddress: null,
      editAddressMode: false,
       // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.apiAddAddressCallId) {
         this.handleaddEditAddress(responseJson, apiRequestCallId)
    } else if (responseJson && responseJson.errors && responseJson.errors[0].token) {
        if (apiRequestCallId === this.apiAddAddressCallId) {
          this.showAlert('token','expired')
        }
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleaddEditAddress = (responseJson: any, apiRequestCallId: string) => {
    if (responseJson && responseJson.data) {
      this.closePopup(apiRequestCallId);
      this.showAlert(configJSON.successMsgTitle, configJSON.successMsg);
      this.props.navigation.navigate && this.props.navigation.navigate("AddressManagement");
  } else if(responseJson && responseJson.delivery_address) {
    this.closePopup(apiRequestCallId);
  }
  }

  closePopup = (apiRequestCallId: string) => {
    if (apiRequestCallId === this.apiAddAddressCallId) {
      if(this.props.onClose) {
        this.props.onClose();
      }
    }
  }
  manageInitialValues = (token: string | null, editAddressJson: any) => {
    if(token){
      if(this.props.addressToedit?.id) {
        this.editAddress = this.props.addressToedit; 
        this.handlInitialValuesChange();
      } else if(editAddressJson){
      this.editAddress = JSON.parse(editAddressJson);
      this.handlInitialValuesChange()
      } 
    } else{
      this.navigateToPages("EmailAccountLoginBlock")
    }
  }
  getToken = () => {
    this.token = localStorage.getItem("authToken");
    const editAddressJson = localStorage.getItem('editAddress')
    this.manageInitialValues(this.token, editAddressJson);
  };

  txtInputLatWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLatValue: text });
    },
  };

  txtInputLatMobileProps = {
    ...this.txtInputLatWebProps,
  };

  txtInputLatProps = this.isPlatformWeb()
    ? this.txtInputLatWebProps
    : this.txtInputLatMobileProps;

  txtInputLngWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputLngValue: text });
    },
  };

  txtInputLngMobileProps = {
    ...this.txtInputLngWebProps,
  };

  txtInputLngProps = this.isPlatformWeb()
    ? this.txtInputLngWebProps
    : this.txtInputLngMobileProps;

  txtInputAddressWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputAddressValue: text });
    },
  };

  txtInputAddressMobileProps = {
    ...this.txtInputAddressWebProps,
  };

  txtInputAddressProps = this.isPlatformWeb()
    ? this.txtInputAddressWebProps
    : this.txtInputAddressMobileProps;


  handleOnchnageAddressType = (item: string)=> this.setState({addressTypeValue: item})

  handleSavePressed() {
    const header = {
      "Content-Type": configJSON.addAddressApiContentType,
      token: this.token,
    };

    const attrs = {
      "name": this.state.name,
      "full_phone_number": this.state.full_phone_number,
      "post_code": this.state.post_code,
      "country": "UK",
      "address": this.state.address,
      "address_type": this.state.address_type, 
      "default_address": this.state.default_address,
      "city": this.state.city
    };

    const httpBody: any = {
      address: attrs,
    };

    if(this.props.orderId) {
      httpBody["order_type"] = "delivery"
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.props.orderId ? configJSON.addDeliveryAddressAPiEndPoint + `${this.props.orderId}` + `/add_address_to_order_delivery` : configJSON.addAddressAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      this.props.orderId ? configJSON.putAddressApiMethod : configJSON.addAddressApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleUpdatePressed() {
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddAddressCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addAddressAPiEndPoint}/${this.editAddress?.id}`
    );
    const header = {
      "Content-Type": configJSON.addAddressApiContentType,
      token: this.token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    const attrs = {
      "name": this.state.name,
      "full_phone_number": this.state.full_phone_number,
      "post_code": this.state.post_code,
      "country": "UK",
      "address": this.state.address,
      "address_type": this.state.address_type, 
      "default_address": this.state.default_address,
      "city": this.state.city
    };

    const httpBody: any = {
      address: attrs,
    };

    if(this.props.orderId) {
      httpBody["order_type"] = "delivery"
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  logOut =()=>{
    this.setState({logoutPopup: true})
  }
  handleSubmit = () => {
    const url = window.location.pathname
    if((url ==="/EditAddress"  || this.props.addressToedit?.id) && this.editAddress){
      this.handleUpdatePressed()
    }else{
    this.handleSavePressed()
    }
    
  };
  navigateToPages=(navigator:string)=>{
    this.props.navigation.navigate && this.props.navigation.navigate(navigator);
    if(this.props.addressToedit?.id && this.props.onClose) {
        this.props.onClose();
    }

  }
  handlInitialValuesChange=()=>{
    const url = window.location.pathname
    if((url ==="/EditAddress" || this.props.addressToedit?.id) && this.editAddress){
      this.setState({
        name: this.editAddress.name,
        full_phone_number: this.editAddress.full_phone_number,
        post_code: this.editAddress.post_code,
        address: this.editAddress.address,
        address_type: this.editAddress.address_type, 
        default_address: this.editAddress.default_address,
        city:this.editAddress.city,
        editAddressMode: true
        })
    }
  }
  disableSubmitButton = (): boolean => {
    const { name, full_phone_number, address, address_type, city,post_code } =
      this.state;
    return !name || !full_phone_number || !address || !address_type || !city ||!post_code;
  };
  handleInputChange = ( fieldName: keyof S,value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.setState(updatedState as S)
  }
  
  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
