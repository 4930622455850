import React from "react";
import { FormControlLabel, Switch, SwitchProps, styled } from "@mui/material";
import NotificationsettingsController from "./NotificationsettingsController";
import Profile from "../../../components/src/Profile";
import "./Notificationsettings.web.css";

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#2C6F37",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

export default class Notificationsettings extends NotificationsettingsController {
  componentDidMount(): Promise<void> {
    const token = localStorage.getItem("authToken") || "";
    this.getNotificationDataRequest(token);

    return super.componentDidMount();
  }

  render() {
    const { notificationSetting } = this.state;
    const { email_notification, in_app_notification, push_notification } =
      notificationSetting;

    return (
      <Profile navigateToPages={this.navigateToPage}>
        <div className="main-panel">
          <div className="notification_settings_wrapper">
            <h2 className="notification_settings_wrapper__title">
              Notification Settings
            </h2>

            <div className="notification_settings_wrapper__form">
              <FormControlLabel
                data-testid="push_notification"
                name="push_notification"
                checked={push_notification}
                onChange={async (_event, checked) => {
                  this.setState(
                    {
                      notificationSetting: {
                        ...notificationSetting,
                        push_notification: checked,
                      },
                    },
                    () => this.updateNotificationSettings()
                  );
                }}
                control={<CustomSwitch />}
                label="Push Notifications"
                labelPlacement="start"
                classes={{
                  root: "notification_settings_wrapper__form__label",
                  label: "notification_settings_wrapper__form__label_text",
                }}
              />

              <FormControlLabel
                data-testid="in_app_notification"
                name="in_app_notification"
                checked={in_app_notification}
                onChange={async (_event, checked) => {
                  this.setState(
                    {
                      notificationSetting: {
                        ...notificationSetting,
                        in_app_notification: checked,
                      },
                    },
                    () => this.updateNotificationSettings()
                  );
                }}
                control={<CustomSwitch />}
                label="In App Notifications"
                labelPlacement="start"
                classes={{
                  root: "notification_settings_wrapper__form__label",
                  label: "notification_settings_wrapper__form__label_text",
                }}
              />

              <FormControlLabel
                data-testid="email_notification"
                name="email_notification"
                checked={email_notification}
                onChange={async (_event, checked) => {
                  this.setState(
                    {
                      notificationSetting: {
                        ...notificationSetting,
                        email_notification: checked,
                      },
                    },
                    () => this.updateNotificationSettings()
                  );
                }}
                control={<CustomSwitch />}
                label="Email Notifications"
                labelPlacement="start"
                classes={{
                  root: "notification_settings_wrapper__form__label",
                  label: "notification_settings_wrapper__form__label_text",
                }}
              />
            </div>
          </div>
        </div>
      </Profile>
    );
  }
}
