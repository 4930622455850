import React from 'react';
import { success } from "./../SocialLogin/assets";
import './SubscriptionSuccess.css';
import { Button, Typography } from '@mui/material';

interface ModalProps {
  navigate: () => void;
  children?: React.ReactNode;
}

const SubscriptionSuccess: React.FC<ModalProps> = ({ navigate }) => {

  const navigateText = () => {
    const token = localStorage.getItem('authToken') || "";
    if (token) {
      return 'BACK TO HOME';
    } else {
      return 'BACK TO SIGN UP';
    }
  }

  return (
    <div className='unsubscribe_success'>
      <img src={success} alt="success" style={{ width: "250px", height: "250px" }} />
      <Typography className='sucess_text' variant='h5'>{'Thanks for Subscribing to the Newsletter'}</Typography>
      <Button className='navigate' onClick={() => { navigate() }}>{navigateText()}</Button>
    </div>
  )
}

export default SubscriptionSuccess;
