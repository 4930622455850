import React from "react";
import CustomFormController, { Props } from "./CustomFormController.web";
import { Feedback } from "../../landingpage/src/assets";
import "./CustomForm.css"
import { Button, Dialog } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {bad ,happy , moderate , good , happyActive , goodActive , badActive ,moderateActive, thankyou} from "./assets"
import { SignInToContinuePopup } from "../../../components/src/SignInToContinuePopup.web";

export default class FeedbackFormButton extends CustomFormController{
    constructor(props: Props) {
        super(props);
      }

      render(){
        return(
            <div>
            <button className="feedback-btn" onClick={()=>this.openFeedBackPopup()}> 
             <div className="feedback-btn-content" >Feedback <img className="feedback-icon" style={{width:"24px", aspectRatio:"1"}} src={Feedback}></img> </div>
             </button>

             <Dialog PaperProps={{ style: {borderRadius: '16px'}}} 
             open={this.state.isFeedBackFormOpen} onClose={this.closeFeedBackPopup} style={{borderRadius:"16px"}} maxWidth="md" >
          <div className="feedBackPopupBox">
            <div className="feedBackHeading">
              Feedback
            </div>
            <div className="feedback_form_container" >
            <div className="feedBackPopupEmojiContainer">
             <div className="sad-icon" 
               style={this.state.feedbackEmoji === 'bad' ?{backgroundImage:`url(${badActive})`}:{backgroundImage:`url(${bad})`}}
             onClick={()=>this.handleSelectEmoji('bad')}> 
             </div>
            <div className="moderate-icon" 
              style={this.state.feedbackEmoji === 'moderate' ?{backgroundImage:`url(${moderateActive})`}:{backgroundImage:`url(${moderate})`}}
            onClick={()=>this.handleSelectEmoji('moderate')}> 
            </div>
             <div className="good-icon" 
             style={this.state.feedbackEmoji === 'good' ?{backgroundImage:`url(${goodActive})`}:{backgroundImage:`url(${good})`}} 
             onClick={()=>this.handleSelectEmoji('good')}>
                 </div> 
              <div className="happy-icon" 
               style={this.state.feedbackEmoji === 'happy' ?{backgroundImage:`url(${happyActive})`}:{backgroundImage:`url(${happy})`}} 
               onClick={()=>this.handleSelectEmoji('happy')}>
                 </div>
                </div>
              <div className="form_feedback_field" >
                <div style={{fontSize: '16px', fontFamily: 'Barmeno',fontWeight: 500, color: '#747474'}}> Note </div>
                <textarea onChange={this.handleFeedbackChange} placeholder="Write Suggestions here" style={{fontFamily:'Barmeno', height:"65px", fontWeight:500, resize:"none", padding:"10px"}} className="input_form_feedback_field"/>
                <div id="error_to_show" style={{fontWeight:700,fontFamily:"Barmeno", fontSize:"14px", color:"#E42328"}}>{this.state.errorToshow}</div>
                { this.state.charCount>100 && <div id="error_will_come" style={{fontWeight:700,fontFamily:"Barmeno", fontSize:"14px", color:"#E42328"}}>The feedback can’t be more than 100 characters</div>}
            </div>
            </div>
            <Button
            disabled={(!this.state.feedbackEmoji || !this.state.feedbackText || this.state.charCount>100 ||!!this.state.errorToshow)}
            onClick={this.submitFeedBack} 
            style={(!this.state.feedbackEmoji || !this.state.feedbackText || this.state.charCount>100 || !!this.state.errorToshow)?{
              width: '472px', height: '56px', color: '#909090', backgroundColor: '#BFBFBF', borderRadius: '8px'}:{ width: '472px', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>Submit</Button>
          </div>
        </Dialog>

        <Dialog PaperProps={{ style: {borderRadius: '16px'}}}  
        open={this.state.isformSubmittedPopupOpen} onClose={this.closeFeedbackSubmittedPopup} >
            <div className="closeIconSubmittedFeedback"><CloseIcon onClick={this.closeFeedbackSubmittedPopup} style={{height: '24px', width: '24px', color: '#525252', cursor:"pointer"}}/></div>
            <div className="feedBackPopupBox" style={{padding:"0px 40px 40px 40px"}}>
              <img style={{height:"300px" , width:"300px"}} src={thankyou}></img>  
              <div style={{ display: 'flex', flexDirection: "column", gap: '16px' }}>
                <div className='feedBackHeading'>
               Thanks for your feedback!
              </div>
                <div style={{ fontFamily: 'Barmeno', fontSize: '18px', fontWeight: '500', textAlign: "center", color:"#747474" }}>
                  We're grateful for your feedback! Your input helps us serve you better.
                </div></div>
              <Button onClick={this.closeFeedbackSubmittedPopup} style={{ width: '440px', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>Close</Button>
            </div>
          </Dialog>
          <SignInToContinuePopup open={this.state.isSignInToContinuePopupOpen} onClose={this.closeSignInToContinuePopup} heading={'Sign In to provide feedback'} 
          content={'Your feedback counts. Please sign in to provide your feedback!'} />
             </div>
        )
      }
}