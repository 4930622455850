const configJSON1 = require("../../framework/src/config.js");
import axiosInstance from "./axiosInstance";

export const confirmationEmail = async(token: string) => {
    try {
      axiosInstance.defaults.headers.common["token"] = token;
      const response = await axiosInstance.get(`${configJSON1.baseURL}/account_block/accounts/email_confirmations/token`);
      if(response.status === 200) {
        const {email} = response.data.data.attributes;
        const {first_name = "", last_name = ""} = response.data.meta;
        return {status: 200, openSuccessPopup: true, email: email, firstName: first_name, lastName: last_name}
      }
    } catch (error) {
      console.log('error', error);
      return {error: error}
    }
  }