// Customizable Area Start
import React, { ChangeEvent } from "react";
import FaqController, { Props } from "./FaqController.web";
import { faqHeaderBg } from "./assets";
import "./Faq.css";
import { Button } from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import Popup from "../../../components/src/InteractiveFaq/Popup.web";
import ImageComponent from "../../../components/src/ImageComponent";

export default class FaqContactus extends FaqController {
  constructor(props: Props) {
    super(props);
  }

  userSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string()
        .matches(
          /^[a-zA-Z\s]*$/,
          "This field should not contain special characters or numbers"
        )
        .test(
          "wordCount",
          "First name should contain maximum of 15 characters",
          function (value) {
            return value ? value.length <= 15 : true;
          }
        ),
      lastName: Yup.string()
        .matches(
          /^[a-zA-Z\s]*$/,
          "This field should not contain special characters or numbers"
        )
        .test(
          "wordCount",
          "Last name should contain maximum of 15 characters",
          function (value) {
            return value ? value.length <= 15 : true;
          }
        ),
      phoneNumber: Yup.string().matches(
        /^\+(?:[0-9] ?){6,14}[0-9]$/,
        "The mobile number you entered is not in a valid format. Please enter a valid mobile number."
      ),
      email: Yup.string().test(
        "email",
        "The email address you entered is not in a valid format. Please enter a valid email address",
        function (value) {
          return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
        }
      ),
      subject: Yup.string()
        .required("Subject is required!")
        .test(
          "wordCount",
          "Subject should contain maximum of 30 characters",
          function (value) {
            return value ? value.length <= 30 : true;
          }
        ),
      message: Yup.string()
        .required("This field is required and can’t be left empty")
        .test(
          "wordCount",
          "Message should contain maximum of 100 characters",
          function (value) {
            return value ? value.length <= 100 : true;
          }
        ),
    });
  };

  render() {
    const { firstName, lastName, email, phoneNumber, subject, message } =
      this.state;
    return (
      <div className="faq-contact-main-component">
        <ImageComponent
          imageUrl={faqHeaderBg}
          style={{ width: "100%" }}
          alt="faq-contact-header"
        />
        <header className="header" />
        <div>
          <div className="mainContactusDiv">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "17px",
                alignItems: "center",
              }}
            >
              <h4 style={{ fontFamily: "Barmeno" }} className="faqConHeading">
                Didn’t find an answer?
              </h4>
              <div style={{ fontFamily: "Barmeno" }} className="faqConContent">
                Our team is just an email away and ready to answer your
                questions!
              </div>
            </div>
            <div className="formContainerContactus">
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  subject: "",
                  message: "",
                }}
                validationSchema={this.userSchema}
                onSubmit={(values) => {
                  // same shape as initial values
                }}
                data-test-id="formikform"
              >
                {({ errors, touched, handleChange }) => (
                  <Form id="Form1" className="contacusform">
                    <div className="contactus_field__wrapper">
                      <label
                        style={{ fontFamily: "Barmeno" }}
                        htmlFor="firstName"
                      >
                        First Name<span className="required">*</span>
                      </label>
                      <Field
                        style={{ fontFamily: "Barmeno" }}
                        className={
                          errors.firstName && touched.firstName
                            ? "error-input"
                            : "contact_form-field"
                        }
                        id="firstName"
                        name="firstName"
                        placeholder="Enter your first name"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.handleInputChange(
                            "firstName",
                            event.target.value
                          );
                          handleChange(event);
                        }}
                        value={this.state.firstName}
                      />
                      {errors.firstName && touched.firstName ? (
                        <div
                          style={{ fontFamily: "Barmeno" }}
                          className="errors"
                        >
                          {errors.firstName}
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{ fontFamily: "Barmeno" }}
                      className="contactus_field__wrapper"
                    >
                      <label
                        style={{ fontFamily: "Barmeno" }}
                        htmlFor="lastName"
                      >
                        Last Name<span className="required">*</span>
                      </label>
                      <Field
                        style={{ fontFamily: "Barmeno" }}
                        className={
                          errors.lastName && touched.lastName
                            ? "error-input"
                            : "contact_form-field"
                        }
                        name="lastName"
                        placeholder="Enter your last name"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.handleInputChange(
                            "lastName",
                            event.target.value
                          );
                          handleChange(event);
                        }}
                        value={this.state.lastName}
                      />
                      {errors.lastName && touched.lastName ? (
                        <div
                          style={{ fontFamily: "Barmeno" }}
                          className="errors"
                        >
                          {errors.lastName}
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{ fontFamily: "Barmeno" }}
                      className="contactus_field__wrapper"
                    >
                      <label style={{ fontFamily: "Barmeno" }} htmlFor="email">
                        Email<span className="required">*</span>
                      </label>
                      <Field
                        style={{ fontFamily: "Barmeno" }}
                        className={
                          errors.email && touched.email
                            ? "error-input"
                            : "contact_form-field"
                        }
                        name="email"
                        type="email"
                        placeholder="Enter your e-mail address"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.handleInputChange("email", event.target.value);
                          handleChange(event);
                        }}
                        value={this.state.email}
                      />
                      {errors.email && touched.email ? (
                        <div
                          style={{ fontFamily: "Barmeno" }}
                          className="errors"
                        >
                          {errors.email}
                        </div>
                      ) : null}
                    </div>
                    <div className="contactus_field__wrapper">
                      <label
                        style={{ fontFamily: "Barmeno" }}
                        htmlFor="phoneNumber"
                      >
                        Mobile Number<span className="required">*</span>
                      </label>
                      <Field
                        style={{ fontFamily: "Barmeno" }}
                        className={
                          errors.phoneNumber && touched.phoneNumber
                            ? "error-input"
                            : "contact_form-field"
                        }
                        name="phoneNumber"
                        type="number"
                        placeholder="00000000"
                      >
                        {({ field, form }: FieldProps<string>) => (
                          <PhoneInput
                            inputProps={{
                              name: "phoneNumber",
                              required: true,
                              autoFocus: true,
                            }}
                            country={"gb"}
                            value={this.state.phoneNumber}
                            onChange={(value) => {
                              const formattedValue = "+" + value;
                              handleChange(formattedValue);
                              form.setFieldValue("phoneNumber", formattedValue);
                              form.setFieldTouched("phoneNumber", true);
                              this.handleInputChange(
                                "phoneNumber",
                                formattedValue
                              );
                            }}
                          />
                        )}
                      </Field>
                      {errors.phoneNumber && touched.phoneNumber ? (
                        <div
                          style={{ fontFamily: "Barmeno" }}
                          className="errors"
                        >
                          {errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{ fontFamily: "Barmeno" }}
                      className="contactus_field__wrapper"
                    >
                      <label
                        style={{ fontFamily: "Barmeno" }}
                        htmlFor="subject"
                      >
                        Subject<span className="required">*</span>
                      </label>
                      <Field
                        style={{ fontFamily: "Barmeno" }}
                        className={
                          errors.subject && touched.subject
                            ? "error-input"
                            : "contact_form-field"
                        }
                        name="subject"
                        placeholder="Enter the subject"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.handleInputChange("subject", event.target.value);
                          handleChange(event);
                        }}
                        value={this.state.subject}
                      />
                      {errors.subject && touched.subject ? (
                        <div
                          style={{ fontFamily: "Barmeno" }}
                          className="errors"
                        >
                          {errors.subject}
                        </div>
                      ) : null}
                    </div>
                    <div className="contactus_field__wrapper">
                      <label
                        style={{ fontFamily: "Barmeno" }}
                        htmlFor="message"
                      >
                        Message<span className="required">*</span>
                      </label>
                      <Field
                        style={{
                          fontFamily: "Barmeno",
                          minHeight: "104px",
                          resize: "none",
                          padding: "10px",
                        }}
                        className={
                          errors.message && touched.message
                            ? "error-input"
                            : "contact_form-field"
                        }
                        as="textArea"
                        name="message"
                        placeholder="Type here..."
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          this.handleInputChange("message", event.target.value);
                          this.handleWordCountChange(event),
                            handleChange(event);
                        }}
                        value={this.state.message}
                      />
                      <div className="wordCount_error_container">
                        <div
                          style={{ fontFamily: "Barmeno" }}
                          className="word-count"
                        >
                          {this.state.count}/100{" "}
                        </div>
                        {errors.message && touched.message ? (
                          <div
                            style={{ fontFamily: "Barmeno" }}
                            className="errors"
                          >
                            {errors.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "24px 0px",
                        borderTop: "1px solid #D4D4D4",
                      }}
                    >
                      <Button
                        className={
                          this.disableSubmitButton() ||
                          [
                            "phoneNumber",
                            "email",
                            "firstName",
                            "lastName",
                            "subject",
                            "message",
                          ].some(
                            (field) => errors[field as keyof typeof errors]
                          )
                            ? "submitButtonDisabledCon"
                            : "submitButtonCon"
                        }
                        disabled={
                          this.disableSubmitButton() ||
                          [
                            "phoneNumber",
                            "email",
                            "firstName",
                            "lastName",
                            "subject",
                            "message",
                          ].some(
                            (field) => errors[field as keyof typeof errors]
                          )
                        }
                        onClick={() =>
                          this.submitContact({
                            firstName,
                            lastName,
                            email,
                            phoneNumber,
                            subject,
                            message,
                          })
                        }
                        id="submit"
                        style={{ fontFamily: "Barmeno" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Popup
          open={this.state.openPopup}
          onClose={this.handleClosePopup}
          navigation={this.props.navigation}
        />
      </div>
    );
  }
}

// Customizable Area End
