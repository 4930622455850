import React from 'react'
import {
    americanExpress,
    masterCard,
    masterCard1,
    unionPay,
    visa,
  } from "../../../blocks/inappgifting/src/assets";

const CardOptions = () => {
  return (
    <div style={{display:"flex", gap:"12px", alignItems:"center"}}> <img style={{width:"24px",height:"24px"}} src={visa}/> <img style={{width:"24px",height:"24px"}} src={masterCard1}/> <img style={{width:"24px",height:"16.57px"}} src={americanExpress}/> <img style={{width:"24px",height:"16.94px"}} src={masterCard}/> <img style={{width:"24px",height:"16.57px"}} src={unionPay}/></div>
  )
}

export default CardOptions
