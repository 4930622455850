import React from "react";
// Customizable Area Start
import { Text, StyleSheet, Image, TouchableOpacity } from "react-native";
const FacebookLogin = require("react-facebook-login/dist/facebook-login-render-props")
  .default;
import { facebok_logo } from "./assets";

type Response = {
  status: string;
  id: string;
  name: string;
  email: string;
  accessToken: string;
  userID: string;
};

type RenderProps = {
  isDisabled: boolean;
  isProcessing: boolean;
  isSdkLoaded: boolean;
  onClick: Function;
};
// Customizable Area End

type Props = {
  testID: string;
  appId: string;
  loginFacebookButtonText: string;
  callback: (response: Response) => void;
  onPress: () => void;
  // Customizable Area Start
  // Customizable Area End
};

export default class CustomFacebookLogInButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <FacebookLogin
        fields="first_name,last_name,email,picture"
        scope="email,public_profile"
        appId={this.props.appId}
        callback={this.props.callback}
        autoLoad={false}
        testID="facebookCustomLogin"
        render={(renderProps: RenderProps) => (
            <TouchableOpacity
            onPress={() => {
              renderProps.onClick();
              this.props.onPress();
            }}
            style={styles.facebookStyle}
            testID="facebookCustomLoginButton"
          >
            <Image style={styles.facebookImageStyle} source={facebok_logo} />
          </TouchableOpacity>
        )}
      />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  facebookStyle: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderRadius: 8,
    backgroundColor: "#ffffff",
    width: 56,
    height: 56
  },
  facebookImageStyle: {
    padding: "11px",
    width: 34,
    height: 34
  },
  facebookTextStyle: {
    color: "#231F20",
    fontFamily: "Montserrat",
    paddingLeft: 7,
    fontSize:14
  }
});
// Customizable Area End
