import React from 'react';
import { Button, Typography } from '@mui/material';
import {junk} from "../SocialLogin/assets";
import "./EmptyCartItemAlert.web.css";
interface Props {
    onClose: ()=>void;
    removePoints: (value1: boolean, value2: string)=>void;
    removeGiftCard: (value1:boolean)=>void;
    removeCouponCode: (value2:string)=>void;
    dataTestId?:string;
    heading_remove:string;
    content_remove:string;
    value1: boolean;
    value2: string;
}

const RemoveCodeAlert: React.FC<Props> = ({onClose, removePoints,removeGiftCard,removeCouponCode, heading_remove, content_remove,value1,value2}) => {
    return (
        <div className='deleteCartItem_Container'>
            <img src={junk} alt='junk' />
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <Typography className='title' variant='h5'>{heading_remove}</Typography>
                <Typography className='description' variant='h6'>{content_remove}</Typography>
            </div>
            <div className='btn_group'>
            <Button className='cancel' onClick={() => {onClose()}}>CANCEL</Button>
            <Button className='signin' onClick={() => {heading_remove === "Remove the reward points" ? removePoints(value1,value2) : heading_remove === "Remove the gift card" ? removeGiftCard(value1) : removeCouponCode(value2),onClose()}}>{heading_remove ==="Delete Card"?"DELETE":"REMOVE"}</Button>
            </div>
        </div>
    )
}

export default RemoveCodeAlert;