Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchApiMethod = "PATCH"
exports.deleteApiMethod = "DELETE"
exports.putApiMethod = "PUT"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "paymentadmin2";
exports.labelBodyText = "paymentadmin2 Body";

exports.paymentEnvironMent = "TEST"
exports.paymentType = 'CARD';
exports.allowedAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"]
exports.allowedCardNetworks =  ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"]
exports.tokenizationType= 'PAYMENT_GATEWAY'
exports.tokenizationParameter = {
  "gateway": "stripe",
  "stripe:version": "2018-10-31",
  "stripe:publishableKey": "pk_test_51POdVTLcQILBXFg4WEifpfXCwnAbBLFz7jXv4CjMmmN275b7esY8Y84mDBdofbLDGEaCPqi5xt8nzQQJXaHWa7gx00RzX2G0jo"
}
exports.merchantId = "23456678"
exports.merchantName = "Test Merchant"
exports.totalPriceStatus = "FINAL"
exports.totalPriceLabel = "Total"
exports.currencyCode = "GBP"
exports.countryCode = "GB"
exports.callbackIntents = ["PAYMENT_AUTHORIZATION"]
exports.buttonColor = 'white'
exports.buttonType='short'
exports.buttonSizeMode='fill'
exports.paymentMethodType = 'gpay'
exports.paymentStatus = "succeeded"
exports.transactionState = "SUCCESS"
exports.monthNames = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
]

exports.btnExampleTitle = "CLICK ME";
exports.saveCardDetailsApiEndPoint = "bx_block_stripe_integration/add_card"
exports.gpayEndPoint ="bx_block_stripe_integration/order_transaction_for_gpay"
exports.doPaymentApiEndPoint = "bx_block_stripe_integration/payments"

exports.getListOfCardApiEndPoint = "bx_block_stripe_integration/payment_methods"
exports.deleteCardApiEndpoint = "bx_block_stripe_integration/payment_methods/"
exports.getCODApiEndPoint = "bx_block_order_management/orders/"
// Customizable Area End