import React from 'react'
import ImageComponent from './ImageComponent'
import { dealFoundImg } from './assets'
import { DealDataConversion } from '../../blocks/shoppingcart/src/ShoppingCartOrdersController'
import { Button } from '@material-ui/core'
import "./DealFoundPopup.css";

interface DealProps{
    dealData:{deal:{data:DealDataConversion}, message:string,save_value_message:string} ;
    onClose:()=>void;
    onDeal:(id:string)=>void
    closePopup:()=>void
    deleteDeal:()=>void
    cartsList:(token:string)=>void
    token:string
}

const DealFoundPopup:React.FC<DealProps>= ({dealData,onClose,onDeal,closePopup,deleteDeal,cartsList,token})  => {
  return (
    <div style={{display:"flex", flexDirection:"column", gap:"16px", alignItems:"center", justifyContent:"center", fontFamily:"Barmeno", padding:"0px 40px 40px 40px"}}>
      <ImageComponent imageUrl={dealFoundImg} alt="deal_image" style={{height:"300px"}}/>
      <div style={{maxWidth:"520px", display:"flex", flexDirection:"column", gap:"24px",fontWeight:700}}>
        <div style={{fontSize:"24px", textAlign:"center"}}>🍗🧀 Combo Deal Alert! 🌮🥤</div>
        <div style={{fontSize:"18px",textAlign:"center",color:"#747474", display:"flex", flexDirection:"column", gap:"10px"}}>
        <div>{dealData.message} </div>
        <div>How about upgrading to our <span style={{color:"#1D1D1D",fontWeight:700}}>{dealData.deal.data.attributes.sub_sub_category.title}</span> Combo Deal (<span className="description-container" dangerouslySetInnerHTML={{__html : `${dealData.deal.data.attributes.sub_sub_category.description}`}}/>)?</div>
<span style={{color:"#2C6F37", fontWeight:400}}>*{dealData.save_value_message}</span></div>
      </div>
      <div style={{width:"100%",display:"flex",gap:"40px",justifyContent:"center"}}>
        <Button className='deal_found__button' style={{width: "100%",maxWidth: "200px",borderRadius: "8px",border:"1px solid #E11B22", color:"#E11B22",height:"56px" }} onClick={onClose}>CANCEL</Button>
        <Button className='deal_found__button' style={{width: "100%",maxWidth: "200px",borderRadius: "8px",color:"#FFF",height:"56px", backgroundColor:"#E11B22" }} onClick={()=>{onDeal(dealData.deal.data.id),closePopup(),deleteDeal()}}>CUSTOMIZE DEAL</Button>
        </div>
    </div>
  )
}

export default DealFoundPopup
