import React from "react";
import { ListItemButton } from "@mui/material";
import { Button, IconButton, List, ListItemIcon, ListItemText, Popover } from "@material-ui/core";
import { ChevronRightRounded, DeleteOutlined, MarkunreadOutlined, MoreVertRounded } from "@material-ui/icons";
import { NotificationAttributes, configJSON } from "./NotificationsController";
import "./NotificationItem.web.css";
import { defaultNotificationImage, markAsReadIcon } from "./assets";

interface Props {
    id: string;
    navigation: any;
    notification: NotificationAttributes;
    markAsRead: (id: number) => void;
    deleteNotification: (id: number) => void;
    dialog?: boolean;
}

interface S {
    anchorEl: null | HTMLElement;
}

export default class NotificationItem extends React.Component<Props, S> {
    constructor(props: Props) {
        super(props);

        this.state = {
            anchorEl: null
        }
    }

    handleContextMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (event?.currentTarget) 
            this.setState({ anchorEl: event.currentTarget });
    }

    handleCloseContextMenu = () => 
        this.setState({ anchorEl: null });

    handleMarkAsRead = () => {
        this.props.markAsRead(this.props.notification.id);
        this.handleCloseContextMenu();
    }

    handleDelete = () => {
        this.props.deleteNotification(this.props.notification.id);
        this.handleCloseContextMenu();
    }

    formatCreatedAt = (date: string) => {
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit'
        });

        return formattedDate;
    }

    showCta = (): { show: boolean; target?: string; text?: string } => {
        const { notification } = this.props;

        switch (notification.notification_for) {
            case "order":
                return { show: true, target: "OrderHistory", text: "View Details" };
            case configJSON.accountCTA.includes(notification.notification_for):
                return { show: true, target: "Profile", text: "Account" };
            default:
                return { show: false };
        }
    }

    render() {
        const { notification } = this.props;
        const cta = this.showCta();

        return (
            <article className="notificationWrapper">
                <img data-testid="notification-image" src={!!notification.image ? notification.image.url : defaultNotificationImage} alt="thumbnail" className="notificationWrapper__thumbnail" />
    
                <div className="notificationWrapper__content">
                    <div className="notificationWrapper__content__titleWrapper">
                        <h3 data-testid="notification-title" className={this.props.dialog ? "notificationWrapper__content__titleWrapper__title__dialog" : "notificationWrapper__content__titleWrapper__title"} style={{ color: notification.is_read ? "inherit" : "#2C6F37" }}>{notification.headings}</h3>
                        {!notification.is_read && <div data-testid="notification-indicator" className="notificationWrapper__content__titleWrapper__notReadIndicator" />}
                    </div>
    
                    {notification.contents && (
                        <p className="notificationWrapper__content__description">
                            {notification.contents}
                        </p>
                    )}
    
                    {cta.show && (
                        <Button
                            data-testid="cta-button"
                            color="default"
                            onClick={() => {
                                this.props.markAsRead(notification.id);
                                window.location.href = cta.target || "";
                            }}
                            endIcon={<ChevronRightRounded fontSize="small" />}
                            classes={{ 
                                root: "notificationWrapper__content__cta",
                                endIcon: "notificationWrapper__content__cta__icon",
                                label: "notificationWrapper__content__cta__text"
                            }}
                        >
                            {cta.text}
                        </Button>
                    )}
    
                    <span className="notificationWrapper__content__time">
                        {this.formatCreatedAt(notification.created_at)}
                    </span>
                </div>
    
                <div className="notificationWrapper__contextMenu">
                    <IconButton data-testId="open-context-menu" className="notificationWrapper__contextMenu__cta" onClick={this.handleContextMenu}>
                        <MoreVertRounded />
                    </IconButton>
    
                    <Popover
                        data-testId="context-menu"
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleCloseContextMenu}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        classes={{ paper: "notificationWrapper__contextMenu__paper" }}
                        className="notificationWrapper__contextMenu__wrapper">
                        <List className="notificationWrapper__contextMenu">
                            <ListItemButton
                                data-testId="mark-as-read-button"
                                sx={{ padding: "0 16px" }}
                                classes={{ root: "notificationWrapper__contextMenu__listItem" }}
                                onClick={this.handleMarkAsRead}>
                                <ListItemIcon classes={{ root: "notificationWrapper__contextMenu__icon__wrapper" }}>
                                    <img src={markAsReadIcon} alt="mark as read" className="notificationWrapper__contextMenu__icon" />
                                </ListItemIcon>
    
                                <ListItemText primary="Mark as read" />
                            </ListItemButton>
    
                            <ListItemButton data-testId="delete-button" sx={{ padding: "0 16px" }} onClick={this.handleDelete}>
                                <ListItemIcon classes={{ root: "notificationWrapper__contextMenu__icon__wrapper" }}>
                                    <DeleteOutlined color="error" className="notificationWrapper__contextMenu__icon" />
                                </ListItemIcon>
    
                                <ListItemText primary="Delete" className="notificationWrapper__contextMenu__wrapper__error" />
                            </ListItemButton>
                        </List>
                    </Popover>
                </div>
            </article>
        )
    }
}