import React from "react";
// Customizable Area Start
import { StyleSheet, Dimensions, Platform } from "react-native";
import GoogleMapReact from 'google-map-react';
// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const AnyReactComponent = ({ text }:any) => <div>{text}</div>;
    return (
      <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
      style={{ height: '500px', position:"relative"}}
      defaultCenter={{lat: 55.378051,lng:-3.435973}}
      defaultZoom={11}
    >
     <AnyReactComponent
     style={{height:"500px"}}
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        /> 
    </GoogleMapReact>
  </div>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  },
  map: {
    width: viewportWidth,
    height: viewportHeight,
    position: "absolute",
    top: 0,
    bottom: 0,
  },
});
// Customizable Area End
