import React from "react";
import { Props, } from "./ForgotPasswordController";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { bgImage } from "./assets";
import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, TextField, Typography } from "@material-ui/core";
import EmailSentPopup from "./EmailSentPopup.web";
import OtpSentPopup from "./OtpSentPopup.web";
import CustomMobileBox from "../../../components/src/CustomMobileBox.web";
import './NewPassword.css'
import NewPasswordController from "./NewPasswordController";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class ForgotPassword extends NewPasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <div style={{backgroundColor: 'rgb(223, 223, 223)', padding:"2 0"}}>
                <Box className="container">
                    <Box className="heading">
                        <Typography style={{fontFamily:'Barmeno'}} className="headingTextT" variant="h4" component="h2">
                            Reset Password
                        </Typography>
                        <Typography style={{fontFamily:'Barmeno'}} className="headingInfoT" variant="subtitle1" component="div">
                            Set your new account password, so that you can sign in and access all features.
                        </Typography>
                        <RadioGroup
                            row
                            style={webStyle.choiceT}
                            defaultValue="email"
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group" 
                            onChange={(e) => this.changeInput(e)}
                        >
                            <FormControlLabel value="email" control={<Radio />} className="choice" style={webStyle.emailT} label="Email Address" />
                            <FormControlLabel value="phone" control={<Radio />} className="choice" style={webStyle.mobileT} label="Mobile Number" />
                        </RadioGroup>
                        {this.state.selectedOption === 'email' && (<>
                            <FormLabel style={{fontFamily:'Barmeno'}} className="passwordTextT"  >Email Address</FormLabel>
                            <div><TextField style={{fontFamily:'Barmeno'}} itemID="txtInputEmail" value={this.state.emailValue} onChange={(e) => this.setState({ emailValue: e.target.value, emailValidation: 'Valid' })} size="small" className ='emailInputT' variant="outlined" /></div>
                            {this.state.emailValidation === 'Invalid' ?
                                <Typography style={{fontFamily:'Barmeno'}} className="emailNotValid" >Email not Valid</Typography> : null}
                            <div>
                            <Button style={{textTransform: 'none' ,marginTop:"0px !important",fontFamily:'Barmeno'}} id="btnVerify" variant="contained" color="primary" size="small" onClick={() => this.sendVerification(this.state.emailValue)} className={this.disableEmailVerify() ?"button" : "resetButton"}disabled={this.disableEmailVerify()}>
                                Verify
                            </Button>
                            </div>
                        </>)}
                        {this.state.selectedOption === 'phone' && (<div className="mobileBox">
                            <CustomMobileBox onCountryCodeChange={(e) => this.handleCountryCodeChange(e)} onMobileNumberChange={(e) => this.handleInputChange(e)}/>
                            <Button style={{ textTransform: 'none', fontFamily:'Barmeno' }} id="btnVerify" onClick={this.sendMobileOtpApi} variant="contained" color="primary" size="small" className={this.disablePhoneVerify() ?"button" : "resetButton"} disabled={this.disablePhoneVerify()}>
                                Verify
                            </Button>
                        </div>)}
                    </Box>
                    <Box className="bgContainer">
                        <img className="bgImage" alt="background"  src={bgImage} />
                    </Box>
                    <EmailSentPopup resendClick = {()=>this.sendLink(this.state.emailValue)} open={this.state.openPopup} onClose={this.handleClosePopup} email={this.state.emailValue} />
                    <OtpSentPopup resendClick={()=>{}} length={4} onChange={() => {}} inValidOtp={this.state.inValidOtp} open={this.state.openOtpPopup} onClose={this.handleCloseOtpPopup} phone={this.state.phoneValue} confirmOtp={this.confirmOtpCall} />
                </Box>
                </div>
            </ThemeProvider>
        )
    }
}

// Customizable Area Start

const webStyle = {
    choiceT: { marginBottom: '10px', marginTop: '37px' },
    emailT: { fontSize: "16px !mportant", color: "#231f20 !mportant", fontWeight: "500 !mportant", fontFamily: "Barmeno" },
    mobileT: { fontSize: "16px !mportant", fontWeight: "500 !mportant", color: "#231f20 !mportant", fontFamily: "Barmeno", },
};
// Customizable Area End