import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import CardOptions from "../../../components/src/ConfirmOrder/CardOptions.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Paymentadmin2Controller, {
  Props,
  configJSON,
} from "./Paymentadmin2Controller";

export default class Paymentadmin2 extends Paymentadmin2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <div style={{display:"flex",flexDirection:"column", gap:"20px",fontFamily:"Barmeno"}}>
        <div className="card_details" style={{gap:"24px"}}>
          <div style={{display:"flex",flexDirection:"column",gap:"4px"}}>

            <div className="senders-details" style={{ flexDirection: location.pathname==="/PaymentManagement"?"row":"column" }}>
              <div className="custom_amount_container">
                <label htmlFor='cardHolderName' className="custom_giftAmount_label_payment" style={{ fontSize: "18px" }}>Cardholder Name</label>
                <input disabled={!!this.props.card_details_edit} onChange={this.handleCardDetailsInput} style={{ fontFamily: 'Barmeno' }} className="gift-amount-form-field" name="cardHolderName" placeholder='Enter cardholder name' value={this.state.cardHolderName}
                />
              </div>

              <div style={{ width: "100%" }}>
                <div className="custom_amount_container">
                  <label htmlFor='number' className="custom_giftAmount_label_payment" style={{ fontSize: "18px" }}>Card Number</label>
                  <input
                    onChange={this.handleCardDetailsInput} value={this.state.cardNumber} disabled={!!this.props.card_details_edit}
                    style={{ fontFamily: 'Barmeno' }} type="tel" className="gift-amount-form-field" name="cardNumber" placeholder='1234 1234 1234 1234' maxLength={this.state.maxLengthCardNumber} />
                  <div id="cardNumberError" style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Barmeno", color: "#E11B22" }} >{this.state.cardAddingErrors}</div>
                </div>
                <div><CardOptions />
                  <div style={{ fontFamily: "Barmeno", fontSize: "12px", color: "#909090", fontWeight: 500, paddingTop: "4px" }}>Accepted Credit and Debit Card types</div></div>
              </div>
            </div>
          </div>
          
          <div className="senders-details" style={{ flexDirection: "row" }}>
            <div className="custom_amount_container">
              <label htmlFor='expiry' className="custom_giftAmount_label_payment" style={{ fontSize: "18px" }}>Expiry Date</label>
              <input
                onChange={this.handleCardDetailsInput} value={this.state.cardExpiry}
                style={{ fontFamily: 'Barmeno' }} className="gift-amount-form-field" name="cardExpiry" placeholder='MM / YY' />
              <div id="expiryDateerror" style={{ fontSize: "12px", fontWeight: 700, fontFamily: "Barmeno", color: "#E11B22" }}>{this.state.expiryError}</div>
            </div>
            <div className="custom_amount_container">
              <label htmlFor='cvc' className="custom_giftAmount_label_payment" style={{ fontSize: "18px" }}>CVC</label>
              <input disabled={!!this.props.card_details_edit}
                onChange={this.handleCardDetailsInput} value={this.state.cardCvc}
                style={{ fontFamily: 'Barmeno' }} className="gift-amount-form-field" name="cardCvc" placeholder='123'
              />
            </div>
          </div>
        </div>
        <div className="payment_button_container" style={{ padding:location.pathname==="/PaymentManagement"?"0px":"24px" }}>
          <Button id="backButton" onClick={this.props.changeOpenAddPaymentPopup} className="giftcard_back_button">
            CANCEL
          </Button>
          {this.props.card_details_edit?
          <Button disabled={this.submitDisabled()} onClick={this.updateCardDetails} name="proceed to add card" className={this.submitDisabled() ? "giftcard_continue_button_disabled" : "giftcard_continue_button"}>
          UPDATE CARD
        </Button>:
          <Button disabled={this.submitDisabled()} onClick={this.saveCardDetails} name="proceed to add card" className={this.submitDisabled() ? "giftcard_continue_button_disabled" : "giftcard_continue_button"}>
          SAVE
        </Button>
          }
          
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
