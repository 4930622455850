import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import SearchController, { Props } from "./SearchController";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <div className="giftCardHistoryPanel">
        <div className="giftCardHistoryHeading">Food Order History</div>  <div className='search_order_history_container'> <SearchRoundedIcon /> <input className='search_order_history_input' placeholder='Search by order number' onChange={this.changeSearchInput}  onKeyPress={this.onPressEnter} value={this.state.seacrhOrderNumberInput} /> </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
