Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.validationCheck = "bx_block_settings/settings/";
exports.endPoint = "check_password"
exports.deleteAccountApi = "bx_block_settings/settings/delete_my_account";
exports.changePasswordApiEndPoint = 'bx_block_settings/settings/change_password';
exports.deleteApiMethodType = "DELETE";
exports.putApiMethodType = "PUT";
exports.btnExampleTitle = "CLICK ME";
exports.checkBalanceAndGiftCard = "bx_block_settings/settings/active_order_or_giftcard_balance"
// Customizable Area End