import React from "react";
import { Button, Dialog } from "@material-ui/core";
// import {noItemsInCartImage} from "../SocialLogin/assets"
import CloseIcon from '@material-ui/icons/Close'
import ImageComponent from "../ImageComponent";
import { tokenExpireImage } from "../SocialLogin/assets";
import { removeStorageData, resetTo } from "../../../framework/src/Utilities";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";


interface Props {
    navigation: any;
}

interface S { }
interface SS { }

export default class TokenExpirePopup extends BlockComponent<Props, S, SS> {

    constructor(props: Props = { navigation:null }) {
        super(props);

        this.subScribedMessages = [getName(MessageEnum.TokenExpiredAlertMessage)]
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        this.state = {
        }
        this.receive = this.receive.bind(this);
    }

    componentDidMount= async()=>{
        await this.navigateToLandingPage()
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.TokenExpiredAlertMessage) === message.id) {
            this.navigateToTokenExpirePopup(message);}

    }
    logoutUser = async () => {
        await removeStorageData('authToken');
        await removeStorageData('profileData');
        await removeStorageData('deviceToken');
        await removeStorageData('role');
        await removeStorageData('catalogListData');
        await removeStorageData('editAddress')
    }

    navigateToSignIn = async() => {
        await this.logoutUser()
        location.href = location.origin+'/accountlogin'
    }

    navigateToLandingPage = async() => {
        {   await this.logoutUser()
            const timer = setTimeout(() => {
                location.href = location.origin
              
            }, 10000);
        
            return () => clearTimeout(timer);
          }
    }

    navigateToTokenExpirePopup = (message: Message) => {

        const msg: Message = new Message(getName(MessageEnum.TokenExpireMessage));
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            message.getData(getName(MessageEnum.NavigationPropsMessage))
        )

        msg.copyAllPropertiesOf(message);

        this.send(msg)
    }

    render() {
        return (
            <Dialog PaperProps={{ style: { borderRadius: '16px' } }}
                open={true} style={{ borderRadius: "16px" }} maxWidth="sm" >
                <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', width: '100%', boxSizing: 'border-box', alignSelf:"end" }}><CloseIcon onClick={this.navigateToSignIn} style={{ height: '24px', width: '24px', color: '#525252', cursor:'pointer', padding:"10px" }} /></div>
                <div className="feedBackPopupBox">
                    <ImageComponent imageUrl={tokenExpireImage} alt="no-item" style={{ width: "250px" }} />
                    <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                        <div className="feedBackHeading">
                            Session Expired!
                        </div>
                        <div className="feedback_form_container" style={{ fontSize: "18px", fontFamily: "Barmeno", textAlign: "center", fontWeight: 500, color: "#747474" }} >
                            Your session has expired due to inactivity. Please Sign in Again to continue.
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "40px", width: "100%", justifyContent: "center" }}>
                        <Button onClick={this.navigateToSignIn} style={{ width: "100%", maxWidth: '440px', height: '56px', color: '#fff', backgroundColor: '#E11B22', borderRadius: '8px' }}>SIGN IN</Button>
                    </div>
                </div>
            </Dialog>
        );
    }
}