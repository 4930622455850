import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    IconButton,
    FormControlLabel,
    RadioGroup,
    FormLabel,
    TextField,
    OutlinedInput,
    Link,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {InfoOutlined } from "@material-ui/icons";
import CustomRadioBox from "../../../components/src/CustomRadioBox.web";
import CustomMobileBox from "../../../components/src/CustomMobileBox.web";
import SocialLogin from "./../../../components/src/SocialLogin/SocialLogin";
import EmailSentPopup from "./../../forgot-password/src/EmailSentPopup.web";
import OtpSentPopup from "./../../forgot-password/src/OtpSentPopup.web";
import OtpSuccessfulPopup from "./../../../components/src/OtpSuccessfulPopup.web";
import "./EmailAccountRegistration.web.css";
import {banner} from './assets';
// @ts-ignore
import globalTheme from "../../../components/src/Theme/Theme.js";
//@ts-ignore
// Customizable Area End

import EmailAccountRegistrationController, {
    Props,
} from "./EmailAccountRegistrationController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
  

class EmailAccountRegistrationBlock extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <ThemeProvider theme={globalTheme}>
                <div className="account-regirstration" style={{backgroundColor: 'rgb(223, 223, 223)'}}>
                <Box
                    sx={webStyle.mainWrapper}
                >
                    <Box sx={webStyle.header} >
                        <Typography style={webStyle.headerText} variant="h4" component="h2">
                            Sign Up
                        </Typography>
                        <Typography style={webStyle.headerInfo} variant="subtitle1" component="div">
                            Enter your details to Sign up on Wingo's!
                        </Typography>
                        <RadioGroup
                            row
                            style={webStyle.choice}
                            defaultValue="email_account"
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {this.onAccountTypeChange(e.target.value)}}
                        >
                            <FormControlLabel value="email_account" control={<CustomRadioBox />} style={webStyle.email} label={<Typography variant="body1" style={webStyle.radioLabel}>Email Address</Typography>} />
                            <FormControlLabel value="sms_account" control={<CustomRadioBox />} style={webStyle.mobile} label={<Typography variant="body1" style={webStyle.radioLabel}>Mobile Number</Typography>} />
                        </RadioGroup>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', margin: '16px 0 24px 0' }}>
                            <div>
                                <FormLabel style={{ ...webStyle.emailInputText, width: "240px" }}  >First Name</FormLabel>
                                <div><TextField itemID="itmFirstName" inputProps={{ maxLength: 15 }} placeholder="Enter first name"  value={this.state.firstName} onChange={(e) => this.handleInputChange('firstName', e.target.value)} id="outlined-basic" size="small" style={{ ...webStyle.emailInput, width: "240px" }} variant="outlined" /></div>
                            </div>

                            <div>
                                <FormLabel style={{ ...webStyle.emailInputText, width: "240px" }}  >Last Name</FormLabel>
                                <div><TextField itemID="itmLastName" inputProps={{ maxLength: 15 }} placeholder="Enter last name" value={this.state.lastName} onChange={(e) => this.handleInputChange('lastName', e.target.value)} id="outlined-basic" size="small" InputProps={{style: { fontFamily: 'Barmeno', fontWeight: '500 !important', color: 'rgba(49, 49, 49, 1)', fontSize: '16px', lineHeight: '16px' }}} style={{ ...webStyle.emailInput, width: "240px" }} variant="outlined" /></div>
                            </div>
                        </div>
                        <div style={{margin: '0 0 24px 0'}}>{this.state.signUpType === "email_account" ? (<><FormLabel style={webStyle.emailInputText}  >Email Address</FormLabel>
                        <div style={{position: "relative"}}>
                            <TextField itemID="itmEmail" placeholder="Enter email address" error={(this.state.email && this.state.emailCorrect === false) || this.state.emailExists} value={this.state.email} onChange={(e) => this.handleInputChange('email', e.target.value.trim())} id="outlined-basic" size="small" InputProps={{style: { fontFamily: 'Barmeno', fontWeight: '500 !important', color: 'rgba(49, 49, 49, 1)', fontSize: '16px', lineHeight: '16px' }}} style={webStyle.emailInput} variant="outlined" />
                            {(this.state.email && this.state.emailCorrect === false) && (<span style={{position: "absolute", fontFamily: "Barmeno", bottom: "-15px", left: 0, color: "rgba(228, 35, 40, 1)", fontWeight: "500", fontSize: "12px", lineHeight: "12px"  }}>The email address you entered is not in a valid format. Please enter a valid email address</span>)}
                            {this.state.emailExists && (<span style={{position: "absolute", fontFamily: "Barmeno", bottom: "-15px", left: 0, color: "rgba(228, 35, 40, 1)", fontWeight: "500", fontSize: "12px", lineHeight: "12px"  }}>The email address you entered is already exists.</span>)}
                        </div></>) : 
                        (<div className="signin_mobile" style={{position: "relative"}}>
                        <CustomMobileBox numberExists={this.state.numberExists} onCountryCodeChange={(e) => this.setState({countryCodeSelected: e})} onMobileNumberChange={(e) => this.handleInputChange('phone', e)}    />
                        {this.state.numberExists && (<span style={{position: "absolute", fontFamily: "Barmeno", bottom: "-15px", left: 0, color: "rgba(228, 35, 40, 1)", fontWeight: "500", fontSize: "12px", lineHeight: "12px"  }}>The phone number you entered already exists.</span>)}
                        </div>)
                        }</div>
                        <div style={{margin: '0 0 24px 0'}}>
                        <FormLabel style={webStyle.passwordText}  >Password</FormLabel>
                        <div>
                            <OutlinedInput
                                className="password"
                                itemID="itmPassword"
                                placeholder="Enter password"
                                value={this.state.password}
                                onChange={(e) => this.handleInputChange('password', e.target.value.trim())}
                                inputProps={{ maxLength: 15, style: { fontFamily: 'Barmeno', fontWeight: '500 !important', color: 'rgba(49, 49, 49, 1)', fontSize: '16px', lineHeight: '16px' }}}                        
                                style={webStyle.passwordInput}
                                type={!this.state.enablePasswordField ? "text" : "password"}

                            />
                            <IconButton
                            className="eyeIcon"
                                itemID="enablePass"
                                style={{ margin: '0px 0px 0px -50px' }}
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enablePasswordField: !this.state.enablePasswordField })}

                            >
                                {!this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </div>
                        <Box style={webStyle.infoBox}>
                            <Typography style={webStyle.infoTextPoints} variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{marginRight:'5px', color:this.state.password.match(/[A-Z]/gm) ? "#409046" : "#e11b23"}} fontSize="small" />{"   "} At least one capital letter (A-Z)
                            </Typography>
                            <Typography style={webStyle.infoTextPoints} variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{marginRight:'5px', color:this.state.password.match(/[a-z]/gm) ? "#409046" : "#e11b23"}} fontSize="small" />{"   "}  At least one lowercase letter (a-z)
                            </Typography>
                            <Typography style={webStyle.infoTextPoints} variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{marginRight:'5px',  color: this.state.password.match(/\d/gm) ? "#409046" : "#e11b23" }} fontSize="small" />{"   "}  At least one number (0-9)
                            </Typography>
                            <Typography style={webStyle.infoTextPoints} variant="subtitle2" gutterBottom>
                                <InfoOutlined style={{marginRight:'5px', color:this.state.password.length >= 8 ? "#409046" : "#e11b23" }} fontSize="small" /> {"   "} Minimum 8 characters length
                            </Typography>
                        </Box>
                        </div>

                        <div style={{margin: '0 0 24px 0'}}>
                        <FormLabel style={{ ...webStyle.passwordText }}  >Confirm Password</FormLabel>
                        <div style={{position: "relative"}}>
                            <OutlinedInput
                                className="password"
                                itemID="itmCnfPass"
                                placeholder="Confirm password"
                                error={this.state.passwordMatch === false}
                                value={this.state.reTypePassword}
                                onChange={(e) => this.handleInputChange('reTypePassword', e.target.value.trim())}
                                inputProps={{ maxLength: 15, style: { fontFamily: 'Barmeno', fontWeight: '500 !important', color: 'rgba(49, 49, 49, 1)', fontSize: '16px', lineHeight: '16px' }}} 
                                style={webStyle.passwordInput}
                                type={!this.state.enableReTypePasswordField ? "text" : "password"}

                            />
                            <IconButton
                            className="eyeIcon"
                                style={{ margin: "0px 0px 0px -50px" }}
                                itemID="testABC"
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enableReTypePasswordField: !this.state.enableReTypePasswordField })}
                            >
                                {!this.state.enableReTypePasswordField ? <Visibility /> : <VisibilityOff />}

                            </IconButton>
                            {(this.state.passwordMatch === false) && (<span style={{position: "absolute", bottom: "-14px", left: 0, color: "#E42328", fontWeight: "500", fontSize: "12px", lineHeight: "12px", fontFamily: "Barmeno"  }}>Password and confirm password does not match. Please try again!</span>)}
                        </div>
                        </div>

                        <Button id="btnEmailSignUp" variant="contained" color="primary" size="small" onClick={() => this.createAccount()} disabled={!this.state.isFormValid} style={webStyle.signIn} disableElevation>
                            SIGN UP
                        </Button>

                        <Typography style={webStyle.existAccountText as any}  >
                            Already have an account?
                            <Link itemID="goToSignIn" onClick={() => { }} style={{ marginLeft: '0px', fontFamily: "Barmeno", fontSize: "16px", fontWeight: "700", lineHeight: "19.2px", color: "rgba(225, 27, 34, 1)" }} href="/accountlogin">
                                {" Sign in"}
                            </Link>
                        </Typography>
                        <Box display="flex" alignItems="center" marginTop={"30px"}>
                            <Box borderTop={1} flexGrow={1} borderColor="#909090" />
                            <Typography style={{fontFamily: "Barmeno", fontWeight: 700, fontSize: 20, lineHeight: "24px", margin: "0 12px" }} variant="body1">Sign Up Using</Typography>
                            <Box borderTop={1} flexGrow={1} borderColor="#909090" />
                        </Box>
                        <SocialLogin loggedIn={(this.props as any).loggedInUser} navigation={this.props.navigation} />
                        

                        <div style={webStyle.subHeader} >  
                        <Typography style={webStyle.subHeader}> By clicking "Sign Up", you agree to the{' '}                              
                            <Link id="opnDialog" style={webStyle.terms} href="/TermsConditions" underline="always" color="inherit" >Terms and Conditions</Link>
                            {' '}and{' '}
                            <Link id="opnPrivacyDialog" style={webStyle.terms} href="/PrivacyPolicy" underline="always" color="inherit" >Privacy Policy</Link>.
                        </Typography>
                        </div>

                    </Box>
                    <Box style={{width: '670px'}}>
                        <img src={banner} style={webStyle.bgImage} alt="banner" />
                    </Box>
                    <EmailSentPopup resendClick={() => {this.resendEmailVerification()}} open={this.state.openPopup} onClose={() => {this.closeEmailPopup()}} email={this.state.email} />
                    <OtpSentPopup resendClick={() => this.resendOtp()} length={4} onChange={() => {}} inValidOtp={this.state.inValidOtp} open={this.state.openOtpPopup} onClose={() => {this.closeOtpPopup()}} phone={this.state.phone} confirmOtp={this.otpAuthentication} />
                    <OtpSuccessfulPopup open={this.state.openSuccessPopup} onClose={() => {this.closeConfirmationPopup()}} />
                </Box>
                </div>
                </ThemeProvider>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
export default EmailAccountRegistrationBlock;

const webStyle = {
    mainWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        fontFamily: 'Barmeno',
        margin: '40px 40px 36px 40px',
        backgroundColor: '#FFFFFF',
        borderRadius: '32px',
    },
    header: {
        padding: "50px 45px 100px 45px",
        margin: '0 auto'
    },
    headerText: { fontSize: "30px", fontWeight: "700", lineHeight: "30px", color: "#313131", fontFamily: 'Barmeno', marginTop: "2px" },
    headerInfo: { color: "#909090", lineHeight: '21px', fontFamily: "Barmeno", fontWeight: "500", margin: "20px 0px 26px", fontSize: '18px' },
    choice: { marginTop: '0 0 16px 0' },
    email: { fontFamily: "Barmeno !important", color: "#231f20", fontSize: "16px", fontWeight: "500", lineHeight: "26px" },
    mobile: { fontFamily: "Barmeno !important", color: "#231f20", fontSize: "16px", fontWeight: "500", lineHeight: "26px" },
    emailInputText: { fontFamily: "Barmeno", color: "#909090", fontSize: "16px", fontWeight: "500", lineHeight: "16px" },
    emailInput: { width: '100%' },
    passwordText: { fontFamily: "Barmeno", color: "#909090", fontSize: "16px", fontWeight: "500", lineHeight: "16px" },
    passwordInput: { height: '44px', width: "100%" },
    subHeader: { width: "500px", margin: '24px 0 30px 0', alignItems: "center", fontSize: "14px", justifyContent: "space-between", fontFamily: "Barmeno, sans-Serif", fontWeight: "600", lineHeight: "16.8px" },
    checkbox: { fontFamily: "Barmeno", color: "#231f20", fontSize: "16px", marginLeft: "0px", fontWeight: "500", lineHeight: "24px" },
    forgotText: { marginLeft: '130px', color: "#e11b23", fontWeight: "600", lineHeight: "19.2px", fontSize: '16px' },
    signIn: { backgroundColor: "#E11B23", borderRadius: "8px", fontSize: "18px", fontWeight: "700", fontFamily: "Barmeno", width: "500px", height: '56px', maxHeight: "56px" },
    existAccountText: { fontFamily: "Barmeno", width: '100%', textAlign: "center", color: "#231f20", fontWeight: "500", lineHeight: "16px", fontSize: '16px', marginTop: "16px" },
    bgImage: { width: '100%', height: "100%" },
    infoBox: { height: "100px", width: "300px", margin: "4px 0 0 0" },
    infoTextPoints: {
        fontSize: "14px",
        lineHeight: "22px",
        fontWeight: "500",
        fontFamily: "Barmeno",
        color: "#231f20",
        display: "flex"
    },
    terms: {
        fontFamily: "Barmeno",
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "19px",
        letterSpacing: "0em",
        cursor: "pointer",
    },
    radioLabel: {fontFamily: "Barmeno", fontWeight: "500", fontSize: "16px", lineHeight: "16px"}
};
// Customizable Area End