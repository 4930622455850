import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";
import { allergenInfo, wingobg } from "./assets";
import {borderLeftColor, fontWeight} from "../../../components/src/utils";
import "./styles.css";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class AllergenInformation extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column", width: "100%", backgroundImage: `url(${wingobg})`, backgroundSize: "contain"}}>
            <div style={{ position: "relative", width: "100%" }}>
              <img src={allergenInfo} alt="react logo" style={styles.aBottomImage} />
            </div>
            <div style={styles.aMainDivStyle}>
              <ul style={{ listStyleType: "none", display: "block", position: "relative", height: "max-content", width: "19.2%", padding: "6px 10px 6px 12px", marginLeft: "40px", backgroundColor: "white", borderRadius: "8px" }}>
                {this.state.allergenInformationList.map((item, i) => {
                  const { id, type, attributes } = item;
                  const { category } = attributes;
                  return (
                    <li key={id} style={{ ...styles.aListItemStyle, borderLeftColor: borderLeftColor(this.state.selectedItem, category, i)}}>
                      <span
                        key={category}
                        style={{
                          ...styles.aAnchorStyle,
                          fontWeight: fontWeight(this.state.selectedItem, category, i),
                          cursor: "pointer", // Add pointer cursor to make it look clickable
                        }}
                        onClick={() => this.handleClick(category)}
                      >
                        {this.capitalizeFirst(category)}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <div id="termsCondition" className="allergen" style={{ ...styles.aTextDivStyle, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: "8px", height: "940px", overflow: "auto", scrollBehavior: "smooth"}}>
                {this.state.allergenInformationList.map((item, index) => {
                  const { id, type, attributes } = item;
                  const { category, description, image } = attributes;
                  return (
                    <div key={id} id={category.replace(/&/g, '').split(" ").join("")}>
                      <h2 style={{ fontFamily: "Barmeno", fontSize: 24, fontWeight: 700 }}>{this.capitalizeFirst(category)}</h2>
                      <div style={styles.aListStyling} dangerouslySetInnerHTML={{ __html: description }} />
                      {image && (
                        <img src={image.url} alt={image.file_name} style={{width: "auto", height: "auto", maxWidth: "100%"}} />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  aAnchorStyle: {
    marginBottom: "0px",
    textDecorationLine: "none",
    color: "#313131",
    display: "flex",
    fontSize: 18,
    fontFamily: 'Barmeno',
    fontWeight: "700",
    padding: "6px 16px 0px 16px"
  },
  aMainDivStyle: {
    display: "flex",
    padding: "0 10px",
    width: "100%"
  },
  aListItemStyle: {
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftColor: "#BFBFBF",
    borderStyle: "solid",
    paddingLeft: "6px",
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingRight: "0px",
    fontFamily:"Barmeno",
  },
  aListStyling: {
    fontFamily: "Barmeno",
    fontSize: 18,
    fontWeight: "500",
  },
  aTextDivStyle: {
    width: "72.5%",
    height: "max-content",
    padding: "20px",
    marginBottom: "100px",
    position: "relative", 
    marginLeft: "40px", 
    backgroundColor: "white", 
    marginRight: "40px",
    marginTop: "15px",
  },
  aBottomImage: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },
  aSpinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
// Customizable Area End
