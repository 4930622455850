import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
export interface ResponseObject{
  errors: any;
  message: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  loggedInUser?: (loggeIn: boolean) => {}
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  currentPassword:string;
  newPassword:string;
  confirmNewPassword:string;
  enablePasswordField: boolean;
  enableNewPasswordField: boolean;
  enableConfirmPasswordField:boolean;
  logoutPopup:boolean;
  deletePassword: string;
  invalidPassword:string;
  passwordChangeSuccess: boolean;
  deleteSuccess:boolean;
  activeOrderPopup:boolean
  deleteAccountPopup:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteAccountPassword: string = "";
  deleteAccountConfirm: string ="";
  changePasswordMessage: string="";
  checkBalanceAndGiftCardApiCallId:string="";
  token: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      this.deleteAccountPassword,
      this.deleteAccountConfirm,
      this.changePasswordMessage,
      this.checkBalanceAndGiftCardApiCallId,
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      currentPassword:"",
      newPassword:"",
      confirmNewPassword:"", 
      enablePasswordField: true,  
      enableNewPasswordField: true,
      enableConfirmPasswordField:true,
      logoutPopup: false,  
      deletePassword:"",
      invalidPassword:"",
      passwordChangeSuccess: false,
      deleteSuccess: false,
      activeOrderPopup:false,
      deleteAccountPopup:true,
  // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
     if(apiRequestCallId === this.deleteAccountPassword){
      this.passwordCheck(responseJson)
     }
     if(apiRequestCallId === this.deleteAccountConfirm ){
      this.navigateToSignin(responseJson)
     }
     if(apiRequestCallId === this.changePasswordMessage){
      this.passwordChangeMessage(responseJson)
     }
     if(apiRequestCallId === this.checkBalanceAndGiftCardApiCallId){
      this.setActiveGiftCardState(responseJson)
     }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount(){
    this.checkSessionStorage()
  }

  checkSessionStorage = () => {
    const token = localStorage.getItem('authToken')
    if (token) {
      this.token = token;
    }else{
      this.navigateToPages('EmailAccountLoginBlock')
    }
  };

  setActiveGiftCardState=(responseJson:any)=>{
    if(responseJson.message && responseJson.message === "You have Active Orders or Gift Card has some balance."){
      this.setState({activeOrderPopup:true, deleteAccountPopup:false})
    } else if(responseJson.message && responseJson.message === "No active orders or gift card balance remaining."){
      this.deleteAccount()
    } else{
      this.showAlert("token","token")
    }
  }

  passwordCheck=(responseJson:ResponseObject)=>{
    if(responseJson.message){
      {
        this.setState({passwordChangeSuccess:true})
        this.checkGiftCardActiveOrders()
      }
    } if(responseJson.errors){
        if (responseJson.errors[0].token){
        this.showAlert('token','expired')
        }else{
        this.setState({invalidPassword:responseJson.errors})}
    }else{
      this.parseApiCatchErrorResponse("something went wrong")
     }
  }
  passwordChangeMessage=(responseJson:ResponseObject)=>{
    if(responseJson.message==="Password has been changed Successfully"){
      this.setState({passwordChangeSuccess:true})
          localStorage.removeItem('authToken');
    } if(responseJson.message === 'Your current password is wrong'){
      this.setState({invalidPassword:responseJson.message})
    }if(responseJson.message === "Old password and new password can't be same"){
      this.setState({invalidPassword:responseJson.message})
    }if(responseJson.errors){
      if (responseJson.errors[0].token){
        this.showAlert('token','expired')
      }else{
        this.parseApiCatchErrorResponse(responseJson.errors)
      }
    }
  }
  logOut=()=>{
    this.setState({logoutPopup:true})
  }
  handleInputChange = (fieldName: keyof S, value: string | boolean): void => {
    const updatedState: Partial<S> = {
      [fieldName]: value,
    };
    this.setState(updatedState as S);
  };
  clearInputChange=()=>{
    this.setState({currentPassword:"",newPassword:"",confirmNewPassword:""})
  }
  disableSubmitButton = (): boolean => {
    const { currentPassword, newPassword, confirmNewPassword} =
      this.state;
    return (
      !currentPassword || !newPassword || !confirmNewPassword
    );
  };

  checkDeleteAccountPassword=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountPassword = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.validationCheck}${configJSON.endPoint}?password=${this.state.deletePassword}`
    );
    const token = this.token
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setDeletePassword=(password:string)=>{
    this.setState({deletePassword: password})
  }

  deleteAccount=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountConfirm = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountApi
    );
    const token = this.token
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changePassword=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordMessage = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );
    const token = localStorage.getItem("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const httpBody ={
      old_password: this.state.currentPassword,
      new_password: this.state.newPassword,
      confirmation_password: this.state.confirmNewPassword
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToChangePassword=()=>{
    this.props.navigation?.navigate('Settings2')
  }
  navigateToPages=(navigator:string)=>{
    this.props.navigation?.navigate(navigator)
  }

  navigateToSignin=(responseJson:ResponseObject)=>{
    if(responseJson.message){
      this.setState({deleteSuccess:true,deleteAccountPopup:true})
      localStorage.removeItem('authToken')
      this.props.loggedInUser && this.props.loggedInUser(false);
   }if(responseJson.errors){
    this.parseApiErrorResponse(responseJson.errors)
   }
  }
  setErrorFalse=()=>{
    this.setState({invalidPassword:''})
  }
  navigateToPage = (navigator: MessageEnum) => {
    const message: Message = new Message(
      getName(navigator)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  goToSignInMethod=()=>{
    location.pathname = "/accountlogin"
  }

  checkGiftCardActiveOrders=()=>{
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkBalanceAndGiftCardApiCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkBalanceAndGiftCard
    );
    const token = localStorage.getItem("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
