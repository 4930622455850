import React, { Component } from 'react';
import { Box, Dialog, DialogContent, Button, Link, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { deleteAddress } from './assets';

interface DeleteAddressProps {
    open:boolean;
    closePopup: ()=>void;
    deleteAddress:(id:string)=>void;
    id: string;
}

export class DeleteAddressPopup extends Component< DeleteAddressProps> {
    constructor(props:  DeleteAddressProps){
        super(props);
    }
    
  render() {
    const {open,closePopup,id} = this.props;
    return (
      <Dialog open={open} style={{marginTop:"100px"}}
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
          paddingBottom: '20px' 
        } 
      }}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="end">
            <IconButton >
              <CloseIcon onClick={closePopup} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{padding: "16px 24px", overflow: "clip"}}>
          <Box test-id ="logout-box" textAlign="center" style={{margin: "0px 20px", display:"flex", flexDirection:"column", gap:"20px", alignItems:"center", overflow: "clip"}}>
            <div>
            <img test-id ="logout-image" style={{height: '100%'}} src={deleteAddress} alt="Logout account" />
            </div>
            <div test-id ="logout-content" style={{display: 'flex',flexDirection: 'column',gap: '10px',alignItems: 'center',textAlign: 'center'}}>
                <div test-id ="logout-title" style={{fontFamily:"Barmeno", fontWeight:700, fontSize:"24px"}}>Delete Address</div>
                <div test-id ="logout-typography" style={{fontFamily:"Barmeno", fontWeight:500, fontSize:"18px",  color: "#747474"}}>Are you sure you want to delete an address from your profile?</div>
            </div>
            <div style={{ padding: "0px 0px", display: "flex", flexDirection: "row", gap: "30px", justifyContent:"center" }}  >
                <Link> <Button test-id="cancel-delete" className="cancelButton" onClick={() => {closePopup()}} id="submit" style={{fontFamily:"Barmeno"}} >
                    Cancel
                </Button></Link>
                <Link > <Button test-id="submit-delete" className={"submitButtonDet"} disabled={false} onClick={() => {this.props.deleteAddress(id)} } id="submit" style={{ fontFamily: "Barmeno" }} > 
                Delete
                </Button></Link>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

export default DeleteAddressPopup;
