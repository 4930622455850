// Customizable Area Start
export const termsConditions = require("../assets/TermsConditions.png")
export const privacyPolicy = require("../assets/PrivacyPolicy.png")
export const betterChicken = require("../assets/BetterChicken.png")
export const allergenInfo = require("../assets/AllergenInfo.png")
export const ourStory = require("../assets/OurStory.png")
export const headOffice = require("../assets/HeadOffice.png")

export const wingobg = require("../assets/wingo_bg_comp.webp")

// Customizable Area End