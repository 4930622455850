import axios from 'axios';
import {baseURL} from "../../framework/src/config"

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: baseURL, // Set your base URL
  timeout: 5000, // Set a default timeout
});

// Add request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request config before sending
    // For example, you can add headers or modify request data
    console.log('Request interceptor:', config);
    return config;
  },
  (error) => {
    // Handle request error
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful responses
    console.log('Response interceptor:', response);
    return response;
  },
  (error) => {
    // Handle response error
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
