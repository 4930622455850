import React, { ChangeEvent, Component } from 'react';
import { Box, Dialog, DialogContent, Button, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { deleteAccount, success } from './assets';

interface DeleteAccountProps {
  navigateToChangePassword: (value:string) => void
  confirmPassword: () => void
  setDeletePassword: (password: string) => void
  pwdError: string
  setError: () => void
  deleteSuccess:boolean
  deleteAccountPopup:boolean
}
interface S {
  enablePasswordField: boolean;
  currentPassword: string;
}

export class DeleteAccountPopup extends Component<DeleteAccountProps, S> {
  constructor(props: DeleteAccountProps) {
    super(props);
    this.state = {
      enablePasswordField: true,
      currentPassword: '',
    }
  }

  render() {
    return (
      <Dialog open={this.props.deleteAccountPopup} style={{ marginTop: "100px", boxSizing:"border-box" }}
        PaperProps={{
          style: {
            borderRadius: '16px',
            paddingBottom: '20px'
          }
        }} >
        <DialogContent style={{ padding: "0px" }}>
        {!this.props.deleteSuccess?
          <Box textAlign="center" style={{ display: "flex", flexDirection: "column", gap: "40px", alignItems: "center" }}>
            <img style={{ height: '150px' }} src={deleteAccount} alt="Delete account" />
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'start', textAlign: 'start' }}>
              <div style={{ fontFamily: "Barmeno", fontWeight: 700, fontSize: "24px" }}>Are you sure you want to delete your account?</div>
              <div style={{ fontFamily: "Barmeno", fontWeight: 400, fontSize: "18px", color:"#747474" }}>Deleting your account will remove all of your information from our database. This cannot be undone.</div>
            </div>
            <div className="password_field__wrapper" style={{ width: '100%', alignItems: "start" }}>
              <label style={{ fontFamily: "Barmeno", color:"#747474", fontWeight:400 }} htmlFor="currentPassword" > Confirm Your Password<span className="required">*</span> </label>
              <div style={{ display: "flex", width: "100%" }}>
                <input
                  style={{ padding:"0px 10px",borderRadius: "8px", fontFamily: "Barmeno", width: "100%", height: "56px",  border: this.props.pwdError ? "1px solid #E42328" : "1px solid #313131" }}
                  type={!this.state.enablePasswordField ? "text" : "password"}
                  id="outlined-basic" name="currentPassword" placeholder="Enter Current Password"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => { this.props.setDeletePassword(event.target.value); this.setState({ currentPassword: event.target.value }); this.props.setError() }}
                  value={this.state.currentPassword}
                />
                <IconButton className="eyeIcon-button"
                  style={{ width: "fit-content" }}
                  itemID="enablePass"
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => this.setState({ enablePasswordField: !this.state.enablePasswordField })}

                >
                  {!this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
                </IconButton></div>
              {this.props.pwdError && <div className='errors' style={{ marginTop: 75, fontFamily:"Barmeno" }}>Incorrect password. Please try again!</div>}
            </div>
            <div style={{ padding: "0px 0px", display: "flex", flexDirection: "row", gap: "30px", justifyContent: "center" }}  >
              <Button className="cancelButton" onClick={() => { this.props.navigateToChangePassword("CustomisableUserProfiles") }} id="submit" style={{ fontFamily: "Barmeno" }} >
                Cancel
              </Button>
              <Button className={"submitButtonDet"} disabled={false} onClick={() => { this.props.confirmPassword() }} id="submit" style={{ fontFamily: "Barmeno" }}  >
                Delete Account
              </Button>
            </div>
          </Box>:
          <Box textAlign="center" style={{ display: "flex", flexDirection: "column", gap: "40px", alignItems: "center" }}>
            <img style={{ height: '250px' }} src={success} alt="Delete account" />
            <div style={{fontFamily:"barmeno", fontSize:"24px", fontWeight:700}}>
            Your account has been deleted successfully!</div>
              <Button style={{fontFamily: "Barmeno" ,borderRadius: "8px ",padding: "16px 0px",fontSize: "16px",fontWeight: 700,width: "100%",height:" 54px",color: "#FFFFFF",backgroundColor: "#E11B23"}} onClick={() => {this.props.navigateToChangePassword('EmailAccountRegistration')}} id="submit" >
              BACK TO SIGN UP
              </Button>
          </Box>}
        </DialogContent>
      </Dialog>
    );
  }
}

export default DeleteAccountPopup;
