import React from "react";
import { View, Button, StyleSheet } from "react-native";
import NavigationMenu from "./../../blocks/navigationmenu/src/NavigationMenu.web";
import { withRouter } from "react-router-dom";

const TopNav = ({ history, cls, isLoggedIn }) => {
  return (
    <NavigationMenu cls={cls} history={history} isLoggedIn={isLoggedIn} id="" />
  );
};

const styles = StyleSheet.create({
  main: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    backgroundColor: "#cccccc"
  }
});

export default withRouter(TopNav);
