import React from 'react';
import { Drawer, List, ListItem, ListItemText, Collapse, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import './DrawerMenu.css';

const useStyles = makeStyles((theme) => ({
      drawerPaper: {
      top: '115px', // Height of the header
      width: "320px !important",
      height: `calc(100% - 115px)`
    },
    drawerContainer: {
        overflow: 'auto',
      },
  }));

const DrawerMenu: React.FC<{ open: boolean; onClose: () => void; handlePathname:()=>string; openLogin: (navigator: string) => void; }> = ({ open, onClose, handlePathname, openLogin }) => {
    const classes = useStyles();
    const [openSubMenu, setOpenSubMenu] = React.useState<boolean>(false);
    
      const handleSubMenuClick = () => {
        setOpenSubMenu(!openSubMenu);
      };

    return (
        <Drawer anchor="right" 
        variant="temporary"
        elevation={5}
        ModalProps={{
          keepMounted: false,
        }} 
        open={open} 
        onClose={onClose} classes={{ paper: classes.drawerPaper}}>
        <div className={classes.drawerContainer}>
        <List>
        <ListItem className='item_wrapper' onClick={() => openLogin("/Menu")}  button >
            <ListItemText className={handlePathname() == "/Menu" ? 'mob-view' :"item_text"} primary="Menu" />
          </ListItem> 
          <ListItem button className='item_wrapper' onClick={() => openLogin("/Deals")}>
            <ListItemText className={handlePathname() == "/Deals" ? 'mob-view' :"item_text"} primary="Deals" />
          </ListItem> 
          <ListItem button className='item_wrapper' onClick={() => openLogin("/Restaurant")}>
            <ListItemText className={handlePathname() == "/Restaurant" ? 'mob-view' :"item_text"} primary="Restaurants" />
          </ListItem> 
          <ListItem button className={`${openSubMenu && 'mg_btm'} item_wrapper`} onClick={handleSubMenuClick}>
            <ListItemText className={handlePathname() == "/WingoRewards" ? 'mob-view' :"item_text"} primary="Wingo's Rewards" />
            {openSubMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className='sub_menu_list'>
              <ListItem button className='sub_menu_item_wrapper' style={{borderTop: "1px solid #F5F5F5", borderRadius: "8px 8px 0 0"}} onClick={() => openLogin("/WingoRewards")}>
                <ListItemText className="sub_menu_item_text" primary="Join Now" />
              </ListItem>
              <ListItem button className='sub_menu_item_wrapper' style={{borderRadius: "0 0 8px 8px", borderBottom: "1px solid #9EBDA3", backgroundColor: "#9EBDA3"}}>
                <ListItemText className="sub_menu_item_text" primary="How it works" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={() => openLogin("/AboutUs")} className='item_wrapper'>
            <ListItemText className={handlePathname() == "/AboutUs" ? 'mob-view' :"item_text"} primary="About Us" />
          </ListItem>
          <ListItem button onClick={() => openLogin("/Faqs")} className='item_wrapper'>
            <ListItemText className={handlePathname() == "/Faqs" ? 'mob-view' :"item_text"} primary="Help" />
          </ListItem>
          <ListItem button className='item_wrapper' onClick={() => openLogin("/Menu")}>
            <ListItemText className="item_text" primary="WIngo’s Cart" />
          </ListItem>
          <ListItem button className='item_wrapper' onClick={() => openLogin("/Notifications")}>
            <ListItemText className="item_text" primary="Notifications" />
          </ListItem>
        </List>
        </div>
      </Drawer>
    )

}

export default DrawerMenu;