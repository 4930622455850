import React from 'react';

interface ImageProps {
  imageUrl: string;
  alt: string;
  className?: string;
  style?:object;
  id?:string;
  loading?:"eager"|"lazy"|undefined;
}

const ImageComponent: React.FC<ImageProps> = ({ imageUrl, alt, className=" ", style={}, id=""}) => {

  return (
    <img src={imageUrl} alt={alt} className={className} style={style} id={id} />
  );
};

export default ImageComponent;