import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";
import { betterChicken, wingobg } from "./assets";
import {borderLeftColor, fontWeight} from "../../../components/src/utils";
import "./styles.css";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class BetterChicken extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
        <>
          <div style={{ alignItems: "center", display: "flex", flexDirection: "column", width: "100%", backgroundImage: `url(${wingobg})`, backgroundSize: "contain"}}>
            <div style={{ position: "relative", width: "100%" }}>
              <img src={betterChicken} alt="react logo" style={styles.bBottomImage} />
            </div>
            <div style={styles.bMainDivStyle}>
              <ul style={{ listStyleType: "none", display: "block", position: "relative", height: "max-content", width: "19.2%", padding: "6px 10px 6px 12px", marginLeft: "40px", backgroundColor: "white", borderRadius: "8px" }}>
                {Object.keys(this.state.betterChickenList).map((item, i) => {
                  return (
                    <li key={`${item}${i}`} style={{ ...styles.bListItemStyle, borderLeftColor: borderLeftColor(this.state.selectedItem, item, i)}}>
                      <span
                        key={item}
                        style={{
                          ...styles.bAnchorStyle,
                          fontWeight: fontWeight(this.state.selectedItem, item, i),
                          cursor: "pointer", // Add pointer cursor to make it look clickable
                        }}
                        onClick={() => this.handleClick(item)}
                      >
                        {this.capitalizeFirst(item)}
                      </span>
                    </li>
                  );
                })}
              </ul>
              <div id="termsCondition" style={{ ...styles.bTextDivStyle, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: "8px" }}>
                {(Object.keys(this.state.betterChickenList) || []).map((keyData: any, index: number) => <div key={index} id={keyData.replace(/&/g, '').split(" ").join("")}>
                  <h2 style={{ fontFamily: "Barmeno", fontSize: 24, fontWeight: 700 }} key={keyData}>{this.capitalizeFirst(keyData)}</h2>
                  {(this.state?.betterChickenList[keyData] || []).map((itemObj: any, index1: number) => <>
                    <div style={styles.bListStyling} key={`${keyData}${itemObj.id}${index}${index1}`} dangerouslySetInnerHTML={{ __html: itemObj.description }} />
                  </>)}
                </div>)}
              </div>
            </div>
          </div>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  bAnchorStyle: {
    marginBottom: "0px",
    textDecorationLine: "none",
    color: "#313131",
    display: "flex",
    fontSize: 18,
    fontFamily: 'Barmeno',
    fontWeight: "700",
    padding: "6px 16px 0px 16px"
  },
  bMainDivStyle: {
    display: "flex",
    padding: "0 10px",
    width: "100%"
  },
  bListItemStyle: {
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftColor: "#BFBFBF",
    borderStyle: "solid",
    paddingLeft: "6px",
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingRight: "0px",
    fontFamily:"Barmeno",
  },
  bListStyling: {
    fontFamily: "Barmeno",
    fontSize: 18,
    fontWeight: "500",
  },
  bTextDivStyle: {
    width: "72.5%",
    padding: "20px",
    marginBottom: "100px",
    position: "relative", 
    marginLeft: "40px", 
    backgroundColor: "white", 
    marginRight: "40px",
    marginTop: "15px",
  },
  bBottomImage: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },
  bSpinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
// Customizable Area End
