import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  View,
  ActivityIndicator,
} from "react-native";
import { termsConditions, wingobg } from "./assets";
// import "../../termsconditions/src/TermsCoditions.css";
import {borderLeftColor, fontWeight} from "../../../components/src/utils";
import "./styles.css";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedItem } = this.state;
    return this.state.isLoading ? (
      <View style={styles.spinnerContainer}>
        <ActivityIndicator color="#000" size={30} />
      </View>
    ) : true ? (
      <>
        <div style={{ alignItems: "center", display: "flex", flexDirection: "column", width: "100%", backgroundImage: `url(${wingobg})`, backgroundSize: "contain" }}>
          <div style={{ position: "relative", width: "100%" }}>
            <img src={termsConditions} alt="react logo" style={styles.bottomImage} />
          </div>
          <div style={styles.mainDivStyle}>
            <ul style={{ listStyleType: "none", display: "block", position: "relative", height: "max-content", width: "19.2%", padding: "6px 10px 6px 12px", marginLeft: "40px", backgroundColor: "white", borderRadius: "8px" }}>
                {Object.keys(this.state.termsCondsList).map((item, i) => {
                  return (
                    <li key={`${item}${i}`} style={{ ...styles.listItemStyle, borderLeftColor: borderLeftColor(this.state.selectedItem, item, i)}}>
                      <span
                        key={item}
                        style={{
                          ...styles.anchorstyle,
                          fontWeight: fontWeight(this.state.selectedItem, item, i),
                          cursor: "pointer", // Add pointer cursor to make it look clickable
                        }}
                        onClick={() => this.handleClick(item)}
                      >
                        {this.capitalizeFirst(item)}
                      </span>
                    </li>
                  );
                })}

            </ul>
            <div id="termsCondition" className="terms" style={{ ...styles.textDivStyle, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', borderRadius: "8px", height: "320px", overflow: "auto", scrollBehavior: "smooth" }}>
              {(Object.keys(this.state.termsCondsList) || []).map((keyData: any, index: number) => <div id={keyData.replace(/&/g, '').split(" ").join("")} key={keyData}>
                <h2 style={{ fontFamily: "Barmeno", fontSize: 24, fontWeight: 700 }} key={keyData}>{this.capitalizeFirst(keyData)}</h2>

                {(this.state?.termsCondsList[keyData] || []).map((itemObj: any, index1: number) => <>
                  <div style={styles.liststyling} key={`${keyData}${itemObj.id}${index}${index1}`} dangerouslySetInnerHTML={{ __html: itemObj.description }} />
                </>)}
              </div>)}
            </div>
          </div>
        </div>
      </>
    ) : (
      <></>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
  },
  anchorstyle: {
    marginBottom: "0px",
    textDecorationLine: "none",
    color: "#313131",
    display: "flex",
    fontSize: 18,
    fontFamily: 'Barmeno',
    fontWeight: "700",
    padding: "6px 16px 0px 16px"
  },
  mainDivStyle: {
    display: "flex",
    padding: "0 10px",
    width: "100%"
  },
  listItemStyle: {
    borderLeftWidth: 1,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftColor: "#BFBFBF",
    borderStyle: "solid",
    paddingLeft: "6px",
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingRight: "0px",
    fontFamily:"Barmeno",
  },
  liststyling: {
    fontFamily: "Barmeno",
    fontSize: 18,
    fontWeight: "500",
  },
  textDivStyle: {
    width: "72.5%",
    padding: "20px",
    marginBottom: "100px",
    position: "relative", 
    marginLeft: "40px", 
    backgroundColor: "white", 
    marginRight: "40px",
    marginTop: "15px",
  },
  bottomImage: {
    position: "relative",
    zIndex: 1,
    width: "100%",
  },
  spinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    flex: 1,
    padding: 20,
    fontWeight: "600",
    fontSize: 22,
  },
  termsCondsTitle: {
    fontWeight: "600",
    marginBottom: 20,
    fontSize: 22,
  },
  scrollView: {
    height: "100%",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: 40,
    marginRight: 20,
  },
  termsCondsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    padding: 20,
  },
  button: {
    backgroundColor: "blue",
    marginLeft: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end",
  },
  buttonLabel: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: 20,
  },

});
// Customizable Area End
