import React from "react";

// Customizable Area Start
import TopNavigation from "./TopNavigation";
import DrawerMenu from "./DrawerMenu";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div className={this.props.cls} style={{position: "fixed", top: "0", left: "0", width: "100%", zIndex: "1000", backgroundImage: "inherit", 
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundColor: "#f8f8f8"
    }}>
      <TopNavigation id={this.props.id} navigation={this.props.navigation} cartCount={this.state.cartCount} notificationCount={this.state.notificationCount} isLoggedIn={this.props.isLoggedIn} handlePathname={this.handlePathname} drawerOpen={this.state.drawerOpen} onMenuClick={this.handleMenuClick} openLogin={this.openLoginPage} />
      <DrawerMenu handlePathname={this.handlePathname} open={this.state.drawerOpen} onClose={this.handleDrawerClose} openLogin={this.openLoginPage}  />
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
