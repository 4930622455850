import React from "react";
// Customizable Area Start
import {
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  FormLabel
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./Profile.css"
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import PersonalDetail from "./PersonalDetail.web";
import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import LogoutPopup from "../../settings2/src/LogoutPopup.web";
import { verifiedImage } from "./assets";
import UpdateDetailsPopup from "./UpdateDetailsPopup.web";
import EmailSentPopup from "../../forgot-password/src/EmailSentPopup.web";
import SuccessPopup from "./SuccessPopup.web";
import UpdateMobileDetailsPopup from "./UpdateMobilePopup.web";
import OtpSentPopup from "../../forgot-password/src/OtpSentPopup.web";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Alert from "@material-ui/lab/Alert";
import { enGB } from 'date-fns/locale';
import Profile from "../../../components/src/Profile";

registerLocale('en-GB', enGB);


// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import CustomisableUserProfilesController, {
  Props,
  IField
} from "./CustomisableUserProfilesController";
// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    )
      return "number";
    return "text";
  };

  renderField = (field: IField, index: number) => {
    if (
      field.field_type === "text" ||
      field.field_type === "string" ||
      field.field_type === "file" ||
      field.field_type === "textarea" ||
      field.field_type === "number" ||
      field.field_type === "integer" ||
      field.field_type === "float"
    )
      return (
        <div key={index + "renderField"}>
          <TextField
            data-test-id={field.name}
            label={field.title}
            multiline={field.field_type === "textarea"}
            value={this.state.profile[field.name]}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.value, field.field_type)
            }
            disabled={!field.is_enable}
            required={field.is_required}
            type={this.getFieldType(field)}
            onKeyPress={(event) => this.onKeyPress(event, field)}
            InputLabelProps={{
              shrink: true
            }}
            // defaultValue='Enter Value'
          />
        </div>
      );

    if (field.field_type === "date" || field.field_type === "datetime")
      return (
        <div key={index + "renderField"}>
          <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
            {field.title + (field.is_required ? "*" : "")}
          </div>
          <div style={{ marginBottom: "5px", marginTop: "5px" }}>
            <DatePicker
              data-test-id={field.name}
              selected={
                new Date(this.state.profile[field.name] as string).toString() !== "Invalid Date"
                  ? new Date(this.state.profile[field.name] as string)
                  : null
              }
              onChange={(date:Date) => this.changeFormValue(field.name, date, field.field_type)}
              disabled={!field.is_enable}
              required={field.is_required}
            />
          </div>
        </div>
      );

    if (field.field_type === "checkbox")
      return (
        <div key={index + "renderField"}>
          <Checkbox
            data-test-id={field.name}
            disabled={!field.is_enable}
            checked={this.state.profile[field.name] as boolean}
            onChange={(event) =>
              this.changeFormValue(field.name, event.target.checked, field.field_type)
            }
            color='primary'
          />
          {field.title}
        </div>
      );
    if (field.field_type === "boolean")
      return (
        <div key={index + "renderField"}>
          <FormControl>
            <FormLabel>{field.title}</FormLabel>
            <RadioGroup
              row
              data-test-id={field.name}
              onChange={(event) => {
                this.changeFormValue(field.name, event.target.value, field.field_type);
              }}
              value={
                this.state.profile[field.name] === undefined
                  ? undefined
                  : String(this.state.profile[field.name])
              }
            >
              <FormControlLabel value={"true"} control={<Radio />} label='True' />
              <FormControlLabel value={"false"} control={<Radio />} label='False' />
            </RadioGroup>
          </FormControl>
        </div>
      );
  };

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Profile navigateToPages={this.navigateToPage}>
        <div className="main-panel"> 
          {this.state.personalDetailsSavedSuccess && <Alert iconMapping={{
          success: <CheckCircleRoundedIcon fontSize="inherit" />,
        }} style={{top:"200px",color: "#313131",backgroundColor:"white", width:"fit-content", boxShadow:"4px 4px 12px #bbb", position:"absolute", right:"56px", zIndex:99 }} >
          Personal details saved successfully
        </Alert>}
          {this.state.editPersonalDetails?
          <div className="personal-details-edit">
            <div className="detail-edit-header"> 
            <Button  test-id="back" onClick={()=>{this.setState({editPersonalDetails:false})}} style={{height:"56px",border:"none", color:"#313131", padding:"0px 12px !important", textTransform:"none"}}><ArrowBackRoundedIcon style={{height:"34px", marginRight:"12px", fontSize:"32px"}}/></Button>
            Edit Personal Details
            </div>
            <PersonalDetail handleInputChange={this.handleInputChange}
            fieldDetails ={{firstName: this.state.editFirstName, lastName: this.state.editLastName, email: this.state.editEmail,
            phoneNumber:this.state.editPhone, dob:this.state.editDOB, gender: this.state.gender} }
            disableSubmitButton={this.disableSubmitButton} cancelButton={this.cancelForm} submitButton={this.submitForm} />
            <div>
            </div>
            
            </div> : 
            <div className="personal-details">
            <div className="detail-header">Personal Details
            <Button test-id="edit" onClick={()=>{this.setState({editPersonalDetails:true})}} style={{height:"39px",border:"1px solid #E11B22", borderRadius:"8px", color:"#E11B22", padding:"0px 12px !important", textTransform:"none", width:"89px"}}><EditRoundedIcon style={{marginRight:"6px"}}/> Edit</Button></div>
            <div className="details-content">
              <div className="details-content-data"><div className="details-content-key">First Name</div> <div className="details-content-value">{this.state.first_name}</div> </div>
              <div className="details-content-data"><div className="details-content-key">Last Name</div> <div className="details-content-value">{this.state.lastName}</div> </div>
              <div className="details-content-data"><div className="details-content-key">E-mail Address</div> <div className="updateDiv" ><div className="details-content-value">{this.state.email} 
              <div style={{marginTop:"8px"}}><div className="update" onClick={()=>this.setState({updatePopup:true})} >Update</div></div> </div>
              <div > { this.state.email &&  <div style={{fontSize:"14px", fontFamily:"Barmeno", fontWeight:700, display:"flex", alignItems:"center", borderRadius:"40px", padding:"0px 8px", width:"fit-content", color:"#059669", backgroundColor:"#D1FAE5"}}> 
              <img src={verifiedImage} style={{marginRight:"8px", height:"12px"}}/> Verified</div>} </div></div></div>
              <div className="details-content-data"><div className="details-content-key">Mobile Number</div> <div className="updateDiv" ><div className="details-content-value">{this.state.phoneNumber}
              <div style={{marginTop:"8px"}}><div className="update" onClick={()=>this.setState({updateMobilePopup:true})} >Update</div></div> </div>
              <div > { this.state.phoneNumber && <div style={{fontSize:"14px", fontFamily:"Barmeno", fontWeight:700, display:"flex", alignItems:"center", borderRadius:"40px", padding:"0px 8px", width:"fit-content", color:"#059669", backgroundColor:"#D1FAE5"}}> 
              <img src={verifiedImage} style={{marginRight:"8px", height:"12px"}}/> Verified</div>} </div></div></div>
              <div className="details-content-data"><div className="details-content-key">DOB</div> <div className="details-content-value">{this.state.dateOfBirth}</div>  </div>
              <div className="details-content-data"><div className="details-content-key">Gender</div> <div className="details-content-value">{this.state.gender}</div>  </div>
            </div>
            </div>}
            </div>
        </Profile>
          <LogoutPopup open={this.state.logoutPopup}/>
          <UpdateDetailsPopup  navigateToPages={this.closePopup} removeError={this.removeError} updatePopup={this.state.updatePopup} emailError={this.state.emailError} updateEmailAddress={this.updateEmailAddress}/>
          <UpdateMobileDetailsPopup navigateToPages={this.closePopup} updatePopup={this.state.updateMobilePopup} updatePhoneNumber={this.updatePhoneNumber} phoneNumberUpdateError={this.state.phoneNumberUpdateError} changeErrorPhoneNumber={this.changeErrorPhoneNumber}/>
          <EmailSentPopup open={this.state.emailPopup} onClose={this.emailClosePopup} email={this.state.updatedEmail} resendClick={this.resendEmail} />
          <OtpSentPopup resendClick={this.resendClick} length={4} onChange={() => {}} inValidOtp={this.state.inValidOtp} open={this.state.openOtpPopup} onClose={this.handleCloseOtpPopup} phone={this.state.email||""} confirmOtp={this.confirmMobile}/>
          <SuccessPopup onClose={this.closeSuccessPopup} successOpen={this.state.successOpen} valueUpdated={this.state.valueUpdated} navigateToPages={this.navigateToPages}/>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
