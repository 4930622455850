import React, { ChangeEvent } from "react";

import {
  Button, IconButton, Link,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import * as Yup from "yup";
import "./Settings.css"
import Alert from "@material-ui/lab/Alert"
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {Visibility, VisibilityOff} from "@material-ui/icons";
import Profile from "../../../components/src/Profile";
import RestPasswordPopup from "../../../components/src/ResetPasswordPopup.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { Field, Form, Formik } from "formik";
import LogoutPopup from "./LogoutPopup.web";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      currentPassword: Yup.string()
      .required('Current Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    'Password must include one number, one lowercase letter, and one uppercase letter'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Password confirmation does not match. Please try again!')
      .required('Confirm New Password is required'),
    });
};
renderErrorMessage = (error:undefined|string, touched:undefined|boolean ) => {
  if (error && touched) {
      return (
          <div style={{ fontFamily:"Barmeno" }} className="errors-profile">
              {error}
          </div>
      );
  }
  return null;
};
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Profile navigateToPages={this.navigateToPage}>
        <div className="main-panel"> 
            <div className="personal-details">
            <div className="detail-header">Change your Password
            </div>
            <div className="password-content">
            <Formik
                initialValues={{
                    currentPassword: "",
                    newPassword: "",
                    confirmNewPassword:""
                }}
                validationSchema={this.userSchema}
                onSubmit={(values) => {
                    // same shape as initial values
                }}
                data-test-id="formikform"
            >
              {({ errors, touched, handleChange }) => (
          <Form id="Form1" style={{display:"flex", flexDirection:"column", gap:"32px", alignItems: 'center'}} >
              <div className="password_field__wrapper">
                <label style={{ fontFamily: "Barmeno", color:"#747474", fontWeight:600, fontSize:"16px" }} htmlFor="currentPassword" > Enter Current Password<span className="required">*</span> </label>
                  <div><Field style={{ fontFamily: "Barmeno", width:"350px" }} className={ errors.currentPassword && touched.currentPassword
                    ? "error-input" : "contact_form-field" }  type={!this.state.enablePasswordField ? "text" : "password"}  id="currentPassword" name="currentPassword" placeholder="Enter Enter Current Password" 
                    onChange={(event: ChangeEvent<HTMLInputElement>) => { this.handleInputChange( "currentPassword", event.target.value);
                                handleChange(event); this.setState({invalidPassword: ''}) }}
                    value={this.state.currentPassword} />
                    <IconButton className="eyeIcon-button"
                                style={{width:"fit-content"}}
                                itemID="enablePass"
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enablePasswordField: !this.state.enablePasswordField })}

                            >
                                {!this.state.enablePasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton></div>
                    {this.state.invalidPassword? <div style={{ fontFamily:"Barmeno" }} className="errors-profile">
              {this.state.invalidPassword}
          </div>:this.renderErrorMessage(errors.currentPassword,touched.currentPassword)}
            </div>
              <div className="password_field__wrapper">
                <label style={{ fontFamily: "Barmeno", color:"#747474", fontWeight:600, fontSize:"16px" }} htmlFor="newPassword" > Enter New Password<span className="required">*</span> </label>
                  <div>
                  <Field style={{ fontFamily: "Barmeno", width:"350px" }} className={ errors.newPassword && touched.newPassword
                    ? "error-input" : "contact_form-field" }  type={!this.state.enableNewPasswordField ? "text" : "password"}  id="newPassword" name="newPassword" placeholder="Enter New Password" 
                    onChange={(event: ChangeEvent<HTMLInputElement>) => { this.handleInputChange( "newPassword", event.target.value);
                                handleChange(event);this.setState({invalidPassword: ''}) }}
                    value={this.state.newPassword} />
                    <IconButton className="eyeIcon-button"
                                style={{width:"fit-content"}}
                                itemID="enablePass"
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enableNewPasswordField: !this.state.enableNewPasswordField })}

                            >
                                {!this.state.enableNewPasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            </div>
                    {this.renderErrorMessage(errors.newPassword,touched.newPassword)}
                </div>
              <div className="password_field__wrapper">
                <label style={{ fontFamily: "Barmeno",color:"#747474", fontWeight:600, fontSize:"16px" }} htmlFor="confirmNewPassword" > Confirm New Password<span className="required">*</span> </label>
                  <div>
                  <Field style={{ fontFamily: "Barmeno", width:"350px" }} className={ errors.confirmNewPassword && touched.confirmNewPassword
                    ? "error-input" : "contact_form-field" }  type={!this.state.enableConfirmPasswordField ? "text" : "password"}  id="confirmNewPassword" name="confirmNewPassword" placeholder="Confirm New Password" 
                    onChange={(event: ChangeEvent<HTMLInputElement>) => { this.handleInputChange( "confirmNewPassword", event.target.value);
                                handleChange(event); }}
                    value={this.state.confirmNewPassword} />
                    <IconButton className="eyeIcon-button"
                                style={{width:"fit-content"}}
                                itemID="enablePass"
                                aria-label="toggle password visibility"
                                edge="end"
                                onClick={() => this.setState({ enableConfirmPasswordField: !this.state.enableConfirmPasswordField })}

                            >
                                {!this.state.enableConfirmPasswordField ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                    </div>
                    {this.renderErrorMessage(errors.confirmNewPassword,touched.confirmNewPassword)}
                </div>
                <div
                            style={{
                                padding: "24px 0px",
                                display: "flex",
                                flexDirection: "row",
                                gap: "30px",
                                justifyContent:"center"
                            }}
                        >
                            <Button
                                className="cancelButton"
                                onClick={this.clearInputChange}
                                id="submit"
                                style={{ fontFamily: "Barmeno" }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className={
                                    this.state.invalidPassword|| this.disableSubmitButton() ||
                                        [
                                            "currentPassword",
                                            "newPassword",
                                            "confirmNewPassword"
                                        ].some(
                                            (field) => errors[field as keyof typeof errors]
                                        )
                                        ? "submitButtonDisabledDet"
                                        : "submitButtonDet"
                                }
                                disabled={
                                   this.disableSubmitButton() ||
                                    [
                                        "currentPassword",
                                        "newPassword",
                                        "confirmNewPassword"
                                    ].some(
                                        (field) => errors[field as keyof typeof errors]
                                    ) || !(!this.state.invalidPassword)
                                }
                                onClick={this.changePassword
                                }
                                id="submit"
                                style={{ fontFamily: "Barmeno" }}
                            >
                                Save
                            </Button>
                        </div>
          </Form>
              )}
            </Formik>
            </div>
            </div>
            </div>
        </Profile>
        <RestPasswordPopup open={this.state.passwordChangeSuccess} onClose={this.goToSignInMethod} button={'BACK TO SIGN IN'}/>
          <LogoutPopup open={this.state.logoutPopup}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
