import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "../../../components/src/utils";
import { CardDetailsEdit } from "./ManagePaymentController";

export interface CardDetails{
  id: string;
  object: string;
  address_city: null;
  address_country: null;
  address_line1: null;
  address_line1_check: null;
  address_line2: null;
  address_state: null;
  address_zip: null;
  address_zip_check: null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  name: null;
  tokenization_method: null;
  wallet: null
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  changeOpenAddPaymentPopup:()=>void
  token:string|null
  getCardList: ()=>void
  card_details_edit?:CardDetailsEdit
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardHolderName: string;
  cardNumber: string;
  cardExpiry: string;
  cardCvc:string;
  expiryError:string;
  cardAddingErrors: string;
  maxLengthCardNumber: number;
  cvcLength: number;
  cardBrand:string | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Paymentadmin2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  saveCrdDetailsApiCallId: string = ""
  updateCardDetailsApiCallId: string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardHolderName: this.props.card_details_edit?`${this.props.card_details_edit.cardHolder}`:"",
      cardNumber: this.props.card_details_edit?`**** **** **** ${this.props.card_details_edit.last4}`:"",
      cardExpiry: this.reverseConversionToDateString()||"",
      cardCvc: this.props.card_details_edit?"***":"",
      expiryError: "",
      cardAddingErrors:"",
      maxLengthCardNumber:19,
      cvcLength:3,
      cardBrand:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if(getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.saveCrdDetailsApiCallId){
        this.setCardDetails(responseJson)
      } else if(apiRequestCallId === this.updateCardDetailsApiCallId){
        this.props.getCardList()
        this.props.changeOpenAddPaymentPopup()
      }
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleCardDetailsInput:React.ChangeEventHandler<HTMLInputElement>=(event)=>{
    switch (event.target.name){
      case 'cardNumber':
        const {cardNumber,length, cardBrand} = formatCreditCardNumber(event.target.value)
        this.setState({cardNumber: cardNumber, cardAddingErrors:"",maxLengthCardNumber:length, cardBrand: cardBrand})
        if(length>18){
          this.setState({cvcLength:3})
        }else if(cardBrand !=='diners-club'){
          this.setState({cvcLength:4})
        }
        break;
      case 'cardExpiry':
        const {expiry,error} = formatExpirationDate(event.target.value)
        this.setState({cardExpiry: expiry, expiryError: error})
        break;
      case 'cardCvc':
        this.setState({cardCvc: formatCVC(event.target.value,this.state.cvcLength)})
        break;
      default:
        this.setState({cardHolderName: event.target.value})
    }
  }

cardLengthValidation =()=>{
  return (this.state.cardBrand === 'diners-club'?(this.state.cardNumber.length !==17 && this.state.cardNumber.length !==19 )
  :this.state.cardBrand ==='unionpay'? (this.state.cardNumber.length !==19 && this.state.cardNumber.length !==23  )
  :this.state.cardNumber.length < this.state.maxLengthCardNumber)
}
  submitDisabled=()=>{
    return !!this.state.expiryError || this.state.cardCvc.length !== this.state.cvcLength || this.cardLengthValidation() || this.state.cardExpiry.length<5 || !!this.state.cardAddingErrors || !this.state.cardHolderName
  }

  conversionToMontAndYear=(dateString:string)=>{
    const [month, year] = dateString.split('/').map(Number);
    const fullYear = 2000 + year;

  return {
    exp_month: `${month}`,
    exp_year: `${fullYear}`
  };
  }

  reverseConversionToDateString = () => {
    if(this.props.card_details_edit){
    const month = this.props.card_details_edit.exp_month.toString().padStart(2, '0');
    const year = (this.props.card_details_edit.exp_year % 100).toString().padStart(2, '0');
    return `${month}/${year}`;}else{
      return undefined
    }
  }

  properCardNumber = (cardNumber:string)=>{
    return cardNumber.replace(/\s/g, '');
    }

  saveCardDetails=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    };
    const card_number = this.properCardNumber(this.state.cardNumber)
    const {exp_month,exp_year} = this.conversionToMontAndYear(this.state.cardExpiry)
    
    const httpBody = {
      payment_method: {
      card_holder_name: this.state.cardHolderName,
      card_number ,
      exp_month,
      exp_year,
      cvc: this.state.cardCvc
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveCrdDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveCardDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setCardDetails=(responseJson:{card_details?:CardDetails,error?:string})=>{
    if(responseJson.card_details){
      this.props.getCardList()
      this.props.changeOpenAddPaymentPopup()
    }else if(responseJson.error){
      if(responseJson.error === "Your card's expiration month is invalid."){
        this.setState({expiryError: responseJson.error})
      }else if(responseJson.error ==="Your card number is incorrect."){
        this.setState({cardAddingErrors: responseJson.error})
      } else if(responseJson.error ==="Your card was declined." || responseJson.error==="Your card has insufficient funds."){
        this.setState({cardAddingErrors: responseJson.error})
      } else{
        this.setState({cardAddingErrors: responseJson.error})
      }
    }  
  }

  updateCardDetails=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.props.token,
    };
    const {exp_month,exp_year} = this.conversionToMontAndYear(this.state.cardExpiry)
    
    const httpBody = {
      exp_month,
      exp_year,
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCardDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteCardApiEndpoint}${this.props.card_details_edit?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
