export const appleImg = require("./apple_img.png");
export const google_logo = require("./GoogleLogo.png");
export const success = require("./success.png");
export const image_cross = require("./image_cross.svg");
export const unsubscribe = require("./unsubscribe.svg");
export const failure = require("./failure.png");
export const signin_tofav = require("./signin_tofav.svg");
export const tokenExpireImage = require("./tokenExpired.png")
export const noItemsInCartImage = require("../Popups/assets/NoItemsInCart.png")
export const subscribed_img = require("./../SocialLogin/subscribed_img.svg")
export const junk = require("./../assets/junk.svg");
export const pickup = require("./../assets/pickup.svg");
export const invoice_btn = require("./../assets/invoice_btn.svg");
export const delivery = require("./../assets/delivery.svg");
