import React from 'react'
import {americanExpress, diners_club, discover, jcbImage, masterCard1, unionPay, visa} from "../../../blocks/inappgifting/src/assets"
import { googlePay } from '../../../blocks/shoppingcart/src/assets';

interface Props {
    brand:string;
}
const GetCardBrand:React.FC<Props>=({brand})=>{
    const cardLogos:{[key: string]: string } = {
        mastercard: masterCard1,
        visa: visa,
        amex: americanExpress,
        unionpay: unionPay,
        jcb: jcbImage,
        diners: diners_club,
        discover: discover,
        gpay: googlePay
      };
    const logoSrc = cardLogos[brand.toLowerCase()];
  
    if (!logoSrc) {
      return <span>Card type not supported</span>;
    }
  
    return <img style={{width:"32px", height:"32px"}} src={logoSrc} alt={brand} />;
}

export default GetCardBrand
